import React from 'react';

export const withBlokContainer = (component: JSX.Element, anchorId: string) => {
  return (
    <div className="relative">
      <div id={anchorId} className="absolute -top-16 sm:-top-14"></div>
      {component}
    </div>
  );
};
