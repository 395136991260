import { Slot } from '../interfaces/Slot.interface';

const EARLY_MORNING = 'EARLY-MORNING';
const MORNING = 'MORNING';
const AFTERNOON = 'AFTERNOON';
const EVENING = 'EVENING';

interface Sections {
  [key: string]: { start: number; end: number };
}

const SECTIONS: Sections = {
  [EARLY_MORNING]: { start: 0, end: 9 },
  [MORNING]: { start: 9, end: 12 },
  [AFTERNOON]: { start: 12, end: 17 },
  [EVENING]: { start: 17, end: 23 },
};

const DEFAULT_DAYS = {
  1: [],
  2: [],
  3: [],
  4: [],
  5: [],
  6: [],
  7: [],
};

const getSection = (slot: Slot): string => {
  const start = parseInt(slot.start.split(':')[0]);

  return Object.keys(SECTIONS).find((section) => {
    if (start >= SECTIONS[section].start && start <= SECTIONS[section].end) {
      return section;
    }
  });
};

export const getClassesBySection = (
  classes: Slot[]
): { [key: string]: Slot[] } => {
  const sections = classes.reduce(
    (sections, slot) => {
      const section = getSection(slot);
      return {
        ...sections,
        [section]: [...sections[section], slot],
      };
    },
    {
      [EARLY_MORNING]: [],
      [MORNING]: [],
      [AFTERNOON]: [],
      [EVENING]: [],
    }
  );

  return Object.keys(sections).reduce((acc, section) => {
    const slots = sections[section].reduce((slots, slot) => {
      return { ...slots, [slot.day]: [...slots[slot.day], slot] };
    }, DEFAULT_DAYS);

    return {
      ...acc,
      [section]: slots,
    };
  }, {});
};

export const getClassesByDay = (classes: Slot[]) => {
  return classes.reduce((acc, curr) => {
    return { ...acc, [curr.day]: [...acc[curr.day], curr] };
  }, DEFAULT_DAYS);
};
