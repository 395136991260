import PerfectGymDataSource from './data-sources/perfect-gym-data-source';
import FirstSportDataSource from './data-sources/first-sport-data-source';
import GladstoneDataSource from './data-sources/gladstone-data-source';
import LocalDataSource from './data-sources/local-data-source';
import {
  TimetableContext,
  TimetableDataSourceResponse,
} from './timetable.context';

export enum TimetableDataSource {
  LOCAL = 'LOCAL',
  GLADSTONE = 'GLADSTONE',
  FIRST_SPORT = 'FIRST_SPORT',
  PERFECT_GYM = 'PERFECT_GYM',
}

class Timetable {
  /**
   * Data sources
   */
  private static DATA_SOURCES = {
    [TimetableDataSource.LOCAL]: LocalDataSource,
    [TimetableDataSource.GLADSTONE]: GladstoneDataSource,
    [TimetableDataSource.FIRST_SPORT]: FirstSportDataSource,
    [TimetableDataSource.PERFECT_GYM]: PerfectGymDataSource,
  };

  /**
   * Get Timetable
   * @param siteId
   * @param source
   * @returns
   */
  public static async get(
    siteId: string,
    source: TimetableDataSource,
    limited: boolean
  ): Promise<TimetableDataSourceResponse> {
    if (!siteId || !source)
      return {
        details: {},
        key: [],
        slots: [],
      };

    const dataSource = this.getTimetableDataSource(source);
    const timetable = new TimetableContext(new dataSource(), siteId, limited);

    return await timetable.render();
  }

  /**
   * Get timetable data by source type
   * @param source
   * @returns
   */
  private static getTimetableDataSource(source: TimetableDataSource) {
    if (this.DATA_SOURCES[source]) return this.DATA_SOURCES[source];

    throw new Error('Timetable data source not found');
  }
}

export { Timetable };
