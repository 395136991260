import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import Img from 'gatsby-image';
import { FluidObject, getFluidGatsbyImage } from 'gatsby-storyblok-image';
import { StoryblokAsset, StoryblokAssetPropType } from '../../interfaces/Asset.interface';
import { isEditMode } from '../../utils/isEditMode';

interface ImageCarouselProps {
    images: StoryblokAsset[]
}

const ImageCarousel: React.FC<ImageCarouselProps> = ({ images }) => {

    const fluidImages: FluidObject[] = images?.map(image => getFluidGatsbyImage(image.filename, { quality: 100 })) || [];
    const isInEditMode = isEditMode();
    const MAX_PERMITTED_IMAGE_SLIDES: number = 10;
    const MAX_PERMITTED_IMAGES_SLIDE_INDEX: number = MAX_PERMITTED_IMAGE_SLIDES - 1;
    const [currentSlide, setCurrentSlide] = useState<number>(0);
    const [isCarouselInitialized, setIsCarouselInitialized] = useState<boolean>();
    const [isResettingCarouselIndex, setIsResettingCarouselIndex] = useState<boolean>();
    let interval;

    useEffect(() => {
        if (fluidImages.length > 1) {
            startCarousel();
        } else {
            stopCarousel();
        }
    }, [images]);

    const startCarousel = () => {
        if (!isCarouselInitialized) {
            interval = setInterval(() => {
                const maxSlideIndex: number = fluidImages.length > MAX_PERMITTED_IMAGES_SLIDE_INDEX ? MAX_PERMITTED_IMAGES_SLIDE_INDEX : fluidImages.length - 1;
                setCurrentSlide(currentSlide => currentSlide < maxSlideIndex ? currentSlide + 1 : 0);
            }, 5000);
            setIsCarouselInitialized(true);
            return () => stopCarousel();
        }
    }

    const stopCarousel = () => {
        if (isCarouselInitialized) {
            resetCarouselIndexToStart();
            setIsCarouselInitialized(false);
            return clearInterval(interval);
        }
    }

    const resetCarouselIndexToStart = () => {
        setIsResettingCarouselIndex(true);
        setCurrentSlide(0);
        setIsResettingCarouselIndex(false);
    }

    return (
        <section>
            <div className={'relative'}>
                <div className={'flex overflow-x-hidden w-screen h-[200px] md:h-[400px] lg:h-[600px]'}>
                    {fluidImages.map((fluidImage, index) => {
                        if (index <= MAX_PERMITTED_IMAGES_SLIDE_INDEX) {
                            return (
                                <Img
                                    key={`carousel-item--${index}`}
                                    className={`flex-shrink-0 w-screen transition-all ${isResettingCarouselIndex ? 'duration-0' : 'duration-500'}`}
                                    fluid={fluidImage}
                                    alt={`Carousel image ${index + 1}`}
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                        marginLeft: (index === 0 && images.length > 1) ? `-${currentSlide * 100}%` : '0'
                                    }}
                                    imgStyle={{
                                        objectPosition: 'center',
                                        width: '100%',
                                        height: '100%',
                                    }}
                                    loading={'lazy'}
                                />
                        )}
                    })}
                </div>

                {/* carousel dots - only shown if we have more than one carousel image to show */}
                {fluidImages.length > 1 && (
                    <div className="absolute w-full bottom-6 flex justify-center">
                        {fluidImages.map((_, index) => {
                            if (index <= MAX_PERMITTED_IMAGES_SLIDE_INDEX) {
                                return <CarouselDot key={`carousel-dot--${index}`} isInActiveState={index === currentSlide} />
                            }
                        })}
                    </div>
                )}

                {/*-- storyblok edit mode warning message about not having any images selected --*/}
                {fluidImages.length === 0 && isInEditMode && 
                    <div className="absolute top-0 right-0 bottom-0 left-0 bg-white bg-opacity-75 flex flex-col justify-center items-center">
                        <div className="px-4 max-w-xl space-y-4">
                            <h4 className="font-oskari-g2--bold text-2xl">Image Carousel is empty</h4>
                            <p className="font-oskari-g2--regular">Select the images that you want to be displayed in this block (maximum <span className="font-oskari-g2--bold">{MAX_PERMITTED_IMAGE_SLIDES}</span> images permitted). You can upload them from your computer, or select from existing images inside the storyblok asset manager.</p>
                        </div>
                    </div>
                }
                
                {/* storyblok edit mode warning message about excessive numbers of images */}
                {fluidImages.length > MAX_PERMITTED_IMAGE_SLIDES && isInEditMode &&
                    <div className="absolute top-0 right-0 bottom-0 left-0 bg-white bg-opacity-75 flex flex-col justify-center items-center">
                        <div className="px-4 max-w-xl space-y-4">
                            <h4 className="font-oskari-g2--bold text-2xl">Maximum permitted carousel items exceeded</h4>
                            <p className="font-oskari-g2--regular">You have added <span className="font-oskari-g2--bold">{fluidImages.length - MAX_PERMITTED_IMAGE_SLIDES}</span> more than the maximum permitted carousel items for this blok (maximum <span className="font-oskari-g2--bold">{MAX_PERMITTED_IMAGE_SLIDES}</span> images permitted). Please remove the extra items. This message is only visible in Storyblok, and if you publish the page without removing the exceeding images, only the first 6 will be shown.</p>
                        </div>
                    </div>
                }
            </div>
        </section>
    )
};

ImageCarousel.defaultProps = {
    images: []
};

ImageCarousel.propTypes = {
    images: PropTypes.arrayOf(StoryblokAssetPropType)
};

export default ImageCarousel;

interface CarouselDotProps {
    isInActiveState: boolean;
}

const CarouselDot: React.FC<CarouselDotProps> = ({ isInActiveState = false }) => {
    return <div className={`mx-2 w-4 h-4 border border-white rounded-full ${isInActiveState ? 'bg-white' : 'bg-transparent'} transition-colors duration-500`}></div>
}