import classNames from 'classnames';
import React, { useContext, useState } from 'react';
import { MembershipContext } from '../../../contexts/MembershipContext';
import StarSVGGreen from '../../../svg/star-green.svg';
import StarSVGBlack from '../../../svg/star-black.svg';
import TickSVG from '../../../svg/tick.svg';
import Button from '../../Button/Button.component';
import MembershipOption, { PromotionType } from '../memberships.interface';
import ReactModal from 'react-modal';
import UpSellModal from '../UpSellModal.component';
import MoreInfoModal from '../MoreInfoModal.component';
import MembershipEventWrapper from '../MembershipEventWrapper.component';
import { PageContext } from '../../../contexts/PageContext';
import { generateJoinUrl } from '../../../utils/joinUrl';

const MembershipHorizontalCard: React.FC<MembershipOption> = ({
  membership,
  featuredMembership,
  isConcession,
}) => {
  const membershipContext = useContext(MembershipContext);
  const [moreInfoModal, setMoreInfoModal] = useState<boolean>(false);
  const [upSellModal, setUpSellModal] = useState<boolean>(false);
  const toggleModal = (state: boolean): void => {
    setMoreInfoModal(state);
    setUpSellModal(state);
  };
  const { gym } = useContext(PageContext);
  const {
    featured_card_bg_image,
    primary_cta_label,
    join_url,
    join_gym_url,
    secondary_cta_label,
    joining_fee_label,
  } = membershipContext.membershipDetails;

  const {
    membership_name,
    membership_term,
    price_per_month,
    discounted_price_per_month,
    features,
    fsi_product_code,
    promotion,
    joiningFeePromotion,
    membership_up_sell,
    joining_fee_price,
    terms_and_conditions,
    show_cta,
  } = membership;

  const secondaryCardHeight: string = !!membershipContext.memberships.find(
    (membership) =>
      !membership.featured_membership &&
      membership.promotion?.type === PromotionType.PROMOTION &&
      membership.promotion?.promotion_copy &&
      !membership.joining_fee_price
  )
    ? 'h-[342px]'
    : !!membershipContext.memberships.find(
        (membership) =>
          !membership.featured_membership &&
          membership.promotion?.type === PromotionType.PROMOTION &&
          membership.promotion?.promotion_copy
      )
    ? 'h-[284px]'
    : !!membershipContext.memberships.find(
        (membership) =>
          !membership.featured_membership && !membership.joining_fee_price
      )
    ? 'h-[262px]'
    : 'h-auto';

  const secondaryCardIsFullHeight =
    featuredMembership &&
    membershipContext.memberships.find(
      (membership) =>
        membership.promotion?.type === PromotionType.PROMOTION &&
        membership.promotion?.promotion_copy &&
        !membership.joining_fee_price &&
        !membership.featured_membership
    );

  const numberOfFeatures: number[] = membershipContext.memberships.reduce(
    function (filtered, option) {
      if (!option.featured_membership) {
        filtered.push(option.features.length);
      }
      return filtered;
    },
    []
  );

  const highestNumberOfFeatures = Math.max(...numberOfFeatures);
  const spacesNecessaryToMatchSecondaryCardHeight =
    highestNumberOfFeatures < features.length
      ? null
      : Math.abs(highestNumberOfFeatures - features.length);

  const isNecessaryToIncreasePrimaryCardHeight =
    membership.featured_membership &&
    highestNumberOfFeatures >= features.length;

  const joinUrl = generateJoinUrl({
    site_id: gym.site_id,
    pg_club_id: gym.pg_club_id,
    migrated: gym.migrated_to_perfect_gym,
    fsi_product_code,
    join_url,
    join_gym_url,
  });

  const upsellUrl = generateJoinUrl({
    site_id: gym.site_id,
    pg_club_id: gym.pg_club_id,
    migrated: gym.migrated_to_perfect_gym,
    fsi_product_code: membership_up_sell?.fsi_product_code,
    join_url,
    join_gym_url,
  });

  return (
    <>
      <div
        className={classNames(`pb-8 pt-10 px-2.5 relative`, {
          'bg-body/body': !featuredMembership,
        })}
      >
        <div className=" relative z-[1]">
          {membership_name && (
            <p className="text-white font-bold text-4xl font-oskari-g2--bold uppercase leading-[40px] pb-1">
              {membership_name}
            </p>
          )}
          {membership_term && (
            <p className="text-white leading-[28px]">{membership_term}</p>
          )}
        </div>
        {featuredMembership && featured_card_bg_image && (
          <div
            className={`w-full h-full transform duration-2000 bg-cover bg-no-repeat absolute top-0 left-0 m-auto bg-center bg-texture/secondary`}
            style={{
              backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.70) 0%, rgba(0, 0, 0, 0.70) 100%), url(${featured_card_bg_image.filename})`,
            }}
          ></div>
        )}
      </div>
      <div
        className={classNames(
          'p-10 flex flex-col justify-center',
          {
            'bg-primary': featuredMembership,
            'bg-body/500': !featuredMembership,
            'xl:min-h-[17em]': secondaryCardIsFullHeight,
          },
          !featuredMembership && !isConcession && secondaryCardHeight
        )}
      >
        {promotion?.promotion_copy && (
          <div className="pb-8 flex justify-center">
            <div
              className={classNames(
                'flex items-center justify-center py-3 px-4 space-x-2 rounded-full',
                {
                  'text-white bg-body/body': featuredMembership,
                  'text-body/body bg-white': !featuredMembership,
                }
              )}
            >
              <StarSVGGreen />
              <span className="font-oskari-g2--bold uppercase leading-[0px] mt-[0.35em]">
                {promotion.promotion_copy}
              </span>
              <StarSVGGreen />
            </div>
          </div>
        )}
        <div className="space-y-2">
          {discounted_price_per_month && (
            <p className="line-through text-body/body text-3xl leading-[36px]">
              £{price_per_month} p/m
            </p>
          )}
          {price_per_month && (
            <p className="text-body/body text-5xl font-oskari-g2--black leading-[56px]">
              £
              {discounted_price_per_month
                ? discounted_price_per_month
                : price_per_month}
            </p>
          )}
        </div>
        {joiningFeePromotion ? (
          <div className="pt-8 flex justify-center">
            <div
              className={` flex items-center justify-center py-3 px-4 space-x-2 rounded-full border border-black`}
            >
              {featuredMembership ? <StarSVGBlack /> : <StarSVGGreen />}
              <p className="text-body/body font-oskari-g2--bold uppercase leading-[0px] mt-[0.35em]">
                No {joining_fee_label || 'Joining Fee'}
              </p>
              {featuredMembership ? <StarSVGBlack /> : <StarSVGGreen />}
            </div>
          </div>
        ) : (
          <p className={`text-body/body text-base leading-[24px] `}>
            {joining_fee_label || 'Joining Fee'}
            {': '}
            <span className="font-semibold">{`£${joining_fee_price}`}</span>
          </p>
        )}
      </div>
      <div className="flex justify-between flex-col grow-0 md:grow xl:grow-0">
        <div className={`py-12 px-8 bg-white grow-0 md:grow xl:grow-0`}>
          <div className="flex items-center justify-center">
            <div className="space-y-5">
              {!!features?.length &&
                features.map(({ feature_name, custom_label, _uid }) => (
                  <div className="flex items-center justify-start" key={_uid}>
                    <div className="h-[33px] w-[32px]">
                      <TickSVG />
                    </div>
                    <p className="text-left pl-3 text-lg">
                      {custom_label || feature_name}
                    </p>
                  </div>
                ))}
              {!!features?.length &&
                [
                  ...Array(spacesNecessaryToMatchSecondaryCardHeight).keys(),
                ].map(() => <div className="pb-[27px]" />)}
              {isNecessaryToIncreasePrimaryCardHeight && (
                <div className="pb-[54px]" />
              )}
            </div>
          </div>
        </div>
        <div
          className={classNames('py-10 px-8 space-y-5 flex flex-col', {
            'bg-primary': featuredMembership,
            'bg-white border-t border-steel/500': !featuredMembership,
          })}
        >
          {membership_up_sell ? (
            <button
              aria-label="Open modal"
              className={classNames(
                'w-full sm:w-auto transition-all bg-body/body !text-white border border-black mt-0 cursor-pointer pt-4 px-8 pb-3 uppercase text-xl font-oskari-g2--semibold antialiased text-center',
                {
                  'hover:bg-white hover:!text-black hover:border-white':
                    featuredMembership,
                  'hover:bg-primary hover:border-primary': !featuredMembership,
                }
              )}
              onClick={() => setUpSellModal(!upSellModal)}
            >
              {primary_cta_label}
            </button>
          ) : (
            <MembershipEventWrapper
              membership={membership}
              event={'add_to_cart'}
            >
              <Button
                cta_label={primary_cta_label}
                cta_link={{
                  anchor: '',
                  cached_url: joinUrl,
                  fieldtype: 'link',
                  _uid: '',
                  id: '',
                  linktype: '',
                  url: joinUrl,
                }}
                className={classNames(
                  'bg-body/body !text-white border border-black mt-0',
                  {
                    'hover:bg-white hover:!text-black hover:border-white':
                      featuredMembership,
                    'hover:bg-primary hover:border-primary':
                      !featuredMembership,
                  }
                )}
              />
            </MembershipEventWrapper>
          )}
          {show_cta && (
            <MembershipEventWrapper membership={membership} event={'view_item'}>
              <button
                aria-label="Open modal"
                className={classNames(
                  'w-full transition-all border border-body/body text-body/body bg-[transparent] hover:bg-body/body hover:text-white mt-0 cursor-pointer pt-4 px-8 pb-3 uppercase text-xl font-oskari-g2--semibold antialiased text-center'
                )}
                onClick={() => {
                  setMoreInfoModal(!moreInfoModal);
                }}
              >
                {secondary_cta_label}
              </button>
            </MembershipEventWrapper>
          )}
        </div>
      </div>
      <ReactModal
        isOpen={upSellModal || moreInfoModal}
        className="relative h-full w-full flex justify-center items-center container mx-auto"
      >
        {upSellModal && (
          <UpSellModal
            membershipUpSell={membership_up_sell}
            membership={membership}
            joinUrl={joinUrl}
            upsellUrl={upsellUrl}
            toggleModal={toggleModal}
          />
        )}
        {moreInfoModal && (
          <MoreInfoModal
            membership={membership}
            joinUrl={joinUrl}
            toggleModal={toggleModal}
          />
        )}
      </ReactModal>
    </>
  );
};

export default MembershipHorizontalCard;
