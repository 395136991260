module.exports = [
  'FacilityTile.facility',
  'GymSelect.gyms',
  'GymSelectContact.gyms',
  'ImageHeroWithGymSelect.gyms',
  'FindAGym.gyms',
  'Timetable.gym',
  'GymCTA.Gym',
  'FiftyFiftyRegistration.gyms',
  'Membership.membership_up_sell',
];
