import React from 'react';
import MembershipOption from '../memberships.interface';
import MembershipHorizontalCard from './MembershipHorizontalCard';

const MembershipSecondaryHorizontalCard: React.FC<MembershipOption> = ({
  membership,
  isConcession,
}) => {
  return (
    <div
      className={`text-center h-auto md:h-full xl:h-auto flex flex-col order-3 xl:order-1 col-span-12 md:col-span-6 lg:col-span-5 xl:col-span-4 border border-black`}
    >
      <MembershipHorizontalCard
        membership={membership}
        key={membership._uid}
        isConcession={isConcession}
      />
    </div>
  );
};

export default MembershipSecondaryHorizontalCard;
