import { useEffect, useState } from 'react';
import StoryblokClient from 'storyblok-js-client';
import RELATIONS from '../constants/relations';
import { StoryblokEntry } from '../templates/storyblok-entry.template';
import { isEditMode } from '../utils/isEditMode';

declare global {
  interface Window {
    StoryblokBridge: any;
  }
}

const sbClient = new StoryblokClient({
  accessToken: process.env.GATSBY_STORYBLOK_ACCESS_TOKEN,
  cache: {
    clear: 'auto',
    type: 'memory',
  },
});

const getSlug = (urlParams: any) => urlParams.get('path') || 'home';

const getStory = async (slug: string): Promise<StoryblokEntry> => {
  const result = await sbClient.get(`cdn/stories/${slug}`, {
    version: 'draft',
    resolve_relations: RELATIONS,
  });

  return result?.data?.story;
};

export default function useStoryblok(
  originalStory: StoryblokEntry | null,
  location: Location | null
) {
  let [story, setStory] = useState(originalStory);

  if (story && typeof story.content === 'string') {
    story.content = JSON.parse(story.content);
  }

  // see https://www.storyblok.com/docs/Guides/storyblok-latest-js
  function initEventListeners() {
    const { StoryblokBridge } = window;
    const urlParams = new URLSearchParams(location?.search);

    if (typeof StoryblokBridge !== 'undefined') {
      const storyblokInstance = new StoryblokBridge({
        resolveRelations: RELATIONS,
      });

      storyblokInstance.on(
        ['published', 'change'],
        (event: StoryblokEventPayload) => {
          window.location.reload();
        }
      );

      storyblokInstance.on('input', (event: StoryblokEventPayload) => {
        setStory(event.story);
      });

      if (!story && location) getStory(getSlug(urlParams)).then(setStory);

      storyblokInstance.on(
        'enterEditmode',
        async (event: StoryblokEventPayload) => {
          if (event?.storyId) {
            const story = await getStory(event.storyId);
            setStory(story);
          }
        }
      );
    }
  }

  function addBridge(callback: () => void) {
    // check if the script is already present
    const existingScript = document.getElementById('storyblokBridge');
    if (!existingScript) {
      const script = document.createElement('script');
      script.src = `//app.storyblok.com/f/storyblok-v2-latest.js`;
      script.id = 'storyblokBridge';
      document.body.appendChild(script);
      script.onload = () => {
        callback();
      };
    } else {
      callback();
    }
  }

  useEffect(() => {
    if (isEditMode()) {
      addBridge(initEventListeners);
    }
  }, []); // it's important to run the effect only once to avoid multiple event attachment

  return story;
}
