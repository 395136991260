import React from 'react';
import {
  Membership,
  MembershipDetails,
  Promotion,
} from '../components/Memberships/memberships.interface';

interface MembershipContext {
  memberships: Membership[];
  concessionMemberships: Membership[];
  membershipDetails: MembershipDetails | null;
}

export const MembershipContext = React.createContext<MembershipContext>({
  memberships: null,
  concessionMemberships: null,
  membershipDetails: null,
});
