import PropTypes from 'prop-types';
import React from 'react';
import { Blok } from '../../interfaces/Blok.interface';
import { StoryblokAsset } from '../../interfaces/Asset.interface';
import Header from '../../components/Header/Header.component';
import { StoryblokLink } from '../../interfaces/StoryblokLink.interface';
import { withBlokContainer } from '../../utils/withBlokContainer';

interface navItemsBlok extends Blok {
  label: string;
  link: StoryblokLink;
}
interface HeaderBlokProps extends Blok {
  logo: StoryblokAsset;
  logo_alt: StoryblokAsset;
  menu_items: navItemsBlok[];
  display_login: boolean;
  login_link: StoryblokLink;
  mobile_menu_items: navItemsBlok[];
}

const HeaderBlok: React.FC<HeaderBlokProps> = ({
  logo,
  logo_alt,
  menu_items,
  display_login,
  login_link,
  mobile_menu_items,
  anchor,
}) => {
  return withBlokContainer(
    <Header
      logo={logo}
      logo_alt={logo_alt}
      menu_items={menu_items}
      display_login={display_login}
      login_link={login_link}
      mobile_menu_items={mobile_menu_items}
    />,
    anchor
  );
};

HeaderBlok.defaultProps = {
  logo: null,
  logo_alt: null,
  menu_items: [],
  display_login: false,
  login_link: null,
  mobile_menu_items: [],
};

HeaderBlok.propTypes = {
  logo: PropTypes.any,
  logo_alt: PropTypes.any,
  menu_items: PropTypes.any,
  display_login: PropTypes.any,
  login_link: PropTypes.any,
  mobile_menu_items: PropTypes.any,
};

export default HeaderBlok;
