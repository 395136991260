import PropTypes from 'prop-types';
import React from 'react';
import Button from '../Button/Button.component';
import { StoryblokAsset } from '../../interfaces/Asset.interface';
import { StoryblokLink } from '../../interfaces/StoryblokLink.interface';
import TileImage from '../TileImage/TileImage.component';
import RichText from '../RichText/RichText.component';
import classnames from 'classnames';
import { BlockTheme, Blok } from '../../interfaces/Blok.interface';
import TileImageVideoModal from '../TileImageVideoModal/TileImageVideoModal.component';
import classNames from 'classnames';
import { useMediaQuery } from 'react-responsive';
import { useLocation } from '@reach/router';

interface CTA extends Blok {
  label: string;
  link: StoryblokLink;
}

interface FiftyFiftyImageTextProps {
  icon: StoryblokAsset;
  heading: string;
  subheading: string;
  body: any;
  cta: CTA;
  image: StoryblokAsset;
  reverse: boolean;
  theme: BlockTheme;
  video: StoryblokLink;
  tight_padding: boolean;
}

const FiftyFiftyImageText: React.FC<FiftyFiftyImageTextProps> = ({
  icon,
  heading,
  subheading,
  body,
  cta,
  image,
  reverse,
  theme,
  video,
  tight_padding,
}) => {
  const isMobile = useMediaQuery({
    query: '(max-width: 1023px)',
  });

  return (
    <section>
      <div
        className={classnames('bg-repeat bg-200 ', {
          'bg-texture-primary bg-texture/primary': theme === 'LIGHT',
          'bg-texture-secondary bg-texture/secondary': theme === 'DARK',
          'py-8 lg:py-0': tight_padding,
        })}
      >
        <div
          className={classnames(
            'grid grid-cols-1 lg:grid-cols-2  m-auto px-4 sm:px-0 sm:max-w-screen-sm md:max-w-screen-md lg:max-w-full',
            {
              'text-body/body': theme === 'LIGHT',
              'text-white ': theme === 'DARK',
            }
          )}
        >
          <div
            className={classnames('relative', {
              'lg:order-1': reverse,
              'lg:order-2': !reverse,
            })}
          >
            {/* If there is a Video, and it has a URL - Overlay a play icon on the image below */}
            {video && video.url ? (
              <TileImageVideoModal image={image} videoURL={video.url} />
            ) : tight_padding ? (
              !isMobile ? (
                <div
                  className="w-full h-full bg-cover"
                  style={{ backgroundImage: `url(${image.filename})` }}
                ></div>
              ) : (
                <TileImage image={image} />
              )
            ) : (
              <TileImage image={image} />
            )}
          </div>
          <div
            className={`lg:flex lg:items-center lg:justify-center lg:order-${
              reverse ? '2' : '1'
            }`}
          >
            <div
              className={`${
                tight_padding
                  ? 'lg:w-[34rem] px-8 py-8 lg:py-24'
                  : 'lg:w-108 py-8 lg:py-6'
              }`}
            >
              <div className="flex items-center">
                <div>
                  <div className="flex">
                    {icon?.filename && (
                      <div
                        className={classnames('hidden lg:block w-16', {
                          // We use "invert" here to alter both the SVG
                          invert: theme === 'LIGHT',
                        })}
                      >
                        <TileImage image={icon}></TileImage>
                      </div>
                    )}
                    {heading && (
                      <h3
                        className={classNames(
                          `text-3xl sm:text-4xl font-oskari-g2--bold antialiased pt-3 uppercase leading-10`,
                          {
                            'lg:pl-6': icon?.filename,
                            'pb-5': heading && !subheading,
                            'pb-7': heading && subheading,
                          }
                        )}
                      >
                        {heading}
                      </h3>
                    )}
                  </div>
                  {subheading && (
                    <p
                      className={classNames(
                        `text-2xl font-oskari-g2--semibold antialiased uppercase`
                      )}
                    >
                      {subheading}
                    </p>
                  )}
                  {body && (
                    <RichText
                      textSize="text-xl"
                      classNames={`${tight_padding ? 'pt-4' : 'pt-2'}`}
                      document={body}
                    />
                  )}
                  {cta && (
                    <div className="flex justify-start">
                      <div
                        onClick={() => {
                          if (useLocation().pathname.includes('/thank-you')) {
                            window.dataLayer = window.dataLayer || [];
                            window.dataLayer.push({
                              event: 'member_cta',
                              member_cta_url: cta.link,
                              member_cta_text: cta.label,
                            });
                          }
                        }}
                      >
                        <Button
                          cta_label={cta.label}
                          cta_link={cta.link}
                          background={theme === 'LIGHT' ? 'BLACK' : 'WHITE'}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

FiftyFiftyImageText.defaultProps = {
  heading: '',
  subheading: '',
  body: null,
  cta: null,
  image: null,
  reverse: false,
};

FiftyFiftyImageText.propTypes = {
  heading: PropTypes.string,
  subheading: PropTypes.string,
  body: PropTypes.any,
  cta: PropTypes.any,
  image: PropTypes.any,
  reverse: PropTypes.bool,
};

export default FiftyFiftyImageText;
