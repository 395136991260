import classNames from 'classnames';
import React from 'react';

interface TimetableItemProps {
  name: string;
  description: string;
  type: string;
  time: string;
  onClick: any;
  colour: string;
  isClickable: boolean;
}

export const TimetableItem: React.FC<TimetableItemProps> = ({
  onClick,
  name,
  description,
  time,
  colour,
  isClickable = false,
}) => {
  // const foregroundColour = colour === '#6D6D6D' ? '#fff' : '#000';
  const foregroundColour = ['#6D6D6D', '#000'].includes(colour) || !colour ? '#fff' : '#000';
  const buttonClasses = classNames('w-full md:my-0.5', {
    'cursor-pointer': isClickable,
    'cursor-default': !isClickable,
  });

  return (
    <button onClick={() => onClick(null)} className={buttonClasses}>
      <div
        className="w-full h-full py-3"
        style={{ backgroundColor: colour, color: foregroundColour }}
      >
        <p className="text-lg md:text-base xl:text-xl font-oskari-g2--semibold antialiased uppercase">
          {name}
        </p>
        <p className="text-sm lg:text-base font-oskari-g2--regular antialiased lg:h-5">
          {description}
        </p>
        <p className="text-sm lg:text-base font-oskari-g2--regular antialiased">
          {time}
        </p>
      </div>
    </button>
  );
};
