import PropTypes from 'prop-types';
import React from 'react';
import Video from '../../components/Video/Video.component';
import { Blok } from '../../interfaces/Blok.interface';
import { withBlokContainer } from '../../utils/withBlokContainer';

interface VideoBlokProps extends Blok {
  video_link: string;
}

const VideoBlok: React.FC<VideoBlokProps> = ({ video_link, anchor }) => {
  return withBlokContainer(<Video video_link={video_link} />, anchor);
};

VideoBlok.defaultProps = {
  video_link: '',
};

VideoBlok.propTypes = {
  video_link: PropTypes.string,
};

export default VideoBlok;
