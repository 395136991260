import React from 'react';
import ArrowRightIcon from '../../svg/arrow-right.svg';
import { Gym } from '../../interfaces/GymData';
import CustomLink from '../CustomLink/CustomLink.component';

interface GymListProps {
  listTitle: string;
  gyms: Gym[];
}

const GymList: React.FC<GymListProps> = ({ listTitle, gyms }) => {
  return (
    <div className="mt-2 pt-4 border-t-2 border-white">
      <h3 className="text-2xl font-oskari-g2--bold uppercase text-white pb-1">
        {listTitle} ({gyms.length})
      </h3>
      <div className="md:h-96 md:overflow-y-scroll">
        <ul>
          {gyms
            .sort((a, b) => (a.name > b.name ? 1 : -1))
            .map((gym) => (
              <li key={`gym-${gym.site_id}`} className="py-2 listItems">
                <CustomLink
                  url={gym.page_url}
                  className="flex text-lg items-center justify-between font-oskari-g2--semibold antialiased uppercase"
                >
                  {gym.name}
                  <ArrowRightIcon />
                </CustomLink>
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
};

export default GymList;
