import classNames from 'classnames';
import React, { useContext, useEffect, useState } from 'react';
import OpenSVG from '../../svg/chevron.svg';
import { MembershipContext } from '../../contexts/MembershipContext';
import { PageContext } from '../../contexts/PageContext';
import { MembershipLayout } from '../../interfaces/MembershipLayout.enum';

interface Props {}

const ConcessionMemberships: React.FC<Props> = ({ children }) => {
  const { membershipDetails, concessionMemberships } =
    useContext(MembershipContext);
  const [showAdditionalCards, setShowAdditionalCards] = useState(false);
  const { gym } = useContext(PageContext);

  return (
    <div className="pt-20">
      <button
        className={`w-full sm:w-auto transition-all cursor-pointer pt-4 pl-8 pr-7 pb-3 uppercase text-xl font-oskari-g2--semibold antialiased text-center  text-white group border border-black inline-flex justify-center space-x-2 ${
          showAdditionalCards
            ? 'bg-primary border-primary !text-body/body'
            : 'bg-body/body text-white'
        }`}
        onClick={() => {
          if (!showAdditionalCards) {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
              event: 'view_item_list',
              ecommerce: {
                items: concessionMemberships.map((membership, idx) => {
                  const TagManagerFeatureKeys = membership.features.map(
                    (feature, idx) => `item_category${idx !== 0 ? idx : ''}`
                  );
                  const membership_data = {
                    item_name: membership.membership_name,
                    item_id: membership.fsi_product_code,
                    site_id: gym.name,
                    price:
                      Number(membership.price_per_month) +
                      Number(membership.joining_fee_price),
                    discount: membership.discounted_price_per_month,
                    joining_fee: membership.joining_fee_price,
                    currency: 'GBP',
                    coupon: membership?.promotion?.promotion_copy,
                    item_brand: 'Everlast',
                    item_list_name:
                      membershipDetails?.component ===
                      MembershipLayout.HORIZONTAL
                        ? 'variant 1'
                        : membershipDetails?.component ===
                          MembershipLayout.VERICAL
                        ? 'variant 2'
                        : 'variant 3',
                    item_list_id:
                      membershipDetails?.component ===
                      MembershipLayout.HORIZONTAL
                        ? 'variant_1'
                        : membershipDetails?.component ===
                          MembershipLayout.VERICAL
                        ? 'variant_2'
                        : 'variant_3',
                    quantity: 1,
                  };
                  membership.features.map(
                    (feature, idx) =>
                      (membership_data[TagManagerFeatureKeys[idx]] =
                        feature.feature_name)
                  );
                  return membership_data;
                }),
              },
            });
          }
          setShowAdditionalCards(!showAdditionalCards);
        }}
      >
        {membershipDetails.show_more_cta_label}
        <OpenSVG
          className={classNames('transition-all fill-white', {
            'rotate-180 fill-body/body': showAdditionalCards,
          })}
        />
      </button>
      {showAdditionalCards && children}
    </div>
  );
};

export default ConcessionMemberships;
