type PageStyle = 'flush' | 'non-flush';

const isJSON = (string) => {
  try {
    JSON.parse(string);
    return true;
  } catch (e) {
    return false;
  }
};

export const getPageStyle = (content): PageStyle => {
  return isJSON(content)
    ? JSON.parse(content)['page_style']
    : content['page_style'];
};
