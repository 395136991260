import PropTypes from 'prop-types';
import React from 'react';
import { Blok } from '../../interfaces/Blok.interface';
import Banner from '../../components/Banner/Banner.component';
import { withBlokContainer } from '../../utils/withBlokContainer';

export type BannerBackground = 'PRIMARY' | 'WHITE';

interface BannerBlokProps extends Blok {
  heading: string;
  subheading: string;
  body: any;
  background: BannerBackground;
}

const BannerBlok: React.FC<BannerBlokProps> = ({
  heading,
  subheading,
  body,
  anchor,
  background,
}) => {
  return withBlokContainer(
    <Banner
      heading={heading}
      subheading={subheading}
      body={body}
      background={background}
    />,
    anchor
  );
};

BannerBlok.defaultProps = {
  heading: '',
};

BannerBlok.propTypes = {
  heading: PropTypes.string,
};

export default BannerBlok;
