import React from 'react';

interface Item {
  name: string;
  color: string;
}
interface TimetableKeyProps {
  items: Item[];
}

export const TimetableKey: React.FC<TimetableKeyProps> = ({ items }) => {
  return (
    <ul className="uppercase text-xl font-oskari-g2--semibold antialiased grid grid-cols-2 sm:flex sm:justify-center sm:items-center flex-wrap pb-10 md:pb-16">
      {items.map((item) => {
        return (
          <li
            className="flex justify-center items-end px-4 my-2"
            key={item.name}
          >
            <div className="flex justify-start items-center">
              <div style={{ width: '32px', height: ' 32px' }}>
                <svg width="32" height="32" viewBox="0 0 32 32">
                  <rect width="32" height="32" fill={item.color} />
                </svg>
              </div>
              <p className="text-base md:text-xl pl-4 uppercase pt-1">
                {item.name}
              </p>
            </div>
          </li>
        );
      })}
    </ul>
  );
};
