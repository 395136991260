import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { BlockTheme } from '../../interfaces/Blok.interface';
import { Gym } from '../../interfaces/GymData';
import { SortAlphabeticallyByName } from '../../utils/sortAlphabeticallyByName';
import RichText from '../RichText/RichText.component';
interface GymSelectProps {
  title: any;
  select_gym_label: string;
  gyms: Gym[];
  login_label?: string;
  login_cta_label: string;
  join_label?: string;
  join_cta_label: string;
  primary_ctas: string[];
  theme?: BlockTheme;
}

const GymSelect: React.FC<GymSelectProps> = ({
  title,
  select_gym_label,
  gyms,
  login_label,
  join_label,
  login_cta_label,
  join_cta_label,
  primary_ctas,
  theme: themeInput,
}) => {
  const [gymsList] = useState<Gym[]>(gyms);
  const [selectedGym, setSelectedGym] = useState<Gym>(null);

  // When the user clicks into the blok to edit - The theme, if not set will be an empty string
  const theme = themeInput || 'DARK';

  const onGymSelection = (event) =>
    setSelectedGym(gymsList.find((gym) => gym.site_id === event.target.value));

  const getCTAClassNames = (name) => {
    const primaryCTAClassNames =
      'pt-1 flex items-center justify-center transition-all hover:bg-primary cursor-pointer uppercase text-xl font-oskari-g2--semibold antialiased bg-white text-black h-14';

    const secondaryCTAClassNames =
      'pt-1 flex items-center justify-center transition-all hover:bg-primary cursor-pointer uppercase text-xl font-oskari-g2--semibold antialiased bg-body/body text-white border-2 h-14 baseline';

    return primary_ctas.includes(name)
      ? primaryCTAClassNames
      : secondaryCTAClassNames;
  };

  return (
    <section
      className={classnames('bg-repeat bg-200 pt-5 pb-12 md:pt-10 md:pb-24', {
        'bg-texture-primary bg-texture/primary': theme === 'LIGHT',
        'bg-texture-tertiary bg-texture/tertiary': theme === 'DARK',
      })}
    >
      <div className="m-auto px-5 max-w-xl">
        <h2
          className={classnames('w-full text-center my-8 leading-9', {
            'text-body/body': theme === 'LIGHT',
            'text-white': theme === 'DARK',
          })}
        >
          <RichText document={title} />
        </h2>
        <label
          className={classnames(
            'text-base font-oskari-g2--semibold uppercase block mb-1',
            {
              'text-body/body': theme === 'LIGHT',
              'text-white': theme === 'DARK',
            }
          )}
          htmlFor="select-gym"
        >
          {select_gym_label}
        </label>
        <select
          id="select-gym"
          onChange={onGymSelection}
          defaultValue={0}
          className="form-select w-full mb-6 md:mb-10"
          aria-label={select_gym_label}
        >
          <option value={0} disabled>
            Please select a gym
          </option>
          {gymsList.sort(SortAlphabeticallyByName).map((gym, index) => (
            <option key={gym.uid + index} value={gym.site_id}>
              {gym.name}
            </option>
          ))}
        </select>
        {selectedGym && (
          <div
            className={classnames('md:flex flex-row  text-center', {
              'text-body/body': theme === 'LIGHT',
              'text-white': theme === 'DARK',
            })}
          >
            <div className="flex-none md:flex-1 mb-6 md:mb-0 pt-2 border-r-0 md:border-r md:pr-10">
              <p className="font-oskari-g2--regular text-lg mb-3">
                {login_label}
              </p>
              <a
                href={selectedGym.login_url}
                className={getCTAClassNames('login')}
              >
                {login_cta_label}
              </a>
            </div>
            <div className="flex-none md:flex-1 pt-2 md:pl-10 pb-2">
              <p className="font-oskari-g2--regular text-lg mb-3">
                {join_label}
              </p>
              <a
                href={selectedGym.join_journey_url}
                className={getCTAClassNames('join')}
              >
                {join_cta_label}
              </a>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

GymSelect.defaultProps = {
  title: null,
  select_gym_label: '',
  gyms: [],
  login_label: '',
  login_cta_label: '',
  join_label: '',
  join_cta_label: '',
};

GymSelect.propTypes = {
  title: PropTypes.any,
  select_gym_label: PropTypes.string.isRequired,
  gyms: PropTypes.arrayOf(
    PropTypes.exact({
      uid: PropTypes.string.isRequired,
      name: PropTypes.string,
      address: PropTypes.string,
      contact_number: PropTypes.string,
      coordinates: PropTypes.exact({
        lat: PropTypes.number,
        lng: PropTypes.number,
      }),
      email: PropTypes.string,
      join_journey_url: PropTypes.string,
      login_url: PropTypes.string,
      contact_url: PropTypes.string,
      page_url: PropTypes.string,
      site_id: PropTypes.string,
      migrated_to_perfect_gym: PropTypes.bool,
    })
  ).isRequired,
  login_label: PropTypes.string,
  login_cta_label: PropTypes.string.isRequired,
  join_label: PropTypes.string,
  join_cta_label: PropTypes.string.isRequired,
};

export default GymSelect;
