import PropTypes from 'prop-types';
import React from 'react';
import { Blok } from '../../interfaces/Blok.interface';
import { StoryblokAsset } from '../../interfaces/Asset.interface';
import SocialBanner from '../../components/SocialBanner/SocialBanner.component';
import { withBlokContainer } from '../../utils/withBlokContainer';

interface ImageBlokProps extends Blok {
  image: StoryblokAsset;
}
interface SocialBannerBlokProps extends Blok {
  heading: string;
  sub_heading: string;
  images: ImageBlokProps[];
  social_links: string[];
}

const SocialBannerBlok: React.FC<SocialBannerBlokProps> = ({
  heading,
  sub_heading,
  images,
  social_links,
  anchor,
  theme: themeInput,
}) => {
  // When the user clicks into the blok to edit - The theme, if not set will be an empty string
  const theme = themeInput || 'LIGHT';

  return withBlokContainer(
    <SocialBanner
      heading={heading}
      sub_heading={sub_heading}
      social_links={social_links}
      images={images}
      theme={theme}
    />,
    anchor
  );
};

SocialBannerBlok.defaultProps = {
  heading: '',
  sub_heading: '',
  social_links: null,
  images: null,
};

SocialBannerBlok.propTypes = {
  heading: PropTypes.string,
  sub_heading: PropTypes.string,
  social_links: PropTypes.any,
  images: PropTypes.any,
};

export default SocialBannerBlok;
