import React from 'react';
import { Gym } from '../../interfaces/GymData';

interface FilteredGymListProps {
  gyms: Gym[];
}

const gymDistanceInMiles = (distance) => (distance / 1609.344).toFixed(2);

const FilteredGymList: React.FC<FilteredGymListProps> = ({ gyms }) => {
  return (
    <div>
      {!!gyms.length && (
        <div className="pb-6 pt-2">
          <p className="font-oskari-g2--light uppercase">
            Search results ({gyms.length})
          </p>

          <ul>
            {gyms.map((gym) => (
              <li key={`filtered-gym-${gym.uid}`} className={`py-2`}>
                <div
                  className={`bg-texture-primary bg-repeat bg-200 bg-texture/primary text-body/body`}
                >
                  <a
                    href={gym.page_url}
                    className={`block py-2 px-4 text-lg font-oskari-g2--semibold antialiased uppercase`}
                  >
                    <div className="flex items-center justify-between">
                      <div>
                        <p className="pb-1 uppercase">{gym.name}</p>
                        <p className="font-oskari-g2--regular text-base lowercase">
                          {gymDistanceInMiles(gym.distance)} miles away
                        </p>
                      </div>
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1 9H12.17L7.29 13.88C6.9 14.27 6.9 14.91 7.29 15.3C7.68 15.69 8.31 15.69 8.7 15.3L15.29 8.71C15.68 8.32 15.68 7.69 15.29 7.3L8.71 0.700001C8.32 0.310001 7.69 0.310001 7.3 0.700001C6.91 1.09 6.91 1.72 7.3 2.11L12.17 7H1C0.45 7 0 7.45 0 8C0 8.55 0.45 9 1 9Z"
                          fill="#141414"
                        />
                      </svg>
                    </div>
                  </a>
                </div>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default FilteredGymList;
