import React from 'react';
import { Gym } from '../interfaces/GymData';

interface PageContext {
  uuid: string;
  isHeadingFlush: boolean;
  gym: Gym;
}

export const PageContext = React.createContext<PageContext>({
  uuid: '',
  isHeadingFlush: false,
  gym: null,
});
