import PropTypes from 'prop-types';
import React from 'react';
import ClubHero from '../../components/ClubHero/ClubHero.component';
import { Blok } from '../../interfaces/Blok.interface';
import { StoryblokAsset } from '../../interfaces/Asset.interface';
import { StoryblokLink } from '../../interfaces/StoryblokLink.interface';
import { withBlokContainer } from '../../utils/withBlokContainer';

interface GridBlokProps extends Blok {
  left_column: string;
  right_column: string;
}

interface InfoBlokProps extends Blok {
  icon: StoryblokAsset;
  label: string;
  body: any;
  grid: GridBlokProps[];
  table: any;
}

interface CTA {
  label: string;
  link: StoryblokLink;
}

interface ClubHeroBlokProps extends Blok {
  image: StoryblokAsset;
  heading: string;
  cta: CTA;
  info_blok: InfoBlokProps[];
}

const ClubHeroBlok: React.FC<ClubHeroBlokProps> = ({
  image,
  heading,
  cta,
  info_blok,
  anchor,
  ...props
}) => {
  const ctaData = cta && cta[0];
  const ctaContent = ctaData && {
    label: ctaData.label,
    link: ctaData.Gym ? ctaData.Gym.content[ctaData.url] : ctaData.link,
    _uid: ctaData._uid,
    anchor: ctaData.anchor,
    component: ctaData.component,
    _editable: ctaData._editable,
  };

  return withBlokContainer(
    <ClubHero
      image={image}
      heading={heading}
      cta={ctaContent}
      info_blok={info_blok}
    />,
    anchor
  );
};

ClubHeroBlok.defaultProps = {
  image: null,
  heading: '',
  cta: null,
  info_blok: null,
};

ClubHeroBlok.propTypes = {
  image: PropTypes.any,
  heading: PropTypes.string,
  cta: PropTypes.any,
  info_blok: PropTypes.any,
};

export default ClubHeroBlok;
