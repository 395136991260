import classNames from 'classnames';
import React, { useContext, useState } from 'react';
import HelpSVG from '../../../svg/help.svg';
import { MembershipTable } from '../memberships.interface';
import MembershipPrimaryComparisonCard from './MembershipPrimaryComparisonCard';
import MembershipSecondaryComparisonCard from './MembershipSecondaryComparisonCard';
import TickSVG from '../../../svg/tick.svg';
import MinusSVG from '../../../svg/minus.svg';
import { v4 as uuidv4 } from 'uuid';

enum ComparisonValue {
  UNDEFINED = 'undefined',
  CUSTOM_VALUE = 'custom_value',
  NO_VALUE = 'no_value',
}

const MembershipComparisonTable: React.FC<MembershipTable> = ({
  memberships,
}) => {
  const sharedFeatures = [
    ...new Set(
      memberships
        .map(({ features }) => features)
        .flat()
        .map(({ feature_name }) => feature_name)
    ),
  ];

  const tableData = sharedFeatures.map((sharedFeature, idx) => {
    const featureData = memberships.map((membership) => {
      const matchingFeature = membership.features.find(
        (feature, idx) => feature.feature_name === sharedFeature
      );
      return matchingFeature;
    });

    const featureValues = featureData.reduce((acc, currentValue, idx) => {
      if (!currentValue) {
        acc[`value_${idx}`] = ComparisonValue.UNDEFINED;
      } else if (currentValue?.comparison_value) {
        acc[`value_${idx}`] = currentValue?.comparison_value;
      } else {
        acc[`value_${idx}`] = ComparisonValue.NO_VALUE;
      }
      return acc;
    }, {});

    return {
      feature_name: sharedFeature,
      ...featureValues,
    };
  });

  return (
    <div className="grid grid-cols-12">
      {/* container sm:!px-0 sm:max-w-none */}
      <div className="grid grid-cols-12 col-span-12 3xl:col-start-2 3xl:col-span-10 gap-y-10 items-end grid-flow-row-dense">
        <ul
          className={classNames(
            `text-center hidden xl:flex flex-col col-start-1 col-span-3 order-0`
          )}
        >
          {sharedFeatures.map((feature, idx) => (
            <li
              className={`flex items-center justify-start px-4 py-[10px] h-[65px] space-x-2 ${
                idx % 2 == 0 ? 'bg-white' : 'bg-steel/100'
              }`}
              key={uuidv4()}
            >
              <p className="text-left text-base">{feature}</p>
              <HelpSVG />
            </li>
          ))}
        </ul>
        {memberships.map((membership, idx) => {
          const { featured_membership } = membership;
          return (
            <>
              {featured_membership ? (
                <MembershipPrimaryComparisonCard
                  membership={membership}
                  key={uuidv4()}
                  featuredMembership
                  sharedFeatures={sharedFeatures}
                />
              ) : (
                <MembershipSecondaryComparisonCard
                  membership={membership}
                  key={uuidv4()}
                  sharedFeatures={sharedFeatures}
                />
              )}
            </>
          );
        })}
      </div>
      <div className="xl:hidden grid grid-cols-12 col-span-12 items-end grid-flow-row-dense">
        {tableData.map((feature, idx) => (
          <div
            className={classNames('col-span-12 grid grid-cols-12', {
              'bg-white': idx % 2 == 0,
              'bg-steel/100': idx % 2 != 0,
            })}
          >
            <div
              className={classNames(
                'col-span-12 flex flex-col items-center justify-center px-2 py-1.5 sm:py-2.5 text-sm sm:text-base font-bold'
              )}
              key={uuidv4()}
            >
              {feature.feature_name}
            </div>
            {[...Array(3)].map((_, idx) => {
              const featureValue = feature[`value_${idx}`];
              return (
                <div
                  className={classNames(
                    'col-span-4 h-full text-sm sm:text-base text-center flex flex-col items-center justify-center pb-1.5 sm:pb-2'
                  )}
                  key={uuidv4()}
                >
                  {feature[`value_${idx}`] === ComparisonValue.NO_VALUE ? (
                    <div className="h-6 w-6 sm:h-7 sm:w-7">
                      <TickSVG />
                    </div>
                  ) : feature[`value_${idx}`] === ComparisonValue.UNDEFINED ? (
                    <div className="h-6 w-6 sm:h-7 sm:w-7">
                      <MinusSVG />
                    </div>
                  ) : (
                    <p>{featureValue}</p>
                  )}
                </div>
              );
            })}
          </div>
        ))}
      </div>
    </div>
  );
};

export default MembershipComparisonTable;
