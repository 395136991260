import PropTypes from 'prop-types';
import React from 'react';
import Facilities from '../../components/Facilities/Facilities.component';
import { Blok } from '../../interfaces/Blok.interface';
import { StoryblokAsset } from '../../interfaces/Asset.interface';
import { withBlokContainer } from '../../utils/withBlokContainer';

interface FacilityTile extends Blok {
  content: {
    label: string;
    icon: StoryblokAsset;
  };
}

interface FacilityBlok extends Blok {
  facility: FacilityTile;
}

interface FacilitiesBlokProps extends Blok {
  heading: string;
  facilities: FacilityBlok[];
}

const FacilitiesBlok: React.FC<FacilitiesBlokProps> = ({
  heading,
  facilities,
  anchor,
  theme: themeInput,
}) => {
  const facilitiesMapped = facilities.map((facility) => ({
    id: facility._uid,
    label: facility.facility.content.label,
    icon: facility.facility.content.icon,
  }));

  // When the user clicks into the blok to edit - The theme, if not set will be an empty string
  const theme = themeInput || 'LIGHT';

  return withBlokContainer(
    <Facilities
      heading={heading}
      facilities={facilitiesMapped}
      theme={theme}
    />,
    anchor
  );
};

FacilitiesBlok.defaultProps = {
  heading: '',
  facilities: null,
};

FacilitiesBlok.propTypes = {
  heading: PropTypes.string,
  facilities: PropTypes.any,
};

export default FacilitiesBlok;
