import PropTypes from 'prop-types';
import React from 'react';

interface TitleProps {
  heading: string;
}

const Title: React.FC<TitleProps> = ({
  heading,
}) => {
  return (
    <section className="bg-texture-tertiary bg-texture/tertiary bg-repeat bg-200">
    <div className="container m-auto pt-12.5 pb-16 sm:pb-20">
    <h1 className="text-center sm:text-left uppercase text-3xl sm:text-5xl font-oskari-g2--bold antialiased text-white">
        {heading}
      </h1>
    </div>
  </section>

  );
};

Title.defaultProps = {
  heading: '',
};

Title.propTypes = {
  heading: PropTypes.string,
};

export default Title;
