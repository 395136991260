import React, { useEffect, useState } from 'react';
import {
  GoogleMap,
  Marker,
  OverlayView,
  withGoogleMap,
  withScriptjs,
} from 'react-google-maps';
import { compose, withHandlers } from 'recompose';
import { Gym } from '../../interfaces/GymData';
import CustomLink from '../CustomLink/CustomLink.component';
import { GeoCoords } from '../FindAGym/FindAGym.component';
import TagManager from 'react-gtm-module';

const MAP_STYLES = [
  {
    elementType: 'geometry',
    stylers: [
      {
        color: '#f5f5f5',
      },
    ],
  },
  {
    elementType: 'labels.icon',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#616161',
      },
    ],
  },
  {
    elementType: 'labels.text.stroke',
    stylers: [
      {
        color: '#f5f5f5',
      },
    ],
  },
  {
    featureType: 'administrative',
    elementType: 'geometry',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'administrative.land_parcel',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#bdbdbd',
      },
    ],
  },
  {
    featureType: 'poi',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'poi',
    elementType: 'geometry',
    stylers: [
      {
        color: '#eeeeee',
      },
    ],
  },
  {
    featureType: 'poi',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#757575',
      },
    ],
  },
  {
    featureType: 'poi.park',
    elementType: 'geometry',
    stylers: [
      {
        color: '#e5e5e5',
      },
    ],
  },
  {
    featureType: 'poi.park',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#9e9e9e',
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'geometry',
    stylers: [
      {
        color: '#ffffff',
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'labels.icon',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'road.arterial',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#757575',
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry',
    stylers: [
      {
        color: '#dadada',
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#616161',
      },
    ],
  },
  {
    featureType: 'road.local',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#9e9e9e',
      },
    ],
  },
  {
    featureType: 'transit',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'transit.line',
    elementType: 'geometry',
    stylers: [
      {
        color: '#e5e5e5',
      },
    ],
  },
  {
    featureType: 'transit.station',
    elementType: 'geometry',
    stylers: [
      {
        color: '#eeeeee',
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'geometry',
    stylers: [
      {
        color: '#c9c9c9',
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#9e9e9e',
      },
    ],
  },
];

const getPixelPositionOffset = (width: number, height: number) => ({
  x: -(width / 2),
  y: -height - 36,
});

const getMarkerIcon = (active: boolean) =>
  active
    ? 'https://a.storyblok.com/f/132963/x/03308af0a1/map-icon-active.svg'
    : 'https://a.storyblok.com/f/132963/x/cdcc691506/map-icon.svg';

interface GymMapProps {
  googleMapURL: string;
  loadingElement: JSX.Element;
  containerElement: JSX.Element;
  mapElement: JSX.Element;
  gyms: Gym[];
  userLocation: GeoCoords;
}

const refs = {
  map: null,
};

const CENTRE_OF_UK_LAT_LNG = { lat: 52.8203922, lng: -2.320493 };

const GymMapComponent: React.FC<GymMapProps> = compose(
  withScriptjs,
  withGoogleMap,
  withHandlers(() => ({
    onMapMounted: () => (ref) => {
      refs.map = ref;
    },
  }))
)(({ gyms, userLocation, ...props }) => {
  const [selectedGym, setSelectedGym] = useState<Gym>(null);

  useEffect(() => {
    if (selectedGym) {
      refs.map.panTo(selectedGym.coordinates);
      refs.map.panBy(0, -120);

      TagManager.initialize({
        gtmId: process.env.GATSBY_GTM_CONTAINER_ID,
        dataLayer: {
          event: 'gym_search_clicks',
          search_lat: selectedGym.coordinates.lat,
          search_lng: selectedGym.coordinates.lng,
          click_search_url: selectedGym.page_url,
          click_search_text: selectedGym.name,
        },
      });
    }
  }, [selectedGym]);

  const getMapBounds = (gyms) => {
    const bounds = new window.google.maps.LatLngBounds();

    if (userLocation) {
      bounds.extend(
        new window.google.maps.LatLng(userLocation.lat, userLocation.lng)
      );
    }

    gyms.map((gym) => {
      bounds.extend(
        new window.google.maps.LatLng(gym.coordinates.lat, gym.coordinates.lng)
      );
    });

    return bounds;
  };

  const fitBoundsOnSearch = (map, gyms) => {
    const bounds = getMapBounds(gyms);
    map.fitBounds(bounds);
  };

  useEffect(() => {
    if (gyms.length) {
      fitBoundsOnSearch(refs.map, gyms);
    }
  }, [gyms]);

  return (
    <GoogleMap
      defaultZoom={2}
      ref={props.onMapMounted}
      defaultCenter={CENTRE_OF_UK_LAT_LNG}
      defaultOptions={{
        styles: MAP_STYLES as any,
        mapTypeControl: false,
        fullscreenControl: false,
        streetViewControl: false,
      }}
    >
      {gyms.map((gym, i) => (
        <Marker
          key={`marker-${gym.uid}${i}`}
          position={{ lat: gym.coordinates.lat, lng: gym.coordinates.lng }}
          icon={getMarkerIcon(gym.uid === selectedGym?.uid)}
          onClick={() =>
            gym === selectedGym ? setSelectedGym(null) : setSelectedGym(gym)
          }
        />
      ))}

      {selectedGym && (
        <OverlayView
          position={{
            lat: selectedGym.coordinates.lat,
            lng: selectedGym.coordinates.lng,
          }}
          mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
          getPixelPositionOffset={getPixelPositionOffset}
        >
          <div className="bg-black bg-texture-tertiary bg-texture/tertiary px-7 pt-9 pb-8 text-center">
            <p className="uppercase font-oskari-g2--bold sm:font-oskari-g2--normal antialiased sm:text-3xl">
              {selectedGym.name}
            </p>
            <p className="text-lg font-oskari-g2--regular antialiased py-6 leading-7 max-w-xs">
              {selectedGym.address}
            </p>
            <p className="text-lg font-oskari-g2--semibold antialiased leading-6 mb-6">
              {selectedGym.contact_number}
            </p>
            <CustomLink
              className="pt-1 flex items-center justify-center transition-all hover:bg-primary cursor-pointer uppercase text-xl font-oskari-g2--semibold antialiased bg-white text-black h-14"
              url={selectedGym.page_url}
            >
              View Gym
            </CustomLink>
          </div>
        </OverlayView>
      )}
    </GoogleMap>
  );
});

export default GymMapComponent;
