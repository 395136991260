import PropTypes from 'prop-types';
import React from 'react';
import FiftyFiftyImageText from '../../components/FiftyFiftyImageText/FiftyFiftyImageText.component';
import { Blok } from '../../interfaces/Blok.interface';
import { StoryblokAsset } from '../../interfaces/Asset.interface';
import { StoryblokLink } from '../../interfaces/StoryblokLink.interface';
import { withBlokContainer } from '../../utils/withBlokContainer';

interface CTA {
  label: string;
  link: StoryblokLink;
}

interface FiftyFiftyImageTextBlokProps extends Blok {
  icon: StoryblokAsset;
  heading: string;
  subheading: string;
  body: any;
  cta: CTA;
  image: StoryblokAsset;
  reverse: boolean;
  video: StoryblokLink;
  tight_padding: boolean;
}

const FiftyFiftyImageTextBlok: React.FC<FiftyFiftyImageTextBlokProps> = ({
  icon,
  heading,
  subheading,
  body,
  cta,
  image,
  reverse,
  anchor,
  theme: themeInput,
  video,
  tight_padding,
  ...props
}) => {
  const ctaData = cta && cta[0];
  const ctaContent = ctaData && {
    label: ctaData.label,
    link: ctaData.Gym ? ctaData.Gym.content[ctaData.url] : ctaData.link,
    _uid: ctaData._uid,
    anchor: ctaData.anchor,
    component: ctaData.component,
    _editable: ctaData._editable,
  };

  // When the user clicks into the blok to edit - The theme, if not set will be an empty string
  const theme = themeInput || 'DARK';

  return withBlokContainer(
    <FiftyFiftyImageText
      icon={icon}
      heading={heading}
      subheading={subheading}
      body={body}
      cta={ctaContent}
      image={image}
      reverse={reverse}
      theme={theme}
      video={video}
      tight_padding={tight_padding}
      {...props}
    />,
    anchor
  );
};

FiftyFiftyImageTextBlok.defaultProps = {
  heading: '',
  subheading: '',
  body: null,
  cta: null,
  image: null,
  reverse: false,
};

FiftyFiftyImageTextBlok.propTypes = {
  heading: PropTypes.string,
  subheading: PropTypes.string,
  body: PropTypes.any,
  cta: PropTypes.any,
  image: PropTypes.any,
  reverse: PropTypes.bool,
};

export default FiftyFiftyImageTextBlok;
