import PropTypes from 'prop-types';
import React from 'react';
import { StoryblokAsset } from '../../interfaces/Asset.interface';
import RichText from '../RichText/RichText.component';

interface SmallImageHeroProps {
  image: StoryblokAsset;
  heading: any;
  subheading: any;
}

const SmallImageHero: React.FC<SmallImageHeroProps> = ({
  image,
  heading,
  subheading,
}) => {
  return (
    <React.Fragment>
      <section
        className="bg-body/body relative overflow-hidden w-full bg-cover bg-no-repeat bg-83-0 sm:bg-top pt-44 sm:pt-48 px-4 sm:px-6"
        style={{
          backgroundImage: `linear-gradient(
              90deg,
              rgba(0, 0, 0, 0.9) 0%,
              rgba(0, 0, 0, 0) 100%
              ), url(${image.filename})`,
        }}
      >
        <div className="text-white w-full text-center pb-24 sm:pb-28 container m-auto">
          {heading && (
            <div className="">
              <RichText document={heading} />
            </div>
          )}
          {subheading && (
            <div className="pt-4">
              <RichText document={subheading} textSize="text-xl" />
            </div>
          )}
        </div>
      </section>
    </React.Fragment>
  );
};

SmallImageHero.defaultProps = {
  image: null,
  heading: '',
  subheading: '',
};

SmallImageHero.propTypes = {
  image: PropTypes.any,
  heading: PropTypes.any,
  subheading: PropTypes.any,
};

export default SmallImageHero;
