import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

interface CustomLinkProps {
  url?: string;
  className?: string;
  activeClass?: string;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
}

const CustomLink: React.FC<CustomLinkProps> = ({
  children,
  url,
  className,
  activeClass,
  onClick,
}) => {
  let path = url || '';

  const isOutboundLink =
    /^https?:\/\//.test(url) || path?.startsWith('mailto:');

  if (isOutboundLink) {
    return (
      <a
        href={path}
        rel="noreferrer noopener"
        aria-label={path}
        className={className}
      >
        {children}
      </a>
    );
  }

  if (!path?.startsWith('/')) path = `/${path}`;

  if (path === '/home') path = '/';

  return (
    <Link
      to={path}
      aria-label={path}
      className={className}
      activeClassName={activeClass}
    >
      {children}
    </Link>
  );
};

CustomLink.defaultProps = {
  url: '',
};

CustomLink.propTypes = {
  url: PropTypes.string.isRequired,
};

export default CustomLink;
