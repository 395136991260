import PropTypes from 'prop-types';
import React from 'react';
import { Blok } from '../../interfaces/Blok.interface';
import { StoryblokAsset } from '../../interfaces/Asset.interface';
import Button from '../Button/Button.component';
import { StoryblokLink } from '../../interfaces/StoryblokLink.interface';

interface CTA extends Blok {
  label: string;
  link: StoryblokLink;
}

interface FullWidthCTAProps {
  heading: string;
  cta: CTA;
  image: StoryblokAsset;
}

const FullWidthCTA: React.FC<FullWidthCTAProps> = ({ heading, cta, image }) => {
  return (
    <section
      className="bg-repeat bg-cover"
      style={{ backgroundImage: `url(${image.filename})` }}
    >
      <div className="banner-img">
        <div className="py-12">
          <div className="container mx-auto">
            {heading && (
              <h3 className="text-4xl sm:text-5xl font-oskari-g2--bold antialiased text-center text-white pt-1">
                {heading}
              </h3>
            )}
            {cta && (
              <div className="sm:flex sm:justify-center pt-3">
                <Button cta_label={cta.label} cta_link={cta.link} />
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

FullWidthCTA.defaultProps = {
  heading: '',
  cta: null,
  image: null,
};

FullWidthCTA.propTypes = {
  heading: PropTypes.string,
  cta: PropTypes.any,
  image: PropTypes.any,
};

export default FullWidthCTA;
