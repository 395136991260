import * as React from 'react';
import DynamicComponent from '../components/DynamicComponent/DynamicComponent.component';
import { Blok } from '../interfaces/Blok.interface';

const renderBloks = (body: Blok[]): JSX.Element[] =>
  body?.reduce(
    (bloks, blok) => [
      ...bloks,
      <DynamicComponent blok={blok} key={blok._uid} />,
    ],
    []
  );

export default renderBloks;
