import StoryblokClient from 'storyblok-js-client';
import { ClassDetails } from './types';

export const formatClassDetails = (classes: ClassDetails[]) => {
  const details = {};

  if (classes.length > 0) {
    // Loop through the classes and create an object with the class name as the key
    classes.forEach((item) => {
      const { slug, content } = item;

      details[slug] = {
        title: content.title,
        image: content.image,
        body: content.body,
        background: content.background,
        video: content.video,
      };
    });
  }

  return details;
};

export const getClasses = async () => {
  // Fetch "Classes" from the storyblok API to use as data for the Modal popups
  const sbClient = new StoryblokClient({
    accessToken: process.env.GATSBY_STORYBLOK_ACCESS_TOKEN,
    cache: {
      clear: 'auto',
      type: 'memory',
    },
  });

  const results = await sbClient.get(`cdn/stories/`, {
    starts_with: 'data/classes/',
  });

  return results.data.stories;
};
