import PropTypes from 'prop-types';

export interface StoryblokAsset {
  alt: string;
  copyright: string;
  fieldtype: string;
  filename: string;
  focus: string;
  id: number;
  name: string;
  title: string;
}

export const StoryblokAssetPropType = PropTypes.exact({
  alt: PropTypes.string,
  copyright: PropTypes.string,
  fieldtype: PropTypes.string,
  filename: PropTypes.string,
  focus: PropTypes.string,
  id: PropTypes.number,
  name: PropTypes.string,
  title: PropTypes.string
});
