import PropTypes from 'prop-types';
import React from 'react';
import RichText from '../RichText/RichText.component';
import Button from '../Button/Button.component';
import { StoryblokLink } from '../../interfaces/StoryblokLink.interface';
import { StoryblokAsset } from '../../interfaces/Asset.interface';
import { Blok } from '../../interfaces/Blok.interface';

interface CTA extends Blok {
  label: string;
  link: StoryblokLink;
}
interface ImageHeroProps {
  image: StoryblokAsset;
  heading: any;
  subheading: any;
  cta: CTA;
}

const ImageHero: React.FC<ImageHeroProps> = ({
  image,
  heading,
  subheading,
  cta,
}) => {
  return (
    <React.Fragment>
      <section
        className="bg-body/body relative overflow-hidden w-full h-[750px] bg-cover bg-no-repeat bg-83-0 sm:bg-top pt-44 sm:pt-52 px-4 sm:px-6"
        style={{
          backgroundImage: `linear-gradient(
              90deg,
              rgba(0, 0, 0, 0.9) 0%,
              rgba(0, 0, 0, 0) 100%
              ), url(${image.filename})`,
        }}
      >
        <div className="text-white w-full text-center pb-16 container m-auto sm:pb-8 lg:pb-0">
          {heading && (
            <div className="py-8 sm:py-10">
              <RichText document={heading} />
            </div>
          )}
          {subheading && (
            <div className="py-4 sm:py-5">
              <RichText document={subheading} />
            </div>
          )}
          {cta && (
            <div className="flex justify-center">
              <Button cta_label={cta.label} cta_link={cta.link} />
            </div>
          )}
        </div>
      </section>
    </React.Fragment>
  );
};

ImageHero.defaultProps = {
  image: null,
  heading: '',
  subheading: '',
  cta: null,
};

ImageHero.propTypes = {
  image: PropTypes.any,
  heading: PropTypes.any,
  subheading: PropTypes.any,
  cta: PropTypes.any,
};

export default ImageHero;
