import PropTypes from 'prop-types';
import React from 'react';
import { StoryblokAsset } from '../../interfaces/Asset.interface';
import { Blok } from '../../interfaces/Blok.interface';
import Footer from '../../components/Footer/Footer.component';
import { withBlokContainer } from '../../utils/withBlokContainer';

interface FooterBlokProps extends Blok {
  logo: StoryblokAsset;
  menus: Blok[];
  copyright_text: string;
}

const FooterBlok: React.FC<FooterBlokProps> = ({
  logo,
  menus,
  copyright_text,
  anchor,
}) => {
  return withBlokContainer(
    <Footer logo={logo} menus={menus} copyright_text={copyright_text} />,
    anchor
  );
};

FooterBlok.defaultProps = {
  logo: null,
  menus: [],
  copyright_text: '',
};

FooterBlok.propTypes = {
  logo: PropTypes.any,
  menus: PropTypes.any,
  copyright_text: PropTypes.string,
};

export default FooterBlok;
