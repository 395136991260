import MetaData from '../interfaces/MetaData.interface';

const isJSON = (string) => {
  try {
    JSON.parse(string);
    return true;
  } catch (e) {
    return false;
  }
};

const transformModel = (content: any) => {
  return {
    description: content?.description || '',
    og_image: content?.og_image || '',
    og_title: content?.og_title || '',
    og_description: content?.og_description || '',
    plugin: content?.plugin || '',
    title: content?.title || '',
    twitter_description: content?.twitter_description || '',
    twitter_image: content?.twitter_image || '',
    twitter_title: content?.twitter_title || '',
    _uid: content?._uid || '',
  };
};

/**
 * Model to transform meta data to keep an internal reference
 * decoupled from the CMS values
 */
const getMetaData = (content: any, fieldName: string = 'SEO'): MetaData => {
  return isJSON(content)
    ? transformModel(JSON.parse(content)[fieldName])
    : transformModel(content[fieldName]);
};

export default getMetaData;
