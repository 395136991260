import React, { useContext } from 'react';
import { MembershipContext } from '../../contexts/MembershipContext';
import { PageContext } from '../../contexts/PageContext';
import { MembershipLayout } from '../../interfaces/MembershipLayout.enum';
import { Membership } from './memberships.interface';

interface MembershipEventWrapperProps {
  membership: Membership;
  event: string;
  children: React.ReactNode;
}

const MembershipEventWrapper: React.FC<MembershipEventWrapperProps> = ({
  membership,
  event,
  children,
}) => {
  const TagManagerFeatureKeys = membership.features.map(
    (feature, idx) => `item_category${idx !== 0 ? idx : ''}`
  );
  const { membershipDetails } = useContext(MembershipContext);
  const { gym } = useContext(PageContext);

  return (
    <div
      onClick={() => {
        const TagManagerFeatureKeys = membership.features.map(
          (feature, idx) => `item_category${idx !== 0 ? idx : ''}`
        );
        const membership_data = {
          item_name: membership.membership_name,
          item_id: membership.fsi_product_code,
          site_id: gym.name,
          price:
            Number(membership.price_per_month) +
            Number(membership.joining_fee_price),
          discount: membership.discounted_price_per_month,
          joining_fee: membership.joining_fee_price,
          currency: 'GBP',
          coupon: membership?.promotion?.promotion_copy,
          item_brand: 'Everlast',
          item_list_name:
            membershipDetails?.component === MembershipLayout.HORIZONTAL
              ? 'variant 1'
              : membershipDetails?.component === MembershipLayout.VERICAL
              ? 'variant 2'
              : 'variant 3',
          item_list_id:
            membershipDetails?.component === MembershipLayout.HORIZONTAL
              ? 'variant_1'
              : membershipDetails?.component === MembershipLayout.VERICAL
              ? 'variant_2'
              : 'variant_3',
          // index: idx,
          quantity: 1,
        };
        membership.features.map(
          (feature, idx) =>
            (membership_data[TagManagerFeatureKeys[idx]] = feature.feature_name)
        );
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: event,
          ecommerce: {
            items: [membership_data],
          },
        });
      }}
    >
      {children}
    </div>
  );
};

export default MembershipEventWrapper;
