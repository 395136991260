import PropTypes from "prop-types";

export const StoryblokImagePropTypes = {
  alt: PropTypes.string,
  copyright: PropTypes.string,
  fieldtype: PropTypes.string,
  filename: PropTypes.string,
  _uid: PropTypes.string,
  focus: PropTypes.any,
  id: PropTypes.number,
  name: PropTypes.string,
  title: PropTypes.string,
};

export const BlokPropTypes = {
  _uid: PropTypes.string.isRequired,
  component: PropTypes.string.isRequired,
  _editable: PropTypes.string,
  theme: PropTypes.string.isRequired,
  background_image: PropTypes.exact(StoryblokImagePropTypes),
};

export const StoryblokLinkPropTypes = {
  anchor: PropTypes.string,
  cached_url: PropTypes.string,
  fieldtype: PropTypes.string,
  _uid: PropTypes.string,
  id: PropTypes.string,
  linktype: PropTypes.string,
  url: PropTypes.string,
};

const StoryblokRichTextContentContentPropTypes = {
  type: PropTypes.string,
  text: PropTypes.string,
};

const StoryblokRichTextContentPropTypes = {
  type: PropTypes.string,
  content: PropTypes.arrayOf(
    PropTypes.exact(StoryblokRichTextContentContentPropTypes)
  ),
};

export const StoryblokRichTextPropTypes = {
  type: PropTypes.string,
  content: PropTypes.arrayOf(
    PropTypes.exact(StoryblokRichTextContentPropTypes)
  ),
};
