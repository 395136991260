import React, { useState } from 'react';

interface PostcodeLookupProps {
  onSubmit: (query: string) => void;
  onReset: () => void;
  showResetButton: boolean;
}

const PostcodeLookup: React.FC<PostcodeLookupProps> = ({
  onSubmit,
  onReset,
  showResetButton,
}) => {
  const [address, setAddress] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(address);
  };

  const handleReset = (e) => {
    e.preventDefault();
    setAddress('');
    onReset();
  };

  return (
    <div className="pb-4 pt-4">
      <p className="uppercase font-bold text-sm pb-2">Find by postcode</p>
      <div className="relative">
        <form onSubmit={handleSubmit}>
          <input
            id="addressInput"
            className="w-full pl-2 h-12 text-body/body focus:ring-0 focus:outline-none"
            onChange={(e) => setAddress(e.target.value)}
            value={address}
          />
          <button>
            <svg
              width="18"
              height="18"
              className="absolute right-3 top-0 bottom-0 m-auto"
            >
              <path d="M12.5 11h-.79l-.28-.27a6.5 6.5 0 0 0 1.48-5.34c-.47-2.78-2.79-5-5.59-5.34A6.505 6.505 0 0 0 .05 7.32c.34 2.8 2.56 5.12 5.34 5.59a6.5 6.5 0 0 0 5.34-1.48l.27.28v.79l4.25 4.25c.41.41 1.08.41 1.49 0 .41-.41.41-1.08 0-1.49L12.5 11Zm-6 0C4.01 11 2 8.99 2 6.5S4.01 2 6.5 2 11 4.01 11 6.5 8.99 11 6.5 11Z" />
            </svg>
          </button>
        </form>
      </div>
      {showResetButton && (
        <button
          onClick={handleReset}
          className="pt-2 font-oskari-g2--regular text-lg underline"
        >
          Reset to show all gyms
        </button>
      )}
    </div>
  );
};

export default PostcodeLookup;
