import Axios, { AxiosResponse } from 'axios';
import { format } from 'date-fns';
import { Slot } from '../../../interfaces/Slot.interface';
import {
  TimetableDataSource,
  TimetableDataSourceResponse,
} from '../timetable.context';
import { ModalData } from '../../../components/Timetable/Timetable.component';
import { formatClassDetails, getClasses } from '../timeline.helpers';

export interface EventType {
  value: string;
  listType: string;
  display: string;
}

export interface EventDuration {
  value: string;
  listType: string;
  display: string;
}

export interface FirstSportResponseItem {
  siteID: string;
  siteName: string;
  venueName: string;
  eventName: string;
  eventDescription: string;
  eventType: EventType;
  eventStartDate: string;
  eventStartTime: string;
  eventDayOfWeek: number;
  eventEndDate: string;
  eventEndTime: string;
  eventDuration: EventDuration;
  eventCapacity: number;
  eventSpacesRemaining: number;
  eventInstructorName?: any;
  eventColour: string;
  eventImage: string;
  eventURL: string;
  eventTemplateName: string;
}

interface ClassDetails {
  name: string;
  slug: string;
  content: ModalData;
}

export default class FirstSportDataSource implements TimetableDataSource {
  // Live API
  private readonly API_URL = 'https://api.everlastgyms.com/timetable';

  // Local API
  // private readonly API_URL = 'http://localhost:3000/timetable';

  private readonly DATE_FORMAT = 'yyyyMMddHHmmss';

  constructor() {}

  /**
   * Get Data
   * Dynamically retrieve the timetable data from a static json file located in
   * Gatsby's /static folder by site id. e.g. 0001.json
   * @param siteId
   * @returns
   */
  /**
   * Get Data
   * Dynamically retrieve the timetable data from a static json file located in
   * Gatsby's /static folder by site id. e.g. 0001.json
   * @param siteId
   * @returns
   */
  public async getData(
    siteId: string,
    limited: boolean
  ): Promise<TimetableDataSourceResponse> {
    const classes = await getClasses();
    const details = formatClassDetails(classes);

    // Get Slots From API
    const date = this.getDateOfFirstDayOfWeek();

    const formattedDate = format(date, this.DATE_FORMAT);

    const response = await this.fetch(siteId, formattedDate);

    const slots = this.mapDataToTimetableSlots(response.data, limited);

    return {
      details,
      slots,
    };
  }

  /**
   * Fetch
   * Fetch data from the API
   * @param siteId
   */
  private async fetch(
    siteId: string,
    date: string
  ): Promise<AxiosResponse<FirstSportResponseItem[]>> {
    try {
      const response = await Axios.get(this.API_URL, {
        params: {
          siteId,
          date,
        },
      });

      return response;
    } catch {
      throw new Error('Unable to fetch timetable data from remote service');
    }
  }

  /**
   * mapDataToTimetableSlots
   * Map data to our internal `Slot` model
   * @param data
   * @returns
   */
  private mapDataToTimetableSlots(
    data: FirstSportResponseItem[],
    limited: boolean
  ): Slot[] {
    if (limited) {
      return data.map((item) => ({
        name: item.eventTemplateName,
        description: item.venueName,
        type: item.eventTemplateName,
        // The "subtype" is used to match the modal content to the slot - But doesn't matter for limited as no modal appears
        subtype: item.eventTemplateName,
        start: item.eventStartTime,
        end: item.eventEndTime,
        day: item.eventDayOfWeek,
        week: 1,
        // The "location" is used as the "key" at the top, and defines the color of the slot
        location: item.venueName,
      }));
    } else {
      // Return the "full" version of the timetable for gyms that have been refurbished
      return data.map((item) => {
        const modalKey = item.eventTemplateName.toLowerCase().replace(' ', '-');

        return {
          name: item.eventTemplateName,
          description: null,
          type: item.eventTemplateName,
          // The "subtype" is used to match the modal content to the slot - Storyblok "Class" slug
          subtype: modalKey,
          start: item.eventStartTime,
          end: item.eventEndTime,
          day: item.eventDayOfWeek,
          week: 1,
          // The "location" is used as the "key" at the top, and defines the color of the slot
          location: item.eventTemplateName,
        };
      });
    }
  }

  private getDateOfFirstDayOfWeek(date: Date = new Date()) {
    const day = date.getDay();
    const diff = date.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday

    return new Date(date.setDate(diff));
  }
}
