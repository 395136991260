import PropTypes from 'prop-types';
import React from 'react';
import GymSelect from '../../components/GymSelect/GymSelect.component';
import { Blok } from '../../interfaces/Blok.interface';
import { GymDataBlok, transformGymData } from '../../utils/gymData';
import { withBlokContainer } from '../../utils/withBlokContainer';

interface GymSelectBlokProps extends Blok {
  title: any;
  select_gym_label: string;
  login_label?: string;
  login_cta_label: string;
  join_label?: string;
  join_cta_label: string;
  gyms: GymDataBlok[];
  primary_ctas: string[];
}

const GymSelectBlok: React.FC<GymSelectBlokProps> = ({
  gyms,
  anchor,
  ...props
}) => {
  return withBlokContainer(
    <GymSelect gyms={transformGymData(gyms)} {...props} />,
    anchor
  );
};

GymSelectBlok.defaultProps = {
  title: null,
  select_gym_label: '',
  login_label: '',
  login_cta_label: '',
  join_label: '',
  join_cta_label: '',
  gyms: [],
};

GymSelectBlok.propTypes = {
  title: PropTypes.object,
  select_gym_label: PropTypes.string,
  login_label: PropTypes.string,
  login_cta_label: PropTypes.string,
  join_label: PropTypes.string,
  join_cta_label: PropTypes.string,
  gyms: PropTypes.array,
};

export default GymSelectBlok;
