import PropTypes from 'prop-types';
import React from 'react';
import RichText from '../RichText/RichText.component';
import Button from '../Button/Button.component';
import { StoryblokLink } from '../../interfaces/StoryblokLink.interface';
import { Blok } from '../../interfaces/Blok.interface';

interface CTA extends Blok {
  label: string;
  link: StoryblokLink;
}

interface VideoHeroProps {
  video_link: string;
  heading: any;
  subheading: any;
  cta: CTA;
}

const VideoHero: React.FC<VideoHeroProps> = ({
  heading,
  subheading,
  cta,
  video_link,
}) => {
  return (
    <React.Fragment>
      <section className="bg-texture/secondary overflow-hidden">
        <div className="club-video home-video flex relative items-center justify-center h-750px w-full min-w-lg m-auto bg-right overflow-hidden">
          <div className="videoContainer h-750px w-full">
            <video autoPlay loop muted playsInline>
              <source src={video_link} />
              <p>
                To view this video please enable JavaScript, and consider
                upgrading to a web browser that supports HTML5 video
              </p>
            </video>
          </div>
        </div>

        <div className="absolute top-0 left-0 right-0 bottom-0 w-full">
          <div className="pt-44 sm:pt-52 container mx-auto">
            <div className="flex justify-end items-center">
              <div className="text-white w-full text-center pb-16 sm:pb-8 lg:pb-0">
                {heading && (
                  <div className="py-8 sm:py-10">
                    <RichText document={heading} />
                  </div>
                )}
                {subheading && (
                  <div className="md:block">
                    <RichText document={subheading} />
                  </div>
                )}
                {cta && (
                  <div className="flex justify-center md:pt-4">
                    <Button cta_label={cta.label} cta_link={cta.link} />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

VideoHero.defaultProps = {
  video_link: '',
  heading: null,
  subheading: null,
  cta: null,
};

VideoHero.propTypes = {
  video_link: PropTypes.string,
  heading: PropTypes.any,
  subheading: PropTypes.any,
  cta: PropTypes.any,
};

export default VideoHero;
