import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { StoryblokAsset } from '../../interfaces/Asset.interface';
import { Blok } from '../../interfaces/Blok.interface';
import TileImage from '../TileImage/TileImage.component';
import CustomLink from '../CustomLink/CustomLink.component';
import { StoryblokLink } from '../../interfaces/StoryblokLink.interface';
import { Collapse } from 'react-collapse';
import { getLinkUrl } from '../../utils/getLinkUrl';

interface ItemBlok extends Blok {
  label: string;
  link: StoryblokLink;
}
interface MenuBlok extends Blok {
  label: string;
  menu_items: ItemBlok[];
}
interface FooterProps {
  logo: StoryblokAsset;
  menus: MenuBlok[];
  copyright_text: string;
}
interface AccoridonProps {
  label: string;
  menuItems: ItemBlok[];
}

const AccordionItem: React.FC<AccoridonProps> = ({ label, menuItems }) => {
  const [isOpened, setIsOpened] = useState(false);

  return (
    <li>
      <a
        onClick={() => setIsOpened((state) => !state)}
        className="group flex justify-between items-center md:block cursor-pointer"
      >
        {label && (
          <h4 className="text-white fill-white group-hover:text-primary text-2xl font-oskari-g2--bold antialiased py-4 uppercase">
            {label}
          </h4>
        )}
        <svg
          width="22"
          height="22"
          viewBox="0 0 22 22"
          className={`text-white fill-white group-hover:text-primary transition-all md:hidden transform ${
            isOpened ? `-rotate-90` : `rotate-90`
          }`}
        >
          <path d="M1.66668 12.3334H16.56L10.0533 18.84C9.53334 19.36 9.53334 20.2134 10.0533 20.7334C10.5733 21.2534 11.4133 21.2534 11.9333 20.7334L20.72 11.9467C21.24 11.4267 21.24 10.5867 20.72 10.0667L11.9467 1.26671C11.4267 0.746709 10.5867 0.746709 10.0667 1.26671C9.54668 1.78671 9.54668 2.62671 10.0667 3.14671L16.56 9.66671H1.66668C0.933343 9.66671 0.333344 10.2667 0.333344 11C0.333344 11.7334 0.933343 12.3334 1.66668 12.3334Z" />
        </svg>
      </a>

      {menuItems && (
        <Collapse
          isOpened={isOpened}
          initialStyle={{
            height: 0,
            overflow: 'hidden',
            transition: 'height 500ms',
          }}
        >
          <ul className="inner">
            {menuItems.map((item) => (
              <li className="pb-6" key={item._uid}>
                <CustomLink
                  url={getLinkUrl(item.link)}
                  className="font-oskari-g2--regular antialiased"
                >
                  {item.label}
                </CustomLink>
              </li>
            ))}
          </ul>
        </Collapse>
      )}
    </li>
  );
};

const Footer: React.FC<FooterProps> = ({ logo, menus, copyright_text }) => {
  return (
    <footer>
      <div className="bg-texture-secondary bg-repeat bg-200 bg-texture/secondary">
        <div className="container mx-auto">
          <div className="text-white py-8 md:py-16">
            <div className="md:flex md:pb-2">
              <div className="w-6.25 md:w-11.375 h-6.25 md:h-9.375">
                <a
                  href="https://www.everlastgyms.com/"
                  aria-label="everlast link"
                >
                  <TileImage image={logo}></TileImage>
                </a>
              </div>

              <div className="hidden md:grid md:grid-cols-3 md:pl-20 w-full">
                {menus.map((menu, i) => (
                  <div className={i !== 0 ? 'md:pl-8' : ''} key={menu._uid}>
                    {menu.label && (
                      <h4 className="text-2xl font-oskari-g2--bold antialiased py-8 md:pb-8 md:pt-0 uppercase">
                        {menu.label}
                      </h4>
                    )}
                    {menu.menu_items && (
                      <ul className="hidden md:block text-lg font-oskari-g2--bold antialiased md:pt-8 lg:pt-0">
                        {menu.menu_items.map((item) => (
                          <li className="pb-6" key={item._uid}>
                            <CustomLink
                              url={getLinkUrl(item.link)}
                              className="font-oskari-g2--regular antialiased hover:text-primary transition-all"
                            >
                              {item.label}
                            </CustomLink>
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                ))}
              </div>
              <div>
                <ul className="accordion md:hidden block text-lg font-oskari-g2--semibold antialiased py-4">
                  {menus.map((menu, i) => (
                    <AccordionItem
                      label={menu.label}
                      menuItems={menu.menu_items}
                      key={menu._uid}
                    ></AccordionItem>
                  ))}
                </ul>
              </div>
            </div>
            {copyright_text && (
              <p className="font-oskari-g2--regular antialiased md:pt-8 md:pb-2">
                {copyright_text}
              </p>
            )}
          </div>
        </div>
      </div>
    </footer>
  );
};

Footer.defaultProps = {
  logo: null,
  menus: [],
  copyright_text: '',
};

Footer.propTypes = {
  logo: PropTypes.any,
  menus: PropTypes.any,
  copyright_text: PropTypes.string,
};

export default Footer;
