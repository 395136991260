import classNames from 'classnames';
import React, { useContext, useState } from 'react';
import { MembershipContext } from '../../../contexts/MembershipContext';
import { MembershipLayout } from '../memberships.interface';
import MembershipPrimaryVerticalCard from './MembershipPrimaryVerticalCard';
import MembershipSecondaryVerticalCard from './MembershipSecondaryVerticalCard';
import ConcessionMemberships from '../ConcessionMemberships.component';

const MembershipVerticalLayout: React.FC<MembershipLayout> = ({}) => {
  const { memberships, concessionMemberships } = useContext(MembershipContext);
  const hasFeaturedStudentMembership = Boolean(
    concessionMemberships.find((membership) => membership.featured_membership)
  );

  return (
    <>
      <div>
        {memberships.map((membership, idx) => {
          const { featured_membership } = membership;
          return (
            featured_membership && (
              <MembershipPrimaryVerticalCard
                membership={membership}
                key={membership._uid + idx}
                featuredMembership
              />
            )
          );
        })}

        <div className="container mx-auto grid grid-cols-12 gap-x-0 gap-y-10 sm:gap-10 pt-10 lg:pt-20">
          {memberships.map((membership, idx) => {
            const { featured_membership } = membership;
            return (
              !featured_membership && (
                <MembershipSecondaryVerticalCard
                  membership={membership}
                  key={membership._uid + idx}
                />
              )
            );
          })}
        </div>
      </div>
      {concessionMemberships && !!concessionMemberships.length && (
        <ConcessionMemberships>
          <div
            className={classNames({ 'pt-20': hasFeaturedStudentMembership })}
          >
            {concessionMemberships.map((membership, idx) => {
              const { featured_membership } = membership;
              return (
                featured_membership && (
                  <MembershipPrimaryVerticalCard
                    membership={membership}
                    key={membership._uid + idx}
                    featuredMembership
                  />
                )
              );
            })}

            <div className="container mx-auto grid grid-cols-12 gap-x-0 gap-y-10 sm:gap-10 pt-10 lg:pt-20">
              {concessionMemberships.map((membership, idx) => {
                const { featured_membership } = membership;
                return (
                  !featured_membership && (
                    <MembershipSecondaryVerticalCard
                      membership={membership}
                      key={membership._uid + idx}
                    />
                  )
                );
              })}
            </div>
          </div>
        </ConcessionMemberships>
      )}
    </>
  );
};

export default MembershipVerticalLayout;
