import PropTypes from 'prop-types';
import React from 'react';
import { Blok } from '../../interfaces/Blok.interface';
import { StoryblokAsset, StoryblokAssetPropType } from '../../interfaces/Asset.interface';
import ImageCarousel from '../../components/ImageCarousel/ImageCarousel.component';
import { withBlokContainer } from '../../utils/withBlokContainer';

interface ImageCarouselBlokProps extends Blok {
    images: StoryblokAsset[];
}

const ImageCarouselBlok: React.FC<ImageCarouselBlokProps> = ({
    images,
    anchor
}) => {

    return withBlokContainer(
        <ImageCarousel images={images}/>,
        anchor
    );
};

ImageCarouselBlok.defaultProps = {
    images: []
};

ImageCarouselBlok.propTypes = {
    images: PropTypes.arrayOf(StoryblokAssetPropType)
}

export default ImageCarousel;