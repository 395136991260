import React from 'react';

interface MembershipsProps {
  numberOfCards?: number;
  hasNoFeatureCard: boolean;
  hasMultipleFeatureCards: boolean;
}

const MembershipsPlaceholder: React.FC<MembershipsProps> = ({
  numberOfCards,
  hasNoFeatureCard,
  hasMultipleFeatureCards,
}) => {
  return (
    <section className="bg-texture-primary bg-texture/primary py-20 ">
      <div className="flex justify-center items-center text-body/body uppercase space-y-4 font-oskari-g2--semibold text-2xl text-center p-2 flex-col">
        {hasNoFeatureCard && <p>No feature membership selected</p>}
        {hasMultipleFeatureCards && (
          <p>Too many feature memberships selected</p>
        )}
        {numberOfCards > 2 && <p>Too many memberships applied</p>}
        {numberOfCards < 2 && <p>Not enough memberships applied</p>}
      </div>
    </section>
  );
};

export default MembershipsPlaceholder;
