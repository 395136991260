import PropTypes from 'prop-types';
import React, { useState } from 'react';
import RichText from '../RichText/RichText.component';
import TileImage from '../TileImage/TileImage.component';
import Button from '../Button/Button.component';
import { StoryblokLink } from '../../interfaces/StoryblokLink.interface';
import '../../styles/azureMediaPlayer.css';
import { StoryblokAsset } from '../../interfaces/Asset.interface';
import { Blok } from '../../interfaces/Blok.interface';

interface InfoBlok extends Blok {
  icon: StoryblokAsset;
  label: string;
  body: any;
  table: any;
}

interface CTA extends Blok {
  label: string;
  link: StoryblokLink;
}

interface ClubHeroProps {
  image: StoryblokAsset;
  heading: string;
  cta: CTA;
  info_blok: InfoBlok[];
}

const ClubHero: React.FC<ClubHeroProps> = ({
  image,
  heading,
  cta,
  info_blok,
  ...rest
}) => {
  const [bgIsScaled, setBgIsScaled] = useState(false);

  return (
    <section className="relative overflow-hidden">
      <div>
        <div className="w-full p-4.45"></div>
        <div className="container mx-auto">
          <div className="flex justify-end items-center pb-12 sm:pt-8 sm:pb-12">
            <div className="text-white w-full text-center sm:text-left">
              {heading && (
                <h1 className="uppercase text-3xl font-oskari-g2--bold sm:font-oskari-g2--normal antialiased sm:text-5xl">
                  {heading}
                </h1>
              )}

              {cta && (
                <div className="flex justify-center sm:justify-start sm:pt-5">
                  <div
                    onMouseEnter={() => setBgIsScaled(true)}
                    onMouseLeave={() => setBgIsScaled(false)}
                    className="w-full sm:w-auto"
                  >
                    <Button cta_label={cta.label} cta_link={cta.link} />
                  </div>
                </div>
              )}
              {info_blok && (
                <div className="grid grid-cols-1">
                  {info_blok.map((blok, i) => (
                    <div
                      key={blok._uid}
                      className={`border border-white sm:border-none py-4 px-1 sm:pb-0 sm:mt-0 ${
                        i == 0 ? 'mt-8 sm:pt-14' : 'mt-6 sm:mt-0 sm:pt-8'
                      }`}
                    >
                      {blok.label && (
                        <div className="flex justify-center sm:justify-start items-center">
                          {blok.icon && (
                            <div className="h-8 w-8">
                              <TileImage image={blok.icon}></TileImage>
                            </div>
                          )}
                          {blok.label && (
                            <p className="pt-3 sm:pt-2 sm:block text-xl font-oskari-g2--bold antialiased pl-4 uppercase">
                              {blok.label}
                            </p>
                          )}
                        </div>
                      )}
                      {blok.table && (
                        <div className="flex justify-center sm:justify-start">
                          <div className="grid grid-cols-1 grid-flow-row w-60 pt-4 text-lg font-oskari-g2--regular antialiased">
                            {blok.table.tbody.map((body) => (
                              <div key={body._uid} className="grid grid-cols-2">
                                {body.body.map((body) => (
                                  <div className="col-span-1" key={body._uid}>
                                    {body.value}
                                  </div>
                                ))}
                              </div>
                            ))}
                          </div>
                        </div>
                      )}
                      {blok.body && (
                        <div className="pt-4">
                          {blok.body && (
                            <RichText
                              textSize="text-sm sm:text-lg"
                              document={blok.body}
                            />
                          )}
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {image && (
        <div
          className={`hero-img w-full h-full transform duration-2000 bg-cover bg-no-repeat bg-83-0 sm:bg-top absolute top-0 left-0 bg-texture/secondary ${
            bgIsScaled && `scale-105`
          }`}
          style={{
            zIndex: -1,
            backgroundImage: `linear-gradient(
                90deg,
                rgba(0, 0, 0, 0.9) 0%,
                rgba(0, 0, 0, 0) 100%
                ), url(${image.filename})`,
          }}
        ></div>
      )}
    </section>
  );
};

ClubHero.defaultProps = {
  heading: '',
  cta: null,
  info_blok: null,
};

ClubHero.propTypes = {
  heading: PropTypes.string,
  cta: PropTypes.any,
  info_blok: PropTypes.any,
};

export default ClubHero;
