import Img from 'gatsby-image';
import { getFluidGatsbyImage } from 'gatsby-storyblok-image';
import React from 'react';
import {
  MARK_BOLD,
  MARK_CODE,
  MARK_ITALIC,
  MARK_LINK,
  MARK_STRIKE,
  MARK_STYLED,
  MARK_UNDERLINE,
  MARK_HIGHLIGHT,
  NODE_CODEBLOCK,
  NODE_HEADING,
  NODE_IMAGE,
  NODE_OL,
  NODE_PARAGRAPH,
  NODE_QUOTE,
  NODE_UL,
  render,
} from 'storyblok-rich-text-react-renderer';
import CustomLink from '../CustomLink/CustomLink.component';

interface RichTextProps {
  primaryTextColour?: string;
  secondaryTextColour?: string;
  document: any;
  classNames?: string;
  textSize?: string;
  fontWeight?: string;
}

const RichText: React.FC<RichTextProps> = ({
  primaryTextColour,
  secondaryTextColour,
  document,
  classNames,
  textSize = 'text-base md:text-lg',
  fontWeight = 'regular',
}) => {
  return (
    <div>
      {render(document, {
        markResolvers: {
          [MARK_BOLD]: (children) => (
            <strong className={`font-oskari-g2--bold antialiased`}>
              {children}
            </strong>
          ),
          [MARK_ITALIC]: (children) => <i>{children}</i>,
          [MARK_STRIKE]: (children) => (
            <span className="line-through">{children}</span>
          ),
          [MARK_UNDERLINE]: (children) => (
            <span className="underline">{children}</span>
          ),
          [MARK_CODE]: (children) => <span>{children}</span>,
          [MARK_STYLED]: (children, { class: className }) => (
            <p
              className={`font-oskari-g2--${fontWeight} antialiased ${
                className ? className : ''
              } ${classNames ? classNames : ''} ${
                textSize ? textSize : ''
              } ${secondaryTextColour}`}
            >
              {children}
            </p>
          ),
          [MARK_LINK]: (children, props) => {
            const { href, target, linktype } = props;
            return (
              <CustomLink url={href} className="underline">
                {children}
              </CustomLink>
            );
          },
          [MARK_HIGHLIGHT]: (children, { color }) => {
            return <span style={{ color: color }}>{children}</span>;
          },
        },
        nodeResolvers: {
          [NODE_HEADING]: (children, { level }) => {
            switch (level) {
              case 1:
                return (
                  <h1
                    className={`uppercase text-3xl sm:text-5xl ${primaryTextColour} font-oskari-g2--regular antialiased leading-8`}
                  >
                    {children}
                  </h1>
                );
              case 2:
                return (
                  <h2
                    className={`text-2xl ${primaryTextColour} font-oskari-g2--semibold antialiased leading-8`}
                  >
                    {children}
                  </h2>
                );
              case 3:
                return (
                  <h3
                    className={`text-2xl ${primaryTextColour} font-oskari-g2--semibold antialiased leading-8`}
                  >
                    {children}
                  </h3>
                );
              case 4:
                return (
                  <h4
                    className={`text-xl ${primaryTextColour} font-oskari-g2--${fontWeight} antialiased leading-8`}
                  >
                    {children}
                  </h4>
                );
              case 5:
                return (
                  <h5
                    className={`${primaryTextColour} font-oskari-g2--${fontWeight} antialiased leading-8`}
                  >
                    {children}
                  </h5>
                );
              case 6:
                return (
                  <h6
                    className={`${primaryTextColour} font-oskari-g2--${fontWeight} antialiased leading-8`}
                  >
                    {children}
                  </h6>
                );
            }
          },
          [NODE_PARAGRAPH]: (children) => (
            <p
              className={`font-oskari-g2--${fontWeight} antialiased ${
                classNames ? classNames : ''
              } ${textSize ? textSize : ''} ${secondaryTextColour}`}
            >
              {children}
            </p>
          ),
          [NODE_CODEBLOCK]: (children) => {
            // investigate what relevance of class is which can be added to params
            return <p>{children}</p>;
          },
          [NODE_IMAGE]: (children, { src, alt, title }) => {
            const fluidImage = getFluidGatsbyImage(src);
            return <Img fluid={fluidImage} alt={alt} title={title} />;
          },
          [NODE_UL]: (children) => {
            return <ul className="ml-4 list-disc">{children}</ul>;
          },
          [NODE_OL]: (children) => {
            return (
              <ol
                className={`ml-4 list-decimal ${`font-oskari-g2--${fontWeight} antialiased mb-6 ${
                  classNames ? classNames : ''
                } ${textSize ? textSize : ''} ${secondaryTextColour}`}`}
              >
                {children}
              </ol>
            );
          },
          [NODE_QUOTE]: (children) => {
            return <i>{children}</i>;
          },
        },
        blokResolvers: {
          /*-- think of what custom components that we need in here --*/
        },
        defaultStringResolver: (str) => (
          <p
            className={`font-oskari-g2--${fontWeight} antialiased mb-6 ${
              classNames ? classNames : ''
            } ${textSize ? textSize : ''} ${secondaryTextColour}`}
          >
            {str}
          </p>
        ),
      })}
    </div>
  );
};

export default RichText;
