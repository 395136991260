import PropTypes from 'prop-types';
import React from 'react';
import { Blok } from '../../interfaces/Blok.interface';
import { StoryblokAsset } from '../../interfaces/Asset.interface';
import { StoryblokLink } from '../../interfaces/StoryblokLink.interface';
import { withBlokContainer } from '../../utils/withBlokContainer';
import FiftyFiftyRegistration from '../../components/FiftyFiftyRegistration/FiftyFiftyRegistration.component';
import { GymDataBlok, transformGymData } from '../../utils/gymData';

interface CTA {
  label: string;
  link: StoryblokLink;
}

interface FiftyFiftyRegistrationBlokProps extends Blok {
  heading: string;
  subheading: string;
  body: any;
  cta: CTA;
  image: StoryblokAsset;
  reverse: boolean;

  // Form fields
  formHeading: string;
  formBody: any;
  showForm: boolean;
  gyms: GymDataBlok[];
  formCta: CTA;
}

const FiftyFiftyRegistrationBlok: React.FC<FiftyFiftyRegistrationBlokProps> = ({
  heading,
  subheading,
  body,
  cta,
  image,
  reverse,
  anchor,
  formHeading,
  formBody,
  showForm,
  gyms,
  formCta,
}) => {
  const ctaData = cta && cta[0];
  const ctaContent = ctaData && {
    label: ctaData.label,
    link: ctaData.Gym ? ctaData.Gym.content[ctaData.url] : ctaData.link,
    _uid: ctaData._uid,
    anchor: ctaData.anchor,
    component: ctaData.component,
    _editable: ctaData._editable,
  };
  // Form CTA
  const ctaFormData = formCta && formCta[0];
  const ctaFormContent = ctaFormData && {
    label: ctaFormData.label,
    link: ctaFormData.Gym
      ? ctaFormData.Gym.content[ctaFormData.url]
      : ctaFormData.link,
    _uid: ctaFormData._uid,
    anchor: ctaFormData.anchor,
    component: ctaFormData.component,
    _editable: ctaFormData._editable,
  };

  return withBlokContainer(
    <FiftyFiftyRegistration
      heading={heading}
      subheading={subheading}
      body={body}
      cta={ctaContent}
      image={image}
      reverse={reverse}
      formHeading={formHeading}
      formBody={formBody}
      showForm={showForm}
      gyms={transformGymData(gyms)}
      formCta={ctaFormContent}
    />,
    anchor
  );
};

FiftyFiftyRegistrationBlok.defaultProps = {
  heading: '',
  subheading: '',
  body: null,
  cta: null,
  image: null,
  reverse: false,
};

FiftyFiftyRegistrationBlok.propTypes = {
  heading: PropTypes.string,
  subheading: PropTypes.string,
  body: PropTypes.any,
  cta: PropTypes.any,
  image: PropTypes.any,
  reverse: PropTypes.bool,
};

export default FiftyFiftyRegistrationBlok;
