import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { StoryblokAsset } from '../../interfaces/Asset.interface';
import { BlockTheme, Blok } from '../../interfaces/Blok.interface';
import { Collapse } from 'react-collapse';
import classnames from 'classnames';

interface documentBlok extends Blok {
  label: string;
  document: StoryblokAsset;
}
interface AccordionProps {
  documents: documentBlok[];
  theme?: BlockTheme;
}

interface AccordionItemProps {
  label: string;
  document: StoryblokAsset;
  theme?: BlockTheme;
}

const AccordionItem: React.FC<AccordionItemProps> = ({
  label,
  document,
  theme,
}) => {
  const [isOpened, setIsOpened] = useState(false);

  return (
    <li
      className={classnames('bg-repeat bg-200 mb-6', {
        'bg-texture-primary bg-texture/primary': theme === 'LIGHT',
        'bg-texture-tertiary bg-texture/tertiary': theme === 'DARK',
      })}
    >
      <button
        className="toggle flex justify-between items-center p-6 w-full"
        onClick={() => setIsOpened((state) => !state)}
      >
        <h4
          className={classnames(
            'text-xl sm:text-2xl font-oskari-g2--bold uppercase',
            {
              'text-body/body': theme === 'LIGHT',
              'text-white': theme === 'DARK',
            }
          )}
        >
          {label}
        </h4>
        <svg
          className={classnames('transition-all transform arrow', {
            '-rotate-90': isOpened,
            'rotate-90': !isOpened,
          })}
          width="22"
          height="22"
          viewBox="0 0 22 22"
          fill="none"
        >
          <path
            d="M1.66668 12.3334H16.56L10.0533 18.84C9.53334 19.36 9.53334 20.2134 10.0533 20.7334C10.5733 21.2534 11.4133 21.2534 11.9333 20.7334L20.72 11.9467C21.24 11.4267 21.24 10.5867 20.72 10.0667L11.9467 1.26671C11.4267 0.746709 10.5867 0.746709 10.0667 1.26671C9.54668 1.78671 9.54668 2.62671 10.0667 3.14671L16.56 9.66671H1.66668C0.933343 9.66671 0.333344 10.2667 0.333344 11C0.333344 11.7334 0.933343 12.3334 1.66668 12.3334Z"
            fill={theme === 'LIGHT' ? '#000000' : '#FFFFFF'}
          />
        </svg>
      </button>

      <Collapse
        isOpened={isOpened}
        initialStyle={{
          height: 0,
          overflow: 'hidden',
          transition: 'height 500ms',
        }}
      >
        <ul className="overflow-hidden px-2 bg-white pl-0">
          <li className="m-0 py-8 sm:py-10 flex justify-center">
            <a
              className="flex items-center border border-black px-3 py-5 sm:p-6 cursor-pointer"
              href={document.filename}
              download
            >
              <svg width="49" height="48" viewBox="0 0 49 48" fill="none">
                <path
                  d="M40.5 4H16.5C14.3 4 12.5 5.8 12.5 8V32C12.5 34.2 14.3 36 16.5 36H40.5C42.7 36 44.5 34.2 44.5 32V8C44.5 5.8 42.7 4 40.5 4ZM23.5 19C23.5 20.66 22.16 22 20.5 22H18.5V24.5C18.5 25.32 17.82 26 17 26C16.18 26 15.5 25.32 15.5 24.5V16C15.5 14.9 16.4 14 17.5 14H20.5C22.16 14 23.5 15.34 23.5 17V19ZM33.5 23C33.5 24.66 32.16 26 30.5 26H26.5C25.94 26 25.5 25.56 25.5 25V15C25.5 14.44 25.94 14 26.5 14H30.5C32.16 14 33.5 15.34 33.5 17V23ZM41.5 15.5C41.5 16.32 40.82 17 40 17H38.5V19H40C40.82 19 41.5 19.68 41.5 20.5C41.5 21.32 40.82 22 40 22H38.5V24.5C38.5 25.32 37.82 26 37 26C36.18 26 35.5 25.32 35.5 24.5V16C35.5 14.9 36.4 14 37.5 14H40C40.82 14 41.5 14.68 41.5 15.5ZM18.5 19H20.5V17H18.5V19ZM6.5 12C5.4 12 4.5 12.9 4.5 14V40C4.5 42.2 6.3 44 8.5 44H34.5C35.6 44 36.5 43.1 36.5 42C36.5 40.9 35.6 40 34.5 40H10.5C9.4 40 8.5 39.1 8.5 38V14C8.5 12.9 7.6 12 6.5 12ZM28.5 23H30.5V17H28.5V23Z"
                  fill="#323232"
                />
              </svg>
              <p className="text-xl sm:text-2xl font-oskari-g2--semibold px-3 sm:px-4 pt-1">
                {label}
              </p>
              <svg
                className="hidden sm:block"
                width="25"
                height="24"
                viewBox="0 0 25 24"
                fill="none"
              >
                <path
                  d="M19.5 19H5.5V5H12.5V3H5.5C4.39 3 3.5 3.9 3.5 5V19C3.5 20.1 4.39 21 5.5 21H19.5C20.6 21 21.5 20.1 21.5 19V12H19.5V19ZM14.5 3V5H18.09L8.26 14.83L9.67 16.24L19.5 6.41V10H21.5V3H14.5Z"
                  fill="#323232"
                />
              </svg>
            </a>
          </li>
        </ul>
      </Collapse>
    </li>
  );
};

const Accordion: React.FC<AccordionProps> = ({
  documents,
  theme: themeInput,
}) => {
  // When the user clicks into the blok to edit - The theme, if not set will be an empty string
  const theme = themeInput || 'LIGHT';

  return (
    <div
      className={classnames('bg-repeat bg-200 ', {
        'bg-texture-primary bg-texture/primary': theme === 'LIGHT',
        'bg-texture-tertiary bg-texture/tertiary': theme === 'DARK',
      })}
    >
      <div className="container m-auto pt-6 sm:pt-16 pb-4 sm:pb-8">
        <ul className="block text-lg font-oskari-g2--semibold antialiased py-4">
          {documents.map((documentBlok, i) => (
            <AccordionItem
              label={documentBlok.label}
              document={documentBlok.document}
              key={documentBlok._uid}
              // Swap the theme from the parent
              // ie. if the parent is dark, the children should be light
              theme={theme === 'LIGHT' ? 'DARK' : 'LIGHT'}
            ></AccordionItem>
          ))}
        </ul>
      </div>
    </div>
  );
};

Accordion.defaultProps = {
  documents: [],
};

Accordion.propTypes = {
  documents: PropTypes.any,
};

export default Accordion;
