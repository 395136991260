import PropTypes from 'prop-types';
import React from 'react';
import { Blok } from '../../interfaces/Blok.interface';
import { StoryblokAsset } from '../../interfaces/Asset.interface';
import FullWidthCTA from '../../components/FullWidthCTA/FullWidthCTA.component';
import { withBlokContainer } from '../../utils/withBlokContainer';
import { StoryblokLink } from '../../interfaces/StoryblokLink.interface';

interface CTA {
  label: string;
  link: StoryblokLink;
}

interface FullWidthCTABlokProps extends Blok {
  heading: string;
  cta: CTA;
  image: StoryblokAsset;
}

const FullWidthCTABlok: React.FC<FullWidthCTABlokProps> = ({
  heading,
  cta,
  image,
  anchor,
}) => {
  const ctaData = cta && cta[0];
  const ctaContent = ctaData && {
    label: ctaData.label,
    link: ctaData.Gym ? ctaData.Gym.content[ctaData.url] : ctaData.link,
    _uid: ctaData._uid,
    anchor: ctaData.anchor,
    component: ctaData.component,
    _editable: ctaData._editable,
  };

  return withBlokContainer(
    <FullWidthCTA heading={heading} cta={ctaContent} image={image} />,
    anchor
  );
};

FullWidthCTABlok.defaultProps = {
  heading: '',
  cta: null,
  image: null,
};

FullWidthCTABlok.propTypes = {
  heading: PropTypes.string,
  cta: PropTypes.any,
  image: PropTypes.any,
};

export default FullWidthCTABlok;
