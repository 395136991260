import PropTypes from 'prop-types';
import React from 'react';
import { StoryblokAsset } from '../../interfaces/Asset.interface';
import TileImage from '../TileImage/TileImage.component';

interface FacilityProps {
  icon: StoryblokAsset;
  label: string;
  width?: number;
  height?: number;
}

const Facility: React.FC<FacilityProps> = ({ icon, label, width, height }) => {
  return (
    <div className="h-full w-full flex justify-center items-end flex-wrap col-span-1">
      <TileImage maxWidth={width} maxHeight={height} image={icon}></TileImage>
      <p className="w-full text-center pt-4 text-base sm:text-xl uppercase">
        {label}
      </p>
    </div>
  );
};

Facility.defaultProps = {
  icon: null,
  label: '',
  width: 56,
  height: 58,
};

Facility.propTypes = {
  icon: PropTypes.any,
  label: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default Facility;
