import Img from 'gatsby-image';
import { getFluidGatsbyImage } from 'gatsby-storyblok-image';
import React from 'react';
import { StoryblokAsset } from '../../interfaces/Asset.interface';

interface TileImageProps {
  image: StoryblokAsset;
  maxWidth?: number;
  maxHeight?: number;
  objectFit?: 'fill' | 'contain' | 'cover' | 'scale-down' | 'none';
  objectPosition?:
    | 'top'
    | 'bottom'
    | 'left'
    | 'right'
    | 'center'
    | 'bottom center';
  className?: string;
}

const TileImage: React.FC<TileImageProps> = ({
  image,
  maxWidth = undefined,
  maxHeight = undefined,
  objectFit = 'contain',
  objectPosition = 'center',
  className = '',
}) => {
  const fluidImage =
    image &&
    image.filename &&
    getFluidGatsbyImage(image.filename, { quality: 70 });

  const isSVG = (image: StoryblokAsset): boolean => {
    if (
      image !== undefined &&
      image !== null &&
      image.filename !== null &&
      image.filename !== ''
    ) {
      const fileExtensionRegex: RegExp = /\.[0-9a-z]+$/i;
      const potentialSVGFileExtensions: string[] = ['.svg', '.SVG'];
      return potentialSVGFileExtensions.includes(
        image.filename?.match(fileExtensionRegex)[0]
      );
    }
    return false;
  };

  return (
    <>
      {isSVG(image) ? (
        <div
          style={{
            width: '100%',
            maxWidth: `${maxWidth}px`,
            height: '100%',
            maxHeight: `${maxHeight}px`,
          }}
        >
          <img
            className={className}
            src={image.filename}
            alt={image.alt}
            style={{
              objectFit: objectFit,
              objectPosition: 'center',
              width: '100%',
              maxWidth: `${maxWidth}px`,
              height: '100%',
              maxHeight: `${maxHeight}px`,
            }}
            loading={'lazy'}
          />
        </div>
      ) : (
        fluidImage && (
          <div className="h-full w-full">
            <Img
              className={className}
              fluid={fluidImage}
              alt={image && image.alt ? image.alt : 'Tile image'}
              style={{
                width: '100%',
                maxWidth: maxWidth,
                height: '100%',
                maxHeight: maxHeight,
              }}
              imgStyle={{
                objectFit: objectFit,
                objectPosition: objectPosition,
                width: '100%',
                maxWidth: maxWidth,
                height: '100%',
                maxHeight: maxHeight,
              }}
              loading={'auto'}
            />
          </div>
        )
      )}
    </>
  );
};

export default TileImage;
