import PropTypes from 'prop-types';
import React from 'react';
import { Blok } from '../../interfaces/Blok.interface';
import { StoryblokAsset } from '../../interfaces/Asset.interface';
import Text from '../../components/Text/Text.component';
import { withBlokContainer } from '../../utils/withBlokContainer';

interface TextBlokProps extends Blok {
  body: any;
}

const TextBlok: React.FC<TextBlokProps> = ({ body, anchor }) => {
  return withBlokContainer(<Text body={body} />, anchor);
};

TextBlok.defaultProps = {
  body: null,
};

TextBlok.propTypes = {
  body: PropTypes.any,
};

export default TextBlok;
