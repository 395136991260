import PropTypes from 'prop-types';
import React from 'react';
import { StoryblokAsset } from '../../interfaces/Asset.interface';
import { StoryblokLink } from '../../interfaces/StoryblokLink.interface';
import TileImage from '../TileImage/TileImage.component';
import CustomLink from '../CustomLink/CustomLink.component';
import { getLinkUrl } from '../../utils/getLinkUrl';

interface CTABlokProps {
  icon: StoryblokAsset;
  label: string;
  image: StoryblokAsset;
  link: StoryblokLink;
}

const CTABlok: React.FC<CTABlokProps> = ({ icon, label, image, link }) => {
  return (
    <div className="relative">
      <CustomLink
        url={getLinkUrl(link)}
        className="relative overflow-hidden cursor-pointer group sm:h-full h-44 mb-6 sm:my-0 flex justify-center items-center flex-wrap"
      >
        <div className="flex justify-center items-center flex-wrap absolute top-0 right-0 left-0 bottom-0 z-10">
          <div>
            <svg
              viewBox="0 0 56 56"
              height="56"
              width="56"
              className="transition-all block m-auto pb-5 fill-white fill-white group-hover:fill-primary"
            >
              <path d="M28 24.29c3.557 0 6.505-2.947 6.505-6.504 0-3.557-2.948-6.505-6.505-6.505s-6.505 2.948-6.505 6.505S24.444 24.29 28 24.29Zm0-11.18c2.54 0 4.675 2.135 4.675 4.676 0 2.54-2.134 4.675-4.675 4.675-2.54 0-4.675-2.134-4.675-4.675S25.459 13.11 28 13.11Z" />
              <path d="m27.797 51.122 14.33-20.632c5.386-7.114 4.573-18.904-1.626-25.205C37.147 1.93 32.675 0 27.898 0c-4.776 0-9.248 1.83-12.602 5.285-6.302 6.301-7.013 17.989-1.728 25.103l14.229 20.734ZM16.515 6.606c3.05-3.049 7.013-4.675 11.282-4.675 4.268 0 8.334 1.626 11.281 4.675 5.691 5.692 6.301 16.261 1.423 22.766L27.797 47.869 14.99 29.372c-4.777-6.505-4.167-17.074 1.524-22.766Z" />
              <path d="M39.281 40.145c-.508-.101-1.016.305-1.016.813-.102.508.305 1.017.813 1.017 9.757 1.118 15.042 4.268 15.042 6.098 0 2.54-9.96 6.098-26.12 6.098S1.88 50.613 1.88 48.073c0-1.83 5.184-4.879 15.042-6.098.508-.102.915-.509.813-1.017-.102-.508-.508-.914-1.016-.813C6.86 41.263.05 44.515.05 48.073.05 52.036 9.706 56 28 56c18.396 0 27.95-3.964 27.95-7.927 0-3.456-6.81-6.708-16.669-7.928Z" />
            </svg>
            {label && (
              <h3 className="transition-all text-2xl sm:text-3xl w-full fill-white group-hover:text-primary">
                {label}
              </h3>
            )}
          </div>
        </div>
        <div
          className="h-full w-full transform group-hover:scale-105 bg-cover duration-2000"
          style={{ backgroundImage: `url(${image.filename})` }}
        ></div>
      </CustomLink>
    </div>
  );
};

CTABlok.defaultProps = {
  icon: null,
  label: '',
  image: null,
  link: null,
};

CTABlok.propTypes = {
  icon: PropTypes.any,
  label: PropTypes.string,
  image: PropTypes.any,
  link: PropTypes.any,
};

export default CTABlok;
