import React, { useContext } from 'react';
import CrossIcon from '../../svg/cross.svg';
import { MembershipContext } from '../../contexts/MembershipContext';
import { Membership } from './memberships.interface';
import Button from '../Button/Button.component';
import RichText from '../RichText/RichText.component';
import MembershipEventWrapper from './MembershipEventWrapper.component';

interface MoreInfoModalProps {
  membership: Membership;
  joinUrl: string;
  toggleModal: (state: boolean) => void;
}
const MoreInfoModal: React.FC<MoreInfoModalProps> = ({
  membership,
  joinUrl,
  toggleModal,
}) => {
  const { membershipDetails } = useContext(MembershipContext);

  const { primary_cta_label, up_sell_bg_image, featured_card_bg_image } =
    membershipDetails;

  const { membership_name, membership_term, terms_and_conditions } = membership;

  const backgroundImage =
    featured_card_bg_image?.filename || up_sell_bg_image?.filename;

  return (
    <div className="bg-texture/primary max-w-[700px] 2xl:max-w-[972px]">
      <div className="px-6 pb-6 pt-8 flex justify-between relative z-10">
        <p className="text-white font-oskari-g2--bold text-3xl leading-[30px]">
          {membership_name} - {membership_term}
        </p>
        <button onClick={() => toggleModal(false)}>
          <CrossIcon className={'fill-white'} />
        </button>
        {backgroundImage && (
          <>
            <div
              className="bg-cover bg-white absolute top-0 right-0 left-0 bottom-0 m-auto z-[-2] -scale-x-100"
              style={{
                backgroundImage: `url(${backgroundImage})`,
                WebkitFilter: `brightness(50%) sepia(100%) saturate(200%) hue-rotate(125deg) contrast(88%)`,
                filter: `brightness(50%) sepia(100%) saturate(200%) hue-rotate(125deg) contrast(88%)`,
              }}
            ></div>
            <div className="absolute top-0 right-0 left-0 bottom-0 m-auto z-[-1] bg-gradient-to-r from-body/body to-[transparent]"></div>
          </>
        )}
      </div>
      <div className="p-10 bg-cover text-body/body text-left">
        <div className="space-y-7">
          {terms_and_conditions && <RichText document={terms_and_conditions} />}
        </div>

        <div className="flex mt-10">
          <MembershipEventWrapper membership={membership} event={'add_to_cart'}>
            <Button
              cta_label={primary_cta_label}
              cta_link={{
                anchor: '',
                cached_url: joinUrl,
                fieldtype: 'link',
                _uid: '',
                id: '',
                linktype: '',
                url: joinUrl,
              }}
              className={`bg-body/body border border-body/body hover:bg-white hover:border-white !text-white hover:!text-body/body uppercase px-6 pt-3 pb-2 text-base mt-0`}
            />
          </MembershipEventWrapper>
        </div>
      </div>
    </div>
  );
};

export default MoreInfoModal;
