import PropTypes from 'prop-types';
import React from 'react';

interface PlaceholderComponentProps {
  componentName: string;
}

const Placeholder: React.FC<PlaceholderComponentProps> = ({
  componentName,
}) => (
  <div className="py-4 bg-body/body">
    <p className="text-white italic text-center">
      The component <strong>{componentName}</strong> has not been created yet.
    </p>
  </div>
);

Placeholder.defaultProps = {
  componentName: `unknown`,
};

Placeholder.propTypes = {
  componentName: PropTypes.string,
};

export default Placeholder;
