import PropTypes from 'prop-types';
import React from 'react';
import { Blok } from '../../interfaces/Blok.interface';
import { StoryblokAsset } from '../../interfaces/Asset.interface';
import SecondaryNav from '../../components/SecondaryNav/SecondaryNav.component';
import { StoryblokLink } from '../../interfaces/StoryblokLink.interface';
import { withBlokContainer } from '../../utils/withBlokContainer';

interface LinkBlokProps extends Blok {
  label: string;
  link: StoryblokLink;
}

interface SecondaryNavBlokProps extends Blok {
  links: LinkBlokProps[];
}

const SecondaryNavBlok: React.FC<SecondaryNavBlokProps> = ({
  links,
  anchor,
}) => {
  return withBlokContainer(<SecondaryNav links={links} />, anchor);
};

SecondaryNavBlok.defaultProps = {
  links: null,
};

SecondaryNavBlok.propTypes = {
  links: PropTypes.any,
};

export default SecondaryNavBlok;
