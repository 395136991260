import PropTypes from 'prop-types';
import React from 'react';
import { Blok } from '../../interfaces/Blok.interface';
import TickSVG from '../../svg/tick.svg';
import classNames from 'classnames';

interface ListItem extends Blok {
  label: string;
}

interface ContentListProps extends Blok {
  title: string;
  list_items: ListItem[];
}

const ContentList: React.FC<ContentListProps> = ({
  title,
  list_items,
  theme: themeInput,
  ...props
}) => {
  const theme = themeInput || 'LIGHT';

  return (
    <React.Fragment>
      <section
        className={classNames(
          'relative overflow-hidden w-full bg-cover bg-no-repeat pt-20 pb-16 ',
          {
            'bg-texture-primary bg-texture/primary text-body/body':
              theme === 'LIGHT',
            'bg-texture-tertiary bg-texture/tertiary text-white':
              theme === 'DARK',
          }
        )}
      >
        <div className={classNames('container mx-auto')}>
          <h2 className="text-4xl font-oskari-g2--bold text-center pb-11">
            {title}
          </h2>
          <div className="flex justify-center">
            <ul className="grid grid-cols-1 sm:grid-cols-2 gap-x-6 lg:gap-x-12">
              {list_items.map((item, idx) => (
                <li
                  className={`flex items-center justify-start pb-4 ${
                    idx % 2 == 0 ? 'sm:col-start-1' : 'sm:col-start-2'
                  }`}
                  key={item._uid}
                >
                  <div className="h-[33px] w-[32px]">
                    <TickSVG />
                  </div>
                  <p className="text-left pl-3 text-lg lg:text-xl">
                    {item.label}
                  </p>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

ContentList.defaultProps = {
  title: '',
  list_items: [],
};

ContentList.propTypes = {
  title: PropTypes.any,
};

export default ContentList;
