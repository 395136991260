import PropTypes from 'prop-types';
import React from 'react';
import SmallImageHero from '../../components/SmallImageHero/SmallImageHero.component';
import { StoryblokAsset } from '../../interfaces/Asset.interface';
import { Blok } from '../../interfaces/Blok.interface';
import { withBlokContainer } from '../../utils/withBlokContainer';

interface SmallImageHeroBlokProps extends Blok {
  image: StoryblokAsset;
  heading: any;
  subheading: any;
}

const SmallImageHeroBlok: React.FC<SmallImageHeroBlokProps> = ({
  image,
  heading,
  subheading,
  anchor,
}) => {
  return withBlokContainer(
    <SmallImageHero image={image} heading={heading} subheading={subheading} />,
    anchor
  );
};

SmallImageHeroBlok.defaultProps = {
  image: null,
  heading: '',
  subheading: '',
};

SmallImageHeroBlok.propTypes = {
  image: PropTypes.any,
  heading: PropTypes.any,
  subheading: PropTypes.any,
};

export default SmallImageHeroBlok;
