import React from 'react';

interface TimetableSectionProps {
  title: string;
}

export const TimetableSection: React.FC<TimetableSectionProps> = ({
  title,
  children,
}) => {
  return (
    <section>
      <div className="flex items-center py-4">
        <p className="pt-xs text-sm xl:text-base md:text-sm lg:text-base font-oskari-g2--regular antialiased w-24">
          {title}
        </p>
        <div className="w-full border-t border-white"></div>
      </div>
      <div className="grid grid-cols-8 gap-x-2 gap-y-2 lg:gap-x-4 lg:gap-y-4 text-3xl font-oskari-g2--bold antialiased text-black text-center">
        <div></div>
        {children}
      </div>
    </section>
  );
};
