import PropTypes from 'prop-types';
import React from 'react';

interface VideoProps {
  video_link: string;
}

const Video: React.FC<VideoProps> = ({ video_link }) => {
  return (
    <React.Fragment>
      <section className="bg-black overflow-hidden">
        <div className="club-video denton-video flex relative items-center justify-center h-650px w-full m-auto bg-right overflow-hidden">
          <div className="videoContainer">
            <video
              autoPlay
              loop
              muted
              playsInline
              data-setup='{"logo": { "enabled": false }, "techOrder": ["azureHtml5JS", "flashSS", "html5FairPlayHLS", "silverlightSS", "html5"], "nativeControlsForTouch": false, "controls": false, "muted": true, "autoplay": true}'
            >
              <source src={video_link} />
              <p>
                To view this video please enable JavaScript, and consider
                upgrading to a web browser that supports HTML5 video
              </p>
            </video>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

Video.defaultProps = {
  video_link: '',
};

Video.propTypes = {
  video_link: PropTypes.string,
};

export default Video;
