import React from 'react';

interface TwoColCTAProps {}

const TwoColCTA: React.FC<TwoColCTAProps> = ({ children }) => {
  return (
    <section className="relative bg-texture-secondary bg-repeat bg-200 bg-texture/secondary text-white text-center font-oskari-g2--bold antialiased grid grid-cols-1 sm:grid-cols-2 sm:h-64 pt-6 sm:pt-0">
      {children}
    </section>
  );
};

TwoColCTA.defaultProps = {};

TwoColCTA.propTypes = {};

export default TwoColCTA;
