import PropTypes from 'prop-types';
import React from 'react';
import ContentList from '../../components/ContentList/ContentList.component';
import { Blok } from '../../interfaces/Blok.interface';
import { StoryblokAsset } from '../../interfaces/Asset.interface';
import { StoryblokLink } from '../../interfaces/StoryblokLink.interface';
import { withBlokContainer } from '../../utils/withBlokContainer';

interface ListItem extends Blok {
  label: string;
}

interface ContentListBlokProps extends Blok {
  title: string;
  list_items: ListItem[];
}

const ContentListBlok: React.FC<ContentListBlokProps> = ({
  title,
  list_items,
  theme: themeInput,
  anchor,
  ...props
}) => {
  const theme = themeInput || 'LIGHT';
  return withBlokContainer(
    <ContentList
      title={title}
      list_items={list_items}
      theme={theme}
      anchor={anchor}
      {...props}
    />,
    anchor
  );
};

ContentListBlok.defaultProps = {
  title: '',
};

ContentListBlok.propTypes = {
  title: PropTypes.string,
};

export default ContentListBlok;
