import React from 'react';
import MembershipOption from '../memberships.interface';
import MembershipComparisonCard from './MembershipComparisonCard';
import classNames from 'classnames';

const MembershipPrimaryComparisonCard: React.FC<MembershipOption> = ({
  membership,
  featuredMembership,
  className,
  isConcession,
  sharedFeatures,
}) => {
  return (
    <div
      className={classNames(
        `text-center h-auto flex flex-col col-span-4 col-start-5 xl:col-span-3 xl:col-start-7 order-0`,
        className
      )}
    >
      <MembershipComparisonCard
        membership={membership}
        featuredMembership={featuredMembership}
        isConcession={isConcession}
        sharedFeatures={sharedFeatures}
      />
    </div>
  );
};

export default MembershipPrimaryComparisonCard;
