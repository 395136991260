import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import CustomLink from '../../components/CustomLink/CustomLink.component';
import { BlockTheme } from '../../interfaces/Blok.interface';
import { StoryblokLink } from '../../interfaces/StoryblokLink.interface';
import { StoryblokLinkPropTypes } from '../../utils/blokPropTypes';
import { getLinkUrl } from '../../utils/getLinkUrl';

interface ButtonComponentProps {
  cta_label: string;
  cta_link: StoryblokLink;
  className?: string;
  background?: 'BLACK' | 'WHITE';
}

const Button: React.FC<ButtonComponentProps> = ({
  cta_label,
  cta_link,
  className,
  background = 'WHITE',
}) => {
  const [isHover, setIsHover] = useState(false);

  const updateIsHover = (value: boolean): void => {
    setIsHover(value);
  };

  return (
    <CustomLink url={getLinkUrl(cta_link)} className="w-full sm:w-auto">
      <div
        className={classnames(
          'w-full sm:w-auto transition-all hover:bg-primary cursor-pointer pt-4 px-8 pb-3 uppercase text-xl font-oskari-g2--semibold antialiased text-center mt-4',
          {
            'bg-white  text-black': background === 'WHITE',
            'bg-black  text-white': background === 'BLACK',
          },
          className
        )}
        onMouseEnter={() => updateIsHover(true)}
        onMouseLeave={() => updateIsHover(false)}
      >
        {cta_label && <p className="">{cta_label.toUpperCase()}</p>}
      </div>
    </CustomLink>
  );
};

Button.defaultProps = {
  cta_label: '',
  cta_link: {
    anchor: '',
    cached_url: '',
    fieldtype: '',
    id: '',
    linktype: null,
    url: null,
  },
};

Button.propTypes = {
  cta_label: PropTypes.string,
  cta_link: PropTypes.exact(StoryblokLinkPropTypes),
};

export default Button;
