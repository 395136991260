import { useEffect, useState, useRef } from 'react';
import { debounce } from 'lodash';

function useSticky() {
  const [isSticky, setSticky] = useState(false);
  const element = useRef(null);

  const handleScroll = debounce(() => {
    if (!element.current) return;
    element.current.getBoundingClientRect() != null &&
    window.scrollY < element.current.getBoundingClientRect().bottom
      ? setSticky(false)
      : setSticky(true);
  }, 100);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', () => handleScroll);
    };
  }, [handleScroll]);

  return { isSticky, element };
}

export default useSticky;
