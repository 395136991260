import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import RichText from '../RichText/RichText.component';

// Icons
import StarIcon from '../../svg/star.svg';
import CrossIcon from '../../svg/cross.svg';
import { BannerBackground } from '../../bloks/Banner/Banner.blok';
import classnames from 'classnames';

interface BannerProps {
  heading: string;
  subheading: string;
  body: any;
  background: BannerBackground;
}

const Banner: React.FC<BannerProps> = ({
  heading,
  subheading,
  body,
  background,
}) => {
  const [showBanner, setShowBanner] = useState(true);

  const localStorageKey = `SHOW_BANNER ${heading} ${subheading}`;

  useEffect(() => {
    const data = window.localStorage.getItem(localStorageKey);

    if (data !== null) setShowBanner(JSON.parse(data));
  }, []);

  useEffect(() => {
    window.localStorage.setItem(localStorageKey, JSON.stringify(showBanner));
  }, [showBanner]);

  const handleClose = () => {
    setShowBanner(false);
  };

  if (!showBanner) return null;

  const BannerSeperator: React.FC = () => {
    return (
      <div
        className={classnames('w-px h-6 hidden lg:block opacity-40', {
          'bg-white': background === 'PRIMARY',
          'bg-black': background === 'WHITE',
        })}
      />
    );
  };

  return (
    <div
      className={classnames('', {
        'bg-primary text-white': background === 'PRIMARY',
        'bg-white text-body/body': background === 'WHITE',
      })}
    >
      <div className="container mx-auto py-2 flex justify-between items-center">
        <div className="flex items-center gap-3">
          <div className="">
            <StarIcon
              className={classnames('', {
                'fill-white': background === 'PRIMARY',
                'fill-primary': background === 'WHITE',
              })}
            />
          </div>

          <div className="flex flex-col lg:flex-row  items-start lg:items-center lg:gap-3 ">
            <div className="font-bold hidden lg:block ">{heading}</div>
            <BannerSeperator />
            <div className="font-bold">{subheading}</div>
            <BannerSeperator />
            {body && (
              <RichText classNames="font-oskari-g2--light" document={body} />
            )}
          </div>
        </div>
        <button onClick={handleClose}>
          <CrossIcon
            className={classnames('', {
              'fill-white': background === 'PRIMARY',
              'fill-body/body': background === 'WHITE',
            })}
          />
        </button>
      </div>
    </div>
  );
};

Banner.defaultProps = {
  heading: '',
};

Banner.propTypes = {
  heading: PropTypes.string,
};

export default Banner;
