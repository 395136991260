import React from 'react';
import MembershipOption from '../memberships.interface';
import MembershipComparisonCard from './MembershipComparisonCard';

const MembershipSecondaryComparisonCard: React.FC<MembershipOption> = ({
  membership,
  isConcession,
  sharedFeatures,
}) => {
  return (
    <div
      className={`text-center h-auto flex flex-col col-span-4 xl:col-span-3 order-2`}
    >
      <MembershipComparisonCard
        membership={membership}
        isConcession={isConcession}
        sharedFeatures={sharedFeatures}
      />
    </div>
  );
};

export default MembershipSecondaryComparisonCard;
