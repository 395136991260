import React from 'react';
import MembershipOption from '../memberships.interface';
import MembershipHorizontalCard from './MembershipHorizontalCard';
import classNames from 'classnames';

const MembershipPrimaryHorizontalCard: React.FC<MembershipOption> = ({
  membership,
  featuredMembership,
  className,
  isConcession,
}) => {
  return (
    <div
      className={classNames(
        `text-center border border-black h-auto md:h-full xl:h-auto flex flex-col col-span-12 lg:col-span-10 xl:col-span-4 col-start-1 lg:col-start-2 xl:col-start-5 order-1 xl:order-none`,
        className
      )}
    >
      <MembershipHorizontalCard
        membership={membership}
        key={membership._uid}
        featuredMembership={featuredMembership}
        isConcession={isConcession}
      />
    </div>
  );
};

export default MembershipPrimaryHorizontalCard;
