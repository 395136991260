import haversine from 'haversine-distance';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Gym } from '../../interfaces/GymData';
import FilteredGymList from '../FilteredGymList/FilteredGymList.component';
import GymList from '../GymList/GymList.component';
import GymMap from '../GymMap/GymMap.component';
import PostcodeLookup from '../PostcodeLookup/PostcodeLookup.component';
import TagManager from 'react-gtm-module';

interface FindAGymProps {
  listTitle: string;
  gyms: Gym[];
}

export interface GeoCoords {
  lat: number;
  lng: number;
}

const CalculateDistance = (userCoords, gymCoords) =>
  haversine(userCoords, gymCoords);

const AddDistanceToGymFromCoords = (
  gym: Gym,
  { lat, lng }: { lat: number; lng: number }
): Gym => ({
  ...gym,

  distance: CalculateDistance(
    [lat, lng],
    [gym.coordinates.lat, gym.coordinates.lng]
  ),
});

const Within20MilesOnly = (gym: Gym): boolean => {
  const TWENTY_MILES_IN_METERES = 32186.9;
  return gym.distance < TWENTY_MILES_IN_METERES;
};

const SortByDistance = (a, b) =>
  a.distance > b.distance ? 1 : b.distance > a.distance ? -1 : 0;

const FindAGym: React.FC<FindAGymProps> = ({ listTitle, gyms }) => {
  const [isGoogleMapsLoaded, setIsGoogleMapsLoaded] = useState(false);
  const [userGeolocation, setUserGeolocation] = useState<GeoCoords>(null);
  const [filteredGyms, setFilteredGyms] = useState<Gym[]>(gyms);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (window?.google?.maps) {
      setIsGoogleMapsLoaded(true);
      return;
    }

    (window as any).initMap = () => {
      setIsGoogleMapsLoaded(true);
    };
  }, []);

  const geocodeAddress = (query: string) => {
    setIsLoading(true);
    const geocoder = new window.google.maps.Geocoder();

    geocoder.geocode({ address: query }, function (results, status) {
      setIsLoading(false);
      switch (status) {
        case 'OK':
          setUserGeolocation({
            lat: results[0].geometry.location.lat(),
            lng: results[0].geometry.location.lng(),
          });
          break;
        case 'ZERO_RESULTS':
          setFilteredGyms([]);
          break;
        default:
          console.log(
            'Geocode was not successful for the following reason: ' + status
          );
      }

      TagManager.initialize({
        gtmId: process.env.GATSBY_GTM_CONTAINER_ID,
        dataLayer: {
          event: 'gym_search_result_views',
          search_lat: results[0].geometry.location.lat(),
          search_lng: results[0].geometry.location.lng(),
          search_results: filteredGyms.length,
        },
      });
    });
  };

  useEffect(() => {
    if (userGeolocation) {
      const gymsWithin20Miles = gyms
        .map((gym) => AddDistanceToGymFromCoords(gym, userGeolocation))
        .filter(Within20MilesOnly)
        .sort(SortByDistance);

      setFilteredGyms(gymsWithin20Miles);
    }
  }, [userGeolocation]);

  const GymResults = () => {
    if (isLoading) {
      return (
        <div className="container text-white m-auto py-36">
          <div className=" flex justify-center items-center">
            <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-gray-900"></div>
          </div>
        </div>
      );
    }

    if (!filteredGyms.length) {
      return (
        <p className='"pb-2 font-oskari-g2--light text-lg'>
          No gyms found in your local area
        </p>
      );
    }

    return filteredGyms.length === gyms.length ? (
      <GymList listTitle={listTitle} gyms={filteredGyms} />
    ) : (
      <FilteredGymList gyms={filteredGyms} />
    );
  };

  return (
    <div className="md:flex md:flex-row text-white bg-texture-tertiary bg-texture/tertiary">
      {isGoogleMapsLoaded && (
        <div className="basis-[420px] py-4 md:pt-10 md:pb-8 px-8">
          <h2 className="text-3xl font-oskari-g2--bold uppercase text-white">
            {listTitle}
          </h2>
          <PostcodeLookup
            onSubmit={geocodeAddress}
            onReset={() => setFilteredGyms(gyms)}
            showResetButton={filteredGyms.length !== gyms.length}
          />
          <GymResults />
        </div>
      )}
      <div className="hidden md:block bg-white grow">
        <GymMap
          googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&client=${process.env.GATSBY_GOOGLE_MAPS_API_KEY}&libraries=geometry,drawing,places&callback=initMap`}
          loadingElement={<div style={{ height: `100%` }} />}
          containerElement={
            <div style={{ minHeight: `672px`, height: '100%' }} />
          }
          mapElement={<div style={{ height: `100%` }} />}
          gyms={filteredGyms}
          userLocation={userGeolocation}
        ></GymMap>
      </div>
    </div>
  );
};

FindAGym.defaultProps = {
  listTitle: '',
  gyms: [],
};

FindAGym.propTypes = {
  listTitle: PropTypes.string,
  gyms: PropTypes.arrayOf(
    PropTypes.exact({
      uid: PropTypes.string.isRequired,
      site_id: PropTypes.string,
      migrated_to_perfect_gym: PropTypes.bool,
      name: PropTypes.string,
      address: PropTypes.string,
      contact_number: PropTypes.string,
      coordinates: PropTypes.exact({
        lat: PropTypes.number,
        lng: PropTypes.number,
      }),
      email: PropTypes.string,
      join_journey_url: PropTypes.string,
      login_url: PropTypes.string,
      contact_url: PropTypes.string,
      page_url: PropTypes.string,
    })
  ).isRequired,
};

export default FindAGym;
