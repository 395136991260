import classNames from 'classnames';
import React, { useContext, useState } from 'react';
import ReactModal from 'react-modal';
import { MembershipContext } from '../../../contexts/MembershipContext';
import StarBodySVG from '../../../svg/star-body.svg';
import StarGreenSVG from '../../../svg/star-green.svg';
import StarWhiteSVG from '../../../svg/star-white.svg';
import TickWhiteSVG from '../../../svg/tick-white.svg';
import TickGreenSVG from '../../../svg/tick.svg';
import Button from '../../Button/Button.component';
import MembershipEventWrapper from '../MembershipEventWrapper.component';
import MoreInfoModal from '../MoreInfoModal.component';
import UpSellModal from '../UpSellModal.component';
import MembershipOption, {
  IconColour,
  PromotionType,
} from '../memberships.interface';
import { PageContext } from '../../../contexts/PageContext';
import { generateJoinUrl } from '../../../utils/joinUrl';

export enum ModalOptions {
  MEMBERSHIP_UP_SELL = 'MEMBERSHIP_UP_SELL',
  MORE_INFO = 'MORE_INFO',
}

const MembershipVerticalCard: React.FC<MembershipOption> = ({
  membership,
  featuredMembership,
}) => {
  const membershipContext = useContext(MembershipContext);
  const { gym } = useContext(PageContext);
  const [moreInfoModal, setMoreInfoModal] = useState<boolean>(false);
  const [upSellModal, setUpSellModal] = useState<boolean>(false);
  const toggleModal = (state: boolean): void => {
    setMoreInfoModal(state);
    setUpSellModal(state);
  };

  const {
    featured_card_bg_image,
    primary_cta_label,
    join_url,
    join_gym_url,
    secondary_cta_label,
    joining_fee_label,
    icon_colour,
  } = membershipContext.membershipDetails;

  const {
    membership_name,
    membership_term,
    price_per_month,
    discounted_price_per_month,
    features,
    promotion,
    joiningFeePromotion,
    fsi_product_code,
    joining_fee_price,
    membership_up_sell,
    promotion_banner_label,
    show_cta,
  } = membership;

  const membershipContainerHeight: string =
    !!membershipContext.memberships.find(
      (membership) =>
        !membership.featured_membership &&
        membership.promotion?.type === PromotionType.PROMOTION &&
        promotion?.promotion_copy
    )
      ? 'h-auto lg:h-[376px] xl:h-auto'
      : 'h-auto';

  const joinUrl = generateJoinUrl({
    site_id: gym.site_id,
    pg_club_id: gym.pg_club_id,
    migrated: gym.migrated_to_perfect_gym,
    fsi_product_code,
    join_url,
    join_gym_url,
  });

  const upsellUrl = generateJoinUrl({
    site_id: gym.site_id,
    pg_club_id: gym.pg_club_id,
    migrated: gym.migrated_to_perfect_gym,
    fsi_product_code: membership_up_sell?.fsi_product_code,
    join_url,
    join_gym_url,
  });

  return (
    <>
      <div
        className={classNames(
          `relative w-auto bg-body/body py-16 px-6 text-left`,
          {
            'lg:w-[450px] xl:w-[640px] 2xl:w-[860px] lg:p-[60px]':
              featuredMembership,
            'xl:w-[470px] xl:p-[60px]': !featuredMembership,
          },
          !featuredMembership && membershipContainerHeight
        )}
      >
        <div
          className={classNames(`h-full w-full items-center justify-start`, {
            'lg:flex': featuredMembership,
            'flex justify-center xl:justify-start flex-col xl:flex-row':
              !featuredMembership,
          })}
        >
          {promotion?.promotion_copy && promotion_banner_label && (
            <div
              className={classNames(
                `relative mb-14 py-2 px-5 z-[1] flex justify-start max-w-fit mx-auto`,
                {
                  'lg:absolute lg:top-[60px] lg:left-0 lg:mr-auto':
                    featuredMembership,
                  'xl:absolute xl:top-[60px] xl:left-0 xl:mr-auto':
                    !featuredMembership,
                  'bg-primary': icon_colour === IconColour.TEAL,
                  'bg-white': icon_colour === IconColour.WHITE,
                }
              )}
            >
              <div
                className={` flex items-center justify-center space-x-2 rounded-full text-body/body`}
              >
                <StarBodySVG />
                <span className="font-oskari-g2--bold uppercase leading-[20px] mt-[0.35em] text-lg text-center">
                  {promotion_banner_label}
                </span>
                <StarBodySVG />
              </div>
            </div>
          )}
          <div
            className={classNames(`relative z-[1] space-y-12 text-center`, {
              'lg:text-left': featuredMembership,
              'xl:text-left': !featuredMembership,
              'xl:pt-5': joiningFeePromotion,
            })}
          >
            <div>
              {membership_name && (
                <p
                  className={classNames(
                    `text-white text-[48px] font-oskari-g2--bold uppercase leading-[40px] pb-3`,
                    {
                      'lg:text-[56px]': featuredMembership,
                      'xl:text-[56px] ': !featuredMembership,
                    }
                  )}
                >
                  {membership_name}
                </p>
              )}
              {membership_term && (
                <p className="text-white text-xl leading-[28px]">
                  {membership_term}
                </p>
              )}
            </div>
            <div
              className={classNames({
                'space-y-3 !mt-8 lg:!mt-4':
                  joiningFeePromotion && promotion?.promotion_copy,
              })}
            >
              {promotion?.promotion_copy && (
                <div
                  className={classNames(`flex justify-center`, {
                    'lg:justify-start': featuredMembership,
                    'xl:justify-start': !featuredMembership,
                  })}
                >
                  <div
                    className={` flex items-center justify-center space-x-2 rounded-full text-white`}
                  >
                    {icon_colour === IconColour.TEAL && <StarGreenSVG />}
                    {icon_colour === IconColour.WHITE && <StarWhiteSVG />}
                    <span className="font-oskari-g2--bold uppercase leading-[0px] mt-[0.35em] text-[24px]">
                      {promotion.promotion_copy}
                    </span>
                  </div>
                </div>
              )}
              {joiningFeePromotion && (
                <div
                  className={classNames(`flex justify-center`, {
                    'lg:justify-start': featuredMembership,
                    'xl:justify-start': !featuredMembership,
                  })}
                >
                  <div
                    className={` flex items-center justify-center space-x-2 rounded-full text-white`}
                  >
                    {icon_colour === IconColour.TEAL && <StarGreenSVG />}
                    {icon_colour === IconColour.WHITE && <StarWhiteSVG />}
                    <span className="font-oskari-g2--bold uppercase leading-[0px] mt-[0.35em] text-[24px]">
                      No {joining_fee_label}
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        {featured_card_bg_image && (
          <div
            className={classNames(
              `w-full h-full transform duration-2000 bg-cover bg-no-repeat absolute top-0 left-0 m-auto bg-center bg-texture/secondary -scale-x-100`,
              {
                grayscale: !featuredMembership,
              }
            )}
            style={{
              backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.70) 0%, rgba(0, 0, 0, 0.70) 100%), url(${featured_card_bg_image.filename})`,
            }}
          ></div>
        )}
      </div>

      <div
        className={classNames(
          'bg-texture-secondary bg-texture/secondary grow py-12 px-6 lg:p-20',
          {
            'grow flex flex-col justify-between': !featuredMembership,
          }
        )}
      >
        <div
          className={classNames(`gap-x-[60px] pb-12 border-white`, {
            'lg:flex lg:pb-14 lg:border-b': featuredMembership,
            'xl:flex xl:pb-14 xl:border-b': !featuredMembership,
          })}
        >
          <div
            className={classNames(
              `text-white flex flex-col justify-center items-center pb-12`,
              {
                'lg:items-start lg:pb-0 text-center lg:text-left':
                  featuredMembership,
                'xl:items-start xl:pb-0 text-center xl:text-left':
                  !featuredMembership,
              }
            )}
          >
            <div className="space-y-4">
              {discounted_price_per_month && (
                <p className="line-through text-white text-[30px] leading-[36px]">
                  £{price_per_month} p/m
                </p>
              )}
              {price_per_month && (
                <p className="text-[56px] font-oskari-g2--black leading-[56px]">
                  £
                  {discounted_price_per_month
                    ? discounted_price_per_month
                    : price_per_month}
                </p>
              )}
              {!joiningFeePromotion && (
                <p className={`text-xl leading-[30px]`}>
                  <span className="text-body/400">
                    {joining_fee_label || 'Joining Fee'}
                    {': '}
                  </span>

                  <span className="font-oskari-g2--bold">{`£${joining_fee_price}`}</span>
                </p>
              )}
            </div>
          </div>

          <div className="text-white  grow flex justify-center">
            <div
              className={`space-y-5 ${
                featuredMembership ? 'min-h-[10rem]' : ''
              }`}
            >
              {!!features?.length &&
                features.map(({ feature_name, custom_label, _uid }) => (
                  <div className="flex items-center justify-start" key={_uid}>
                    <div className="h-[32px] w-[32px]">
                      {icon_colour === IconColour.TEAL && <TickGreenSVG />}
                      {icon_colour === IconColour.WHITE && <TickWhiteSVG />}
                    </div>
                    <p className="text-left pl-3 text-lg">
                      {custom_label || feature_name}
                    </p>
                  </div>
                ))}
            </div>
          </div>
        </div>
        <div
          className={classNames(
            `space-y-6 grid grid-cols-1 items-center justify-center`,
            {
              'lg:space-y-0 lg:space-x-5 lg:pt-14 lg:grid-cols-2':
                featuredMembership,
              'xl:space-y-0 xl:space-x-5 xl:pt-14 xl:grid-cols-2':
                !featuredMembership,
            }
          )}
        >
          {membership_up_sell ? (
            <button
              aria-label="Open modal"
              className={classNames(
                'w-full sm:w-auto transition-all bg-white border border-white mt-0 hover:bg-primary hover:border-primary cursor-pointer pt-4 px-8 pb-3 uppercase text-xl font-oskari-g2--semibold antialiased text-center'
              )}
              onClick={() => {
                setUpSellModal(!upSellModal);
              }}
            >
              {primary_cta_label}
            </button>
          ) : (
            <MembershipEventWrapper
              membership={membership}
              event={'add_to_cart'}
            >
              <Button
                cta_label={primary_cta_label}
                cta_link={{
                  anchor: '',
                  cached_url: joinUrl,
                  fieldtype: 'link',
                  _uid: '',
                  id: '',
                  linktype: '',
                  url: joinUrl,
                }}
                className={`bg-white border border-white mt-0 hover:bg-primary hover:border-primary`}
              />
            </MembershipEventWrapper>
          )}
          {show_cta && (
            <MembershipEventWrapper membership={membership} event={'view_item'}>
              <button
                aria-label="Open modal"
                className={classNames(
                  'w-full transition-all border border-white !text-white bg-[transparent] hover:bg-white hover:!text-body/body mt-0 px-4 cursor-pointer pt-4 pb-3 uppercase text-xl font-oskari-g2--semibold antialiased text-center'
                )}
                onClick={() => {
                  setMoreInfoModal(!moreInfoModal);
                }}
              >
                {secondary_cta_label}
              </button>
            </MembershipEventWrapper>
          )}
        </div>
      </div>

      <ReactModal
        isOpen={upSellModal || moreInfoModal}
        className="relative h-full w-full flex justify-center items-center container mx-auto"
      >
        {upSellModal && (
          <UpSellModal
            membershipUpSell={membership_up_sell}
            membership={membership}
            joinUrl={joinUrl}
            upsellUrl={upsellUrl}
            toggleModal={toggleModal}
          />
        )}
        {moreInfoModal && (
          <MoreInfoModal
            membership={membership}
            joinUrl={joinUrl}
            toggleModal={toggleModal}
          />
        )}
      </ReactModal>
    </>
  );
};

export default MembershipVerticalCard;
