import PropTypes from 'prop-types';
import React from 'react';
import FiftyFiftyImageTextNoBottomPadding from '../../components/FiftyFiftyImageTextNoBottomPadding/FiftyFiftyImageTextNoBottomPadding.component';
import { Blok } from '../../interfaces/Blok.interface';
import { StoryblokAsset } from '../../interfaces/Asset.interface';
import { StoryblokLink } from '../../interfaces/StoryblokLink.interface';
import { withBlokContainer } from '../../utils/withBlokContainer';

interface CTA {
  label: string;
  link: StoryblokLink;
}

interface FiftyFiftyImageTextNoBottomPaddingBlokProps extends Blok {
  heading: string;
  subheading: string;
  body: any;
  cta: CTA;
  image: StoryblokAsset;
  show_app_icons: boolean;
  apple_store_link: StoryblokLink;
  google_play_link: StoryblokLink;
}

const FiftyFiftyImageTextNoBottomPaddingBlok: React.FC<FiftyFiftyImageTextNoBottomPaddingBlokProps> =
  ({
    heading,
    subheading,
    body,
    cta,
    image,
    show_app_icons,
    anchor,
    theme: themeInput,
    google_play_link,
    apple_store_link,
    ...props
  }) => {
    const ctaData = cta && cta[0];
    const ctaContent = ctaData && {
      label: ctaData.label,
      link: ctaData.Gym ? ctaData.Gym.content[ctaData.url] : ctaData.link,
      _uid: ctaData._uid,
      anchor: ctaData.anchor,
      component: ctaData.component,
      _editable: ctaData._editable,
    };

    // When the user clicks into the blok to edit - The theme, if not set will be an empty string
    const theme = themeInput || 'DARK';

    return withBlokContainer(
      <FiftyFiftyImageTextNoBottomPadding
        heading={heading}
        subheading={subheading}
        body={body}
        cta={ctaContent}
        image={image}
        show_app_icons={show_app_icons}
        apple_store_link={apple_store_link}
        google_play_link={google_play_link}
        theme={theme}
        {...props}
      />,
      anchor
    );
  };

FiftyFiftyImageTextNoBottomPaddingBlok.defaultProps = {
  heading: '',
  subheading: '',
  body: null,
  cta: null,
  image: null,
  show_app_icons: false,
};

FiftyFiftyImageTextNoBottomPaddingBlok.propTypes = {
  heading: PropTypes.string,
  subheading: PropTypes.string,
  body: PropTypes.any,
  cta: PropTypes.any,
  image: PropTypes.any,
  show_app_icons: PropTypes.bool,
};

export default FiftyFiftyImageTextNoBottomPaddingBlok;
