import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Gym } from '../../interfaces/GymData';
import { SortAlphabeticallyByName } from '../../utils/sortAlphabeticallyByName';
import RichText from '../RichText/RichText.component';
import Button from '../Button/Button.component';
import { StoryblokLink } from '../../interfaces/StoryblokLink.interface';
import { StoryblokAsset } from '../../interfaces/Asset.interface';

export interface ContactCTAEmail {
  address: string;
  subject: string;
  body: string;
}

export interface ContactCTAActionProps {
  _uid: string;
  type: string;
  email: ContactCTAEmail;
  plugin: string;
}

interface ImageHeroWithGymSelectProps {
  image: StoryblokAsset;
  heading: any;
  subheading: any;
  select_gym_label: string;
  gyms: Gym[];
  contact_cta_label: string;
  contact_cta_action: ContactCTAActionProps;
}

const ImageHeroWithGymSelect: React.FC<ImageHeroWithGymSelectProps> = ({
  image,
  heading,
  subheading,
  select_gym_label,
  gyms,
  contact_cta_label,
  contact_cta_action,
}) => {
  const [gymsList] = useState<Gym[]>(gyms);
  const [selectedGym, setSelectedGym] = useState<Gym>(null);

  const onGymSelection = (event) =>
    setSelectedGym(gymsList.find((gym) => gym.site_id === event.target.value));

  const getConfiguredMailTo = (
    gym: Gym,
    contactCTAEmail: ContactCTAEmail
  ): string => {
    const emailToSendTo: string = gym.email;
    const isCustomEmailAddress: boolean =
      contact_cta_action.type === 'custom-email';
    const address: string = `${
      isCustomEmailAddress ? contactCTAEmail.address : emailToSendTo
    }`;
    const subject: string = `subject=${
      isCustomEmailAddress ? selectedGym.name + ' | ' : ''
    }${contactCTAEmail.subject}`;
    const body: string = `body=${contactCTAEmail.body.split('\n').join('%0D')}`;
    return `mailTo:${address}?${subject}&${body}`;
  };
  return (
    <React.Fragment>
      <section
        className="bg-body/body relative overflow-hidden w-full h-[750px] bg-cover bg-no-repeat bg-83-0 sm:bg-top pt-44 sm:pt-52 px-4 sm:px-6"
        style={{
          backgroundImage: `linear-gradient(
              90deg,
              rgba(0, 0, 0, 0.9) 0%,
              rgba(0, 0, 0, 0) 100%
              ), url(${image.filename})`,
        }}
      >
        <div className="w-full text-center pb-12 container m-auto sm:pb-8 lg:pb-0">
          {heading && (
            <div className="py-8 sm:py-10 text-white">
              <RichText document={heading} />
            </div>
          )}
          {subheading && (
            <div className="py-4 sm:py-5 text-white">
              <RichText document={subheading} />
            </div>
          )}
          <div className="m-auto px-5 max-w-xl text-left pt-4">
            <label
              className="text-base font-oskari-g2--semibold text-white uppercase block mb-1"
              htmlFor="select-gym"
            >
              {select_gym_label}
            </label>
            <select
              id="select-gym"
              onChange={onGymSelection}
              defaultValue={0}
              className="form-select w-full mb-6 md:mb-10"
              aria-label={select_gym_label}
            >
              <option value={0} disabled>
                Please select a gym
              </option>
              {gymsList.sort(SortAlphabeticallyByName).map((gym) => (
                <option key={`contact-${gym.site_id}`} value={gym.site_id}>
                  {gym.name}
                </option>
              ))}
            </select>
            {selectedGym && (
              <div className="flex justify-center text-white text-center">
                <a
                  href={
                    contact_cta_action.type === 'link'
                      ? selectedGym.contact_url
                      : getConfiguredMailTo(
                          selectedGym,
                          contact_cta_action.email
                        )
                  }
                  target={
                    contact_cta_action.type === 'link' ? '_self' : '_blank'
                  }
                  className={`${
                    ((contact_cta_action.type === 'link' &&
                      !selectedGym.contact_url) ||
                      (contact_cta_action.type === 'email' &&
                        !selectedGym.email)) &&
                    'pointer-events-none text-body/400'
                  } pt-1 flex items-center justify-center transition-all hover:bg-primary cursor-pointer uppercase text-xl font-oskari-g2--semibold antialiased bg-white text-black h-14 w-full sm:w-44`}
                >
                  {contact_cta_label}
                </a>
              </div>
            )}
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

ImageHeroWithGymSelect.defaultProps = {
  image: null,
  heading: '',
  subheading: '',
  select_gym_label: '',
  gyms: [],
  contact_cta_label: '',
  contact_cta_action: {
    _uid: '',
    type: 'link',
    email: {
      address: 'Email address goes here',
      subject: 'Email subject goes here',
      body: 'Email body goes here',
    },
    plugin: '',
  },
};

ImageHeroWithGymSelect.propTypes = {
  image: PropTypes.any,
  heading: PropTypes.any,
  subheading: PropTypes.any,
  select_gym_label: PropTypes.string.isRequired,
  gyms: PropTypes.arrayOf(
    PropTypes.exact({
      uid: PropTypes.string.isRequired,
      name: PropTypes.string,
      address: PropTypes.string,
      contact_number: PropTypes.string,
      coordinates: PropTypes.exact({
        lat: PropTypes.number,
        lng: PropTypes.number,
      }),
      email: PropTypes.string,
      join_journey_url: PropTypes.string,
      login_url: PropTypes.string,
      contact_url: PropTypes.string,
      page_url: PropTypes.string,
      site_id: PropTypes.string,
      migrated_to_perfect_gym: PropTypes.bool,
    })
  ).isRequired,
  contact_cta_label: PropTypes.string.isRequired,
  contact_cta_action: PropTypes.exact({
    _uid: PropTypes.string,
    type: PropTypes.string,
    email: PropTypes.exact({
      address: PropTypes.string,
      subject: PropTypes.string,
      body: PropTypes.string,
    }),
    plugin: PropTypes.string,
  }),
};

export default ImageHeroWithGymSelect;
