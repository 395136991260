import PropTypes from 'prop-types';
import React from 'react';
import { StoryblokLink } from '../../interfaces/StoryblokLink.interface';
import { Blok } from '../../interfaces/Blok.interface';
import CustomLink from '../CustomLink/CustomLink.component';
import { getLinkUrl } from '../../utils/getLinkUrl';

interface LinkBlok extends Blok {
  label: string;
  link: StoryblokLink;
}

interface SecondaryNavProps {
  links: LinkBlok[];
}

const SecondaryNav: React.FC<SecondaryNavProps> = ({ links }) => {
  return (
    <section>
      <div className="secondary-nav bg-texture-primary bg-texture/primary bg-repeat bg-200 overflow-x-scroll">
        <div className="container mx-auto">
          <ul className="min-w-45 flex justify-start items-center lg:grid lg:grid-cols-4 lg:gap-x-8 uppercase text-xl xl:text-2xl font-oskari-g2--semibold py-5">
            {links.map((link, i) => (
              <li
                className={`my-0 w-full h-full border transition-all ${
                  i == 0
                    ? 'mr-3'
                    : i == links.length - 1
                    ? 'ml-3 whitespace-nowrap'
                    : 'mx-3'
                } ${
                  i == 0
                    ? 'border-body/body bg-body/body text-white'
                    : 'border-texture/primary hover:border-body/body'
                }`}
                key={link._uid}
              >
                <CustomLink
                  url={getLinkUrl(link.link)}
                  className={`font-oskari-g2--semibold antialiased flex justify-around items-center w-full h-full transition-all lg:mx-0 px-7 lg:px-10 pt-4 pb-2`}
                >
                  {link.label}
                </CustomLink>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  );
};

SecondaryNav.defaultProps = {
  links: null,
};

SecondaryNav.propTypes = {
  links: PropTypes.any,
};

export default SecondaryNav;
