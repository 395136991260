import { Slot } from '../../interfaces/Slot.interface';
import { SortAlphabeticallyByName } from '../../utils/sortAlphabeticallyByName';

interface Details {
  [key: string]: {
    [key: string]: string;
  };
}

export interface KeyItem {
  name: string;
  color: string;
}
export interface TimetableDataSourceResponse {
  details: Details;
  slots: Slot[];
  key?: KeyItem[];
}

export interface TimetableDataSource {
  getData(
    siteId: string,
    limited: boolean
  ): Promise<TimetableDataSourceResponse>;
}

interface TimetableDataSourceContext {
  setDataSource(dataSource: TimetableDataSource): void;
  render(): Promise<TimetableDataSourceResponse>;
}

class TimetableContext implements TimetableDataSourceContext {
  /**
   * Data source
   */
  private dataSource: TimetableDataSource;

  /**
   * Site Id
   */
  private siteId: string;

  /**
   * Limited
   */
  private limited: boolean;

  constructor(
    dataSource: TimetableDataSource,
    siteId: string,
    limited: boolean
  ) {
    this.dataSource = dataSource;
    this.siteId = siteId;
    this.limited = limited;
  }

  /**
   * Set Data Source
   * Dynamically set a new data source at run time
   * @param dataSource
   */
  public setDataSource(dataSource: TimetableDataSource) {
    this.dataSource = dataSource;
  }

  /**
   * Render
   *
   * Renders the timetable in the correct format
   * @returns Slot[]
   */
  public async render() {
    const { details, slots } = await this.dataSource.getData(
      this.siteId,
      this.limited
    );

    const slotLocations = slots
      .map((slot) => slot.location)
      .filter((value, index, self) => self.indexOf(value) === index);

    const slotsWithColour = slots.map((slot) => {
      const key = slotLocations.indexOf(slot.location);
      return { ...slot, color: this.getLocationColour(key) };
    });

    const key = slotLocations
      .map((location, _, self) => ({
        name: location,
        color: this.getLocationColour(self.indexOf(location)),
      }))
      .sort(SortAlphabeticallyByName);

    return {
      details: details,
      slots: slotsWithColour,
      key,
    };
  }

  /**
   * Get item colour
   * @param key
   * @returns
   */
  private getLocationColour(key: number): string {
    return {
      0: 'white',
      1: '#36BEB0',
      2: '#6D6D6D',
      3: '#959595',
      4: '#D9D9D9',
      5: '#000',
    }[key];
  }
}

export { TimetableContext };
