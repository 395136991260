import Axios, { AxiosResponse } from 'axios';
import { addMinutes, format } from 'date-fns';
import { Slot } from '../../../interfaces/Slot.interface';
import {
  TimetableDataSource,
  TimetableDataSourceResponse,
} from '../timetable.context';

interface GladstoneResponseItem {
  classId: number;
  clubId: number;
  cssClass: string;
  keyLocation: string;
  activityId: string;
  activityName: string;
  activityGroupId: string;
  description: string;
  imagePath: string;
  duration: number;
  location: string;
  dayOfWeek: number;
  startDate: string;
  startTime: string;
  minBookees: number;
  maxBookees: number;
  slotsBooked: number;
  waitingList: number;
  classPrice: number;
}

export default class GladstoneDataSource implements TimetableDataSource {
  private readonly API_URL = 'https://api.everlastgyms.com/v1/classes';

  private readonly DATE_FORMAT = 'MM-dd-yyyy';

  private readonly TIME_FORMAT = 'HH:mm';

  constructor() {}

  /**
   * Get Data
   * Dynamically retrieve the timetable data from a static json file located in
   * Gatsby's /static folder by site id. e.g. 0001.json
   * @param siteId
   * @returns
   */
  public async getData(siteId: string): Promise<TimetableDataSourceResponse> {
    const date = this.getDateOfFirstDayOfWeek();
    const formattedDate = format(date, this.DATE_FORMAT);
    const response = await this.fetch(siteId, formattedDate);
    const slots = this.mapDataToTimetableSlots(response.data);

    return {
      details: {},
      slots,
    };
  }

  /**
   * mapDataToTimetableSlots
   * Map data to our internal `Slot` model
   * @param data
   * @returns
   */
  private mapDataToTimetableSlots(data: GladstoneResponseItem[]): Slot[] {
    return data.map((item) => ({
      name:
        item.activityName.split('-')[1] ||
        item.activityName.split('-')[0] ||
        '',
      description: item.description,
      type: item.activityName,
      subtype: item.activityName,
      start: this.formatTime(item.startTime),
      end: this.calculateEndTime(
        this.formatTime(item.startTime),
        item.duration
      ),
      day: this.getDayOfWeek(item.dayOfWeek),
      week: 1,
      location: item.location,
    }));
  }

  private getDayOfWeek(day: number) {
    switch (day) {
      case 1:
        return 7;
      default:
        return day - 1;
    }
  }

  private formatTime(time: string): string {
    const segments = time.split(':');
    return `${segments[0]}:${segments[1]}`;
  }

  private calculateEndTime(startTime: string, duration: number): string {
    const [hours, minutes] = startTime
      .split(':')
      .map((segment) => parseInt(segment, 10));

    const today = new Date();
    today.setHours(hours, minutes);

    return format(addMinutes(today, duration), this.TIME_FORMAT);
  }

  /**
   * Fetch
   * Fetch data from the API
   * @param siteId
   */
  private async fetch(
    siteId: string,
    date: string
  ): Promise<AxiosResponse<GladstoneResponseItem[]>> {
    try {
      const response = await Axios.get(this.API_URL, {
        params: {
          siteId,
          date,
        },
        headers: {
          'Access-Control-Allow-Origin': '*',
        },
      });

      return response;
    } catch {
      throw new Error('Unable to fetch timetable data from remote service');
    }
  }

  private getDateOfFirstDayOfWeek(date: Date = new Date()) {
    const day = date.getDay();
    const diff = date.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday

    return new Date(date.setDate(diff));
  }
}
