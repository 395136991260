import classNames from 'classnames';
import React from 'react';
import MembershipOption from '../memberships.interface';
import MembershipVerticalCard from './MembershipVerticalCard';

const MembershipSecondaryVerticalCard: React.FC<MembershipOption> = ({
  membership,
  isConcession,
  className,
}) => {
  return (
    <div
      className={classNames(
        `col-span-12 lg:col-span-6 xl:col-span-12 xl:col-start-1 3xl:col-span-10 3xl:col-start-2 lg:flex lg:flex-col xl:flex-row h-full w-full`,
        className
      )}
    >
      <MembershipVerticalCard
        membership={membership}
        key={membership._uid}
        isConcession={isConcession}
      />
    </div>
  );
};

export default MembershipSecondaryVerticalCard;
