import PropTypes from 'prop-types';
import React from 'react';
import Facility from '../Facility/Facility.component';
import { StoryblokAsset } from '../../interfaces/Asset.interface';
import { BlockTheme } from '../../interfaces/Blok.interface';
import classnames from 'classnames';

interface Facility {
  id: string;
  icon: StoryblokAsset;
  label: string;
}

interface FacilitiesProps {
  heading: string;
  facilities: Facility[];
  theme: BlockTheme;
}

const Facilities: React.FC<FacilitiesProps> = ({
  facilities,
  heading,
  theme,
}) => {
  return (
    <section
      className={classnames('bg-repeat bg-200 relative', {
        'bg-texture-primary bg-texture/primary': theme === 'LIGHT',
        'bg-texture-secondary bg-texture/secondary': theme === 'DARK',
      })}
    >
      <div
        className={classnames('container mx-auto py-8 sm:py-16', {
          // We use "invert" here to alter both the SVG and the text color
          invert: theme === 'DARK',
        })}
      >
        <h3 className="text-5xl font-oskari-g2--bold text-center pt-2 pb-6 sm:pb-8">
          {heading}
        </h3>
        <ul className="uppercase text-xl font-oskari-g2--semibold grid grid-cols-2 md:grid-cols-4 gap-y-4 md:gap-y-8 ">
          {facilities.map((facility) => (
            <li key={facility.id}>
              <Facility icon={facility.icon} label={facility.label} />
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
};

Facilities.defaultProps = {
  heading: '',
  facilities: null,
};

Facilities.propTypes = {
  heading: PropTypes.string,
  facilities: PropTypes.any,
};

export default Facilities;
