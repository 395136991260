import { updateItemPositionInArray } from './updateItemPositionInArray';

export const orderMemberships = (memberships) => {
  if (!memberships?.length) return memberships;
  const membershipsByPrice = memberships?.sort(
    (a, b) => parseInt(a.price_per_month) - parseInt(b.price_per_month)
  );
  const featuredIdx = membershipsByPrice.findIndex(
    (membership) => membership.featured_membership
  );
  return updateItemPositionInArray(membershipsByPrice, featuredIdx, 1);
};
