import PropTypes from 'prop-types';
import React from 'react';
import RichText from '../RichText/RichText.component';

interface TitleProps {
  body: any;
}

const Title: React.FC<TitleProps> = ({ body }) => {
  return (
    <section className="bg-texture-primary bg-texture/primary bg-repeat bg-200">
      <div className="container m-auto py-16 px-4">
        {body && <RichText textSize="text-lg" document={body} />}
      </div>
    </section>
  );
};

Title.defaultProps = {
  body: null,
};

Title.propTypes = {
  body: PropTypes.any,
};

export default Title;
