import { graphql, useStaticQuery } from 'gatsby';
import React, { useContext } from 'react';
import SEO from '../SEO/SEO.component';
import MetaData from '../../interfaces/MetaData.interface';
import HeaderBlok from '../../bloks/Header/Header.blok';
import FooterBlok from '../../bloks/Footer/Footer.blok';
import BannerBlok from '../../bloks/Banner/Banner.blok';
import SbEditable from 'storyblok-react';
import { Helmet } from 'react-helmet';
import { PageContext } from '../../contexts/PageContext';
import { MembershipContext } from '../../contexts/MembershipContext';

interface LayoutProps {
  metaData?: MetaData;
}

const getBlok = (content: any) => JSON.parse(content?.edges[0]?.node.content);

const Layout: React.FC<LayoutProps> = ({ children, metaData }) => {
  const { isHeadingFlush, uuid } = useContext(PageContext);
  const { header, footer, banners } = useStaticQuery(graphql`
    {
      header: allStoryblokEntry(filter: { field_component: { eq: "Header" } }) {
        edges {
          node {
            name
            full_slug
            content
          }
        }
      }
      footer: allStoryblokEntry(filter: { field_component: { eq: "Footer" } }) {
        edges {
          node {
            name
            full_slug
            content
          }
        }
      }
      banners: allStoryblokEntry(
        filter: { field_component: { eq: "Banner" } }
      ) {
        edges {
          node {
            name
            full_slug
            content
          }
        }
      }
    }
  `);

  const headerBlok = getBlok(header);
  const footerBlok = getBlok(footer);

  const getBannerBlok = (content: any) => {
    const edges = content?.edges;
    if (!edges) return null;

    const allBannerBlocks = edges.map((edge: any) =>
      JSON.parse(edge.node.content)
    );

    // Filter out banners that don't have this page uuid attached
    const bannersForThisPage = allBannerBlocks.filter((banner: any) =>
      banner.pages.includes(uuid)
    );

    // Take the highest priority banner
    const bannerBlok = bannersForThisPage.sort(
      (a: any, b: any) => a.priority - b.priority
    )[0];

    return bannerBlok;
  };

  const bannerBlok = getBannerBlok(banners);

  return (
    <React.Fragment>
      {metaData && <SEO metaData={metaData} />}
      <Helmet>
        <body className="bg-texture-tertiary bg-texture/tertiary bg-repeat bg-200" />
      </Helmet>
      {bannerBlok && (
        <SbEditable key={bannerBlok._uid} content={bannerBlok}>
          <BannerBlok {...bannerBlok} />
        </SbEditable>
      )}
      <SbEditable key={headerBlok._uid} content={headerBlok}>
        <HeaderBlok {...headerBlok} />
      </SbEditable>
      <main style={{ paddingTop: isHeadingFlush ? 0 : 168 }}>{children}</main>
      <SbEditable key={footerBlok._uid} content={footerBlok}>
        <FooterBlok {...footerBlok} />
      </SbEditable>
    </React.Fragment>
  );
};

export default Layout;
