import { Gym } from '../interfaces/GymData';
import {
  Membership,
  MembershipDetails,
} from '../components/Memberships/memberships.interface';

interface GenerateJoinUrl {
  site_id: Gym['site_id'];
  pg_club_id: Gym['pg_club_id'];
  migrated: Gym['migrated_to_perfect_gym'];
  fsi_product_code: Membership['fsi_product_code'];
  join_url: MembershipDetails['join_url'];
  join_gym_url: MembershipDetails['join_gym_url'];
}

export const generateJoinUrl = ({
  site_id,
  pg_club_id,
  migrated,
  fsi_product_code,
  join_url,
  join_gym_url,
}: GenerateJoinUrl) => {
  // migrated to perfect gyms
  if (migrated) {
    return `${join_gym_url}?clubId=${pg_club_id}&paymentPlanId=${fsi_product_code}`;
  }
  return `${join_url}${site_id}-${fsi_product_code?.replace(/ /g, '_')}`;
};
