import PropTypes from 'prop-types';
import React from 'react';
import { Blok } from '../../interfaces/Blok.interface';
import { StoryblokAsset } from '../../interfaces/Asset.interface';
import Title from '../../components/Title/Title.component';
import { withBlokContainer } from '../../utils/withBlokContainer';

interface TitleBlokProps extends Blok {
  heading: string;
}

const TitleBlok: React.FC<TitleBlokProps> = ({ heading, anchor }) => {
  return withBlokContainer(<Title heading={heading} />, anchor);
};

TitleBlok.defaultProps = {
  heading: '',
};

TitleBlok.propTypes = {
  heading: PropTypes.string,
};

export default TitleBlok;
