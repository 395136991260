import PropTypes from 'prop-types';
import React from 'react';
import CTABlok from '../../components/CTABlok/CTABlok.component';
import { Blok } from '../../interfaces/Blok.interface';
import { StoryblokAsset } from '../../interfaces/Asset.interface';
import { StoryblokLink } from '../../interfaces/StoryblokLink.interface';
import { withBlokContainer } from '../../utils/withBlokContainer';

interface CTABlokBlokProps extends Blok {
  icon: StoryblokAsset;
  label: string;
  image: StoryblokAsset;
  link: StoryblokLink;
}

const CTABlokBlok: React.FC<CTABlokBlokProps> = ({
  icon,
  label,
  image,
  link,
  anchor,
}) => {
  return withBlokContainer(
    <CTABlok icon={icon} label={label} image={image} link={link} />,
    anchor
  );
};

CTABlokBlok.defaultProps = {
  icon: null,
  label: '',
  image: null,
  link: null,
  anchor: null,
};

CTABlokBlok.propTypes = {
  icon: PropTypes.any,
  label: PropTypes.string,
  image: PropTypes.any,
  link: PropTypes.any,
  anchor: PropTypes.any,
};

export default CTABlokBlok;
