import PropTypes from 'prop-types';
import React from 'react';
import TwoColCTA from '../../components/TwoColCTA/TwoColCTA.component';
import CTABlok from '../../components/CTABlok/CTABlok.component';
import { Blok } from '../../interfaces/Blok.interface';
import { StoryblokAsset } from '../../interfaces/Asset.interface';
import { StoryblokLink } from '../../interfaces/StoryblokLink.interface';
import SbEditable from 'storyblok-react';
import { withBlokContainer } from '../../utils/withBlokContainer';

interface CTABlokProps extends Blok {
  icon: StoryblokAsset;
  label: string;
  image: StoryblokAsset;
  link: StoryblokLink;
}

interface TwoColCTABlokProps extends Blok {
  ctas: CTABlokProps[];
}

const TwoColCTABlok: React.FC<TwoColCTABlokProps> = ({ ctas, anchor }) => {
  return withBlokContainer(
    <TwoColCTA>
      {ctas.map((cta) => (
        <SbEditable key={cta._uid} content={cta}>
          <CTABlok
            icon={cta.icon}
            label={cta.label}
            image={cta.image}
            link={cta.link}
          />
        </SbEditable>
      ))}
    </TwoColCTA>,
    anchor
  );
};

TwoColCTABlok.defaultProps = {
  ctas: [],
};

TwoColCTABlok.propTypes = {
  ctas: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default TwoColCTABlok;
