import { Blok } from '../interfaces/Blok.interface';
import { Gym } from '../interfaces/GymData';
import { StoryblokLink } from '../interfaces/StoryblokLink.interface';
import { getLinkUrl } from './getLinkUrl';

export interface GymDataBlok extends Blok {
  content: {
    _uid: string;
    site_id: string;
    pg_club_id?: string;
    migrated_to_perfect_gym: boolean;
    name: string;
    address: string;
    contact_number: string;
    coordinates: string;
    email: StoryblokLink;
    join_journey_url: StoryblokLink;
    login_url: StoryblokLink;
    contact_url: StoryblokLink;
    page_url: StoryblokLink;
  };
}

export const transformGymData = (gyms: GymDataBlok[]): Gym[] =>
  gyms.map<Gym>(({ content }) => {
    const coords = content.coordinates.split(',');

    return {
      uid: content._uid,
      site_id: content.site_id,
      pg_club_id: content.pg_club_id,
      migrated_to_perfect_gym: content.migrated_to_perfect_gym,
      name: content.name,
      address: content.address,
      contact_number: content.contact_number,
      coordinates: {
        lat: parseFloat(coords[0]),
        lng: parseFloat(coords[1]),
      },
      email: getLinkUrl(content.email),
      join_journey_url: getLinkUrl(content.join_journey_url),
      login_url: getLinkUrl(content.login_url),
      contact_url: getLinkUrl(content.contact_url),
      page_url: getLinkUrl(content.page_url),
    };
  });
