import PropTypes from 'prop-types';
import React from 'react';
import { Blok } from '../../interfaces/Blok.interface';
import Banner from '../../components/Banner/Banner.component';
import { withBlokContainer } from '../../utils/withBlokContainer';
import Memberships from '../../components/Memberships/Memberships.component';

interface MembershipBlokProps extends Blok {
  heading: string;
}

const MembershipBlok: React.FC<MembershipBlokProps> = ({
  anchor,
  heading,
  ...props
}) => {
  return withBlokContainer(<Memberships heading={heading} />, anchor);
};

MembershipBlok.defaultProps = {
  heading: '',
};

MembershipBlok.propTypes = {
  heading: PropTypes.string,
};

export default MembershipBlok;
