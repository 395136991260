import React, { useContext } from 'react';
import { MembershipContext } from '../../../contexts/MembershipContext';
import ConcessionMemberships from '../ConcessionMemberships.component';
import MembershipPrimaryHorizontalCard from './MembershipPrimaryHorizontalCard';
import MembershipSecondaryHorizontalCard from './MembershipSecondaryHorizontalCard';

interface Props {}

const MembershipHorizontalLayout: React.FC<Props> = ({}) => {
  const { memberships, concessionMemberships } = useContext(MembershipContext);
  const hasFeaturedStudentMembership = Boolean(
    concessionMemberships.find((membership) => membership.featured_membership)
  );

  return (
    <>
      <div className="grid grid-cols-12 gap-x-6 gap-y-10 items-start lg:items-end grid-flow-row-dense">
        {memberships.map((membership, idx) => {
          const { featured_membership } = membership;
          return (
            <>
              {featured_membership ? (
                <MembershipPrimaryHorizontalCard
                  membership={membership}
                  key={membership._uid + idx}
                  featuredMembership
                />
              ) : (
                <MembershipSecondaryHorizontalCard
                  membership={membership}
                  key={membership._uid + idx}
                />
              )}
            </>
          );
        })}
        <div className="hidden lg:block xl:hidden col-span-1 order-2 col-start-1 row-start-2 xl:order-none" />
      </div>
      {concessionMemberships && !!concessionMemberships.length && (
        <ConcessionMemberships>
          <div className="grid grid-cols-12 gap-x-6 gap-y-10 items-start lg:items-end grid-flow-row-dense pt-20">
            {concessionMemberships.map((membership, idx) => {
              const { featured_membership } = membership;
              return (
                <>
                  {hasFeaturedStudentMembership ? (
                    featured_membership ? (
                      <MembershipPrimaryHorizontalCard
                        membership={membership}
                        key={membership._uid + idx}
                        className={'h-full'}
                        isConcession
                        featuredMembership
                      />
                    ) : (
                      <MembershipSecondaryHorizontalCard
                        membership={membership}
                        key={membership._uid + idx}
                        className={'h-full'}
                        isConcession
                      />
                    )
                  ) : idx === 1 ? (
                    <MembershipPrimaryHorizontalCard
                      membership={membership}
                      key={membership._uid + idx}
                      className={'h-full'}
                      isConcession
                    />
                  ) : (
                    <MembershipSecondaryHorizontalCard
                      membership={membership}
                      key={membership._uid + idx}
                      className={'h-full'}
                      isConcession
                    />
                  )}
                </>
              );
            })}
            <div className="hidden lg:block xl:hidden col-span-1 order-2 col-start-1 row-start-2 xl:order-none" />
          </div>
        </ConcessionMemberships>
      )}
    </>
  );
};

export default MembershipHorizontalLayout;
