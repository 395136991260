import classNames from 'classnames';
import React, { useContext } from 'react';
import { MembershipContext } from '../../contexts/MembershipContext';
import CrossIcon from '../../svg/cross.svg';
import Button from '../Button/Button.component';
import MembershipEventWrapper from './MembershipEventWrapper.component';
import { Membership } from './memberships.interface';
import { PageContext } from '../../contexts/PageContext';

interface UpSellModalProps {
  membership: Membership;
  membershipUpSell: Membership;
  joinUrl: string;
  upsellUrl: string;
  toggleModal: (state: boolean) => void;
}
const UpSellModal: React.FC<UpSellModalProps> = ({
  membershipUpSell,
  membership,
  upsellUrl,
  joinUrl,
  toggleModal,
}) => {
  const { membershipDetails } = useContext(MembershipContext);
  const { gym } = useContext(PageContext);
  const {
    up_sell_title,
    accept_up_sell_label,
    reject_up_sell_label,
    up_sell_bg_image,
    featured_card_bg_image,
    join_url,
  } = membershipDetails;

  const { membership_name, features } = membershipUpSell;

  const backgroundImage =
    featured_card_bg_image?.filename || up_sell_bg_image?.filename;

  return (
    <div className="bg-texture-primary bg-texture/primary max-w-[400px]">
      <div className="px-6 py-6 flex justify-between">
        <p className="text-body/body font-oskari-g2--bold text-3xl leading-[30px]">
          {up_sell_title}
        </p>
        <button onClick={() => toggleModal(false)}>
          <CrossIcon className={'fill-body/body'} />
        </button>
      </div>
      <div className="p-10 bg-cover relative z-10 text-white text-left">
        <div className="space-y-7">
          <div className="">
            <p className="text-3xl sm:text-4xl font-oskari-g2--bold leading-[40px] pb-3 uppercase">
              {membership_name}
            </p>
            <p className="text-lg">For only £5 more a month you'll get...</p>
          </div>
          <div className="space-y-3">
            {!!features?.length &&
              features.map(({ feature_name, custom_label, _uid }) => (
                <div className="flex items-center justify-start" key={_uid}>
                  <div className="">
                    <svg width="24" height="24" viewBox="0 0 32 33">
                      <g clip-path="url(#clip0_2011_650)">
                        <path
                          d="M16 3.16663C8.64002 3.16663 2.66669 9.13996 2.66669 16.5C2.66669 23.86 8.64002 29.8333 16 29.8333C23.36 29.8333 29.3334 23.86 29.3334 16.5C29.3334 9.13996 23.36 3.16663 16 3.16663ZM13.3334 23.1666L6.66669 16.5L8.54669 14.62L13.3334 19.3933L23.4534 9.27329L25.3334 11.1666L13.3334 23.1666Z"
                          fill="#36BEB0"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_2011_650">
                          <rect
                            width="32"
                            height="32"
                            fill="white"
                            transform="translate(0 0.5)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                  <p className="text-left pl-3 text-base">
                    {custom_label || feature_name}
                  </p>
                </div>
              ))}
          </div>
        </div>
        {backgroundImage && (
          <>
            <div
              className="bg-cover bg-white absolute top-0 right-0 left-0 bottom-0 m-auto z-[-2] -scale-x-100"
              style={{
                backgroundImage: `url(${backgroundImage})`,
                WebkitFilter: `brightness(50%) sepia(100%) saturate(200%) hue-rotate(125deg) contrast(88%)`,
                filter: `brightness(50%) sepia(100%) saturate(200%) hue-rotate(125deg) contrast(88%)`,
              }}
            ></div>
            <div className="absolute top-0 right-0 left-0 bottom-0 m-auto z-[-1] bg-gradient-to-r from-body/body to-[transparent]"></div>
          </>
        )}
      </div>
      <div
        className={classNames(
          `space-y-6 sm:space-y-0 gap-x-0 sm:gap-x-6 grid grid-cols-1 sm:grid-cols-2 items-center justify-center p-6`
        )}
      >
        <MembershipEventWrapper
          membership={membershipUpSell}
          event={'add_to_cart'}
        >
          <Button
            cta_label={accept_up_sell_label}
            cta_link={{
              anchor: '',
              cached_url: upsellUrl,
              fieldtype: 'link',
              _uid: '',
              id: '',
              linktype: '',
              url: upsellUrl,
            }}
            className={`bg-white border border-white mt-0 hover:bg-primary hover:border-primary uppercase px-6 pt-3 pb-2 text-base`}
          />
        </MembershipEventWrapper>

        <MembershipEventWrapper membership={membership} event={'add_to_cart'}>
          <Button
            cta_label={reject_up_sell_label}
            cta_link={{
              anchor: '',
              cached_url: joinUrl,
              fieldtype: 'link',
              _uid: '',
              id: '',
              linktype: '',
              url: joinUrl,
            }}
            className="!text-white bg-body/body hover:bg-white hover:!text-body/body w-full mt-0 px-6 pt-3 pb-2 text-base"
          />
        </MembershipEventWrapper>
      </div>
    </div>
  );
};

export default UpSellModal;
