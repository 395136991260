import PropTypes from 'prop-types';
import React from 'react';
import ImageHeroWithGymSelect, {
  ContactCTAActionProps,
} from '../../components/ImageHeroWithGymSelect/ImageHeroWithGymSelect.component';
import { StoryblokAsset } from '../../interfaces/Asset.interface';
import { Blok } from '../../interfaces/Blok.interface';
import { GymDataBlok, transformGymData } from '../../utils/gymData';
import { withBlokContainer } from '../../utils/withBlokContainer';

interface ImageHeroWithGymSelectBlokProps extends Blok {
  image: StoryblokAsset;
  heading: any;
  subheading: any;
  select_gym_label: string;
  gyms: GymDataBlok[];
  contact_cta_label: string;
  contact_cta_action: ContactCTAActionProps;
}

const ImageHeroWithGymSelectBlok: React.FC<ImageHeroWithGymSelectBlokProps> = ({
  image,
  heading,
  subheading,
  select_gym_label,
  gyms,
  contact_cta_label,
  contact_cta_action,
  anchor,
  ...props
}) => {
  return withBlokContainer(
    <ImageHeroWithGymSelect
      image={image}
      heading={heading}
      subheading={subheading}
      select_gym_label={select_gym_label}
      gyms={transformGymData(gyms)}
      contact_cta_label={contact_cta_label}
      contact_cta_action={contact_cta_action}
      {...props}
    />,
    anchor
  );
};

ImageHeroWithGymSelectBlok.defaultProps = {
  image: null,
  heading: '',
  subheading: '',
  select_gym_label: '',
  gyms: [],
  contact_cta_label: '',
  contact_cta_action: {
    _uid: '',
    type: 'link',
    email: {
      address: 'Email address goes here',
      subject: 'Email subject goes here',
      body: 'Email body goes here',
    },
    plugin: '',
  },
};

ImageHeroWithGymSelectBlok.propTypes = {
  image: PropTypes.any,
  heading: PropTypes.any,
  subheading: PropTypes.any,
  select_gym_label: PropTypes.string,
  gyms: PropTypes.array,
  contact_cta_label: PropTypes.string,
  contact_cta_action: PropTypes.exact({
    _uid: PropTypes.string,
    type: PropTypes.string,
    email: PropTypes.exact({
      address: PropTypes.string,
      subject: PropTypes.string,
      body: PropTypes.string,
    }),
    plugin: PropTypes.string,
  }),
};

export default ImageHeroWithGymSelectBlok;
