import { StoryblokAsset } from '../../interfaces/Asset.interface';
import { Blok } from '../../interfaces/Blok.interface';

export enum PromotionType {
  PROMOTION = 'promotion',
  JOINING_FEE = 'joining_fee',
}

export enum IconColour {
  TEAL = 'TEAL',
  WHITE = 'WHITE',
}
export interface Promotion extends Blok {
  type: PromotionType;
  promotion_copy?: string;
  joining_fee_price?: number;
}

export interface MembershipDetails extends Blok {
  applicable_gyms: string[];
  featured_card_bg_image: StoryblokAsset;
  primary_cta_label: string;
  join_url: string;
  join_gym_url: string;
  secondary_cta_label: string;
  show_more_cta_label: string;
  sub_title: string;
  title: string;
  joining_fee_label: string;
  up_sell_title?: string;
  accept_up_sell_label?: string;
  reject_up_sell_label?: string;
  up_sell_bg_image?: StoryblokAsset;
  icon_colour?: IconColour;
}

interface Feature extends Blok {
  feature_name: string;
  custom_label: string;
  comparison_value: string;
}

export interface Membership extends Blok {
  membership_name: string;
  membership_term: string;
  price_per_month: string;
  applicable_gyms: string[];
  concession_membership: boolean;
  discounted_price_per_month: string;
  featured_membership: boolean;
  features: Feature[];
  fsi_product_code: string;
  promotion_banner_label?: string;
  promotion?: Promotion;
  joiningFeePromotion?: Promotion;
  joining_fee_price: string;
  membership_up_sell?: Membership;
  terms_and_conditions: string;
  show_cta: boolean;
}

export default interface MembershipOption {
  membership: Membership;
  featuredMembership?: boolean;
  className?: string;
  isConcession?: boolean;
  sharedFeatures?: string[];
}

export interface MembershipTable {
  memberships: Membership[];
}
export interface MembershipLayout {}
