import PropTypes from 'prop-types';
import React from 'react';
import Button from '../Button/Button.component';
import { StoryblokAsset } from '../../interfaces/Asset.interface';
import { StoryblokLink } from '../../interfaces/StoryblokLink.interface';
import TileImage from '../TileImage/TileImage.component';
import RichText from '../RichText/RichText.component';
import classnames from 'classnames';
import { BlockTheme, Blok } from '../../interfaces/Blok.interface';
import TileImageVideoModal from '../TileImageVideoModal/TileImageVideoModal.component';
import AppleStoreBtn from '../../svg/apple-store-btn.svg';
import GoogleStoreBtn from '../../svg/google-store-btn.svg';
import CustomLink from '../CustomLink/CustomLink.component';

interface CTA extends Blok {
  label: string;
  link: StoryblokLink;
}

interface FiftyFiftyImageTextNoBottomPaddingProps extends Blok {
  heading: string;
  subheading: string;
  body: any;
  cta: CTA;
  apple_store_link: StoryblokLink;
  google_play_link: StoryblokLink;
  image: StoryblokAsset;
  show_app_icons: boolean;
}

const FiftyFiftyImageTextNoBottomPadding: React.FC<FiftyFiftyImageTextNoBottomPaddingProps> =
  ({
    heading,
    subheading,
    body,
    cta,
    image,
    show_app_icons,
    apple_store_link,
    google_play_link,
    theme = 'LIGHT',
  }) => {
    return (
      <section
        className={classnames(
          'bg-repeat bg-200 bg-texture-primary bg-texture/primary'
        )}
      >
        <div className={classnames('container mx-auto text-body/body')}>
          {heading && (
            <h3 className="text-4xl sm:text-5xl font-oskari-g2--black antialiased pt-[4.5rem] uppercase leading-10 text-center">
              {heading}
            </h3>
          )}
          <div
            className={classnames(
              'grid grid-cols-1 lg:grid-cols-2  m-auto px-4 sm:px-0 sm:max-w-screen-sm md:max-w-screen-md lg:max-w-full pt-12 lg:pt-24'
            )}
          >
            <div className={`lg:flex lg:items-center`}>
              <div className="lg:max-w-[38rem] pb-8 lg:py-6">
                <div className="flex items-center justify-center lg:justify-start text-center lg:text-left">
                  <div>
                    {subheading && (
                      <p className="text-3xl sm:text-4xl font-oskari-g2--bold antialiased uppercase leading-10">
                        {subheading}
                      </p>
                    )}
                    {body && (
                      <RichText
                        textSize="text-xl"
                        fontWeight="light"
                        document={body}
                      />
                    )}
                    {show_app_icons ? (
                      <div className="flex justify-center lg:justify-start gap-x-4 pt-10">
                        {apple_store_link && (
                          <div
                            onClick={() => {
                              window.dataLayer = window.dataLayer || [];
                              window.dataLayer.push({
                                event: 'member_cta',
                                member_cta_url: apple_store_link.cached_url,
                                member_cta_text: 'Download on the App Store',
                              });
                            }}
                          >
                            <CustomLink url={apple_store_link.cached_url}>
                              <AppleStoreBtn />
                            </CustomLink>
                          </div>
                        )}
                        {google_play_link && (
                          <div
                            onClick={() => {
                              window.dataLayer = window.dataLayer || [];
                              window.dataLayer.push({
                                event: 'member_cta',
                                member_cta_url: google_play_link.cached_url,
                                member_cta_text: 'Download it on Google Play',
                              });
                            }}
                          >
                            <CustomLink url={google_play_link.cached_url}>
                              <GoogleStoreBtn />
                            </CustomLink>
                          </div>
                        )}
                      </div>
                    ) : (
                      cta && (
                        <div
                          className="flex justify-center lg:justify-start pt-10"
                          onClick={() => {
                            window.dataLayer = window.dataLayer || [];
                            window.dataLayer.push({
                              event: 'member_cta',
                              member_cta_url: cta.link,
                              member_cta_text: cta.label,
                            });
                          }}
                        >
                          <Button
                            cta_label={cta.label}
                            cta_link={cta.link}
                            background={'BLACK'}
                            className="mt-0"
                          />
                        </div>
                      )
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className={classnames('relative h-[32rem]')}>
              {image && (
                <TileImage
                  image={image}
                  objectFit="contain"
                  className="bg-bottom"
                  objectPosition="bottom center"
                />
              )}
            </div>
          </div>
        </div>
      </section>
    );
  };

FiftyFiftyImageTextNoBottomPadding.defaultProps = {
  heading: '',
  subheading: '',
  body: null,
  cta: null,
  image: null,
  show_app_icons: false,
};

FiftyFiftyImageTextNoBottomPadding.propTypes = {
  heading: PropTypes.string,
  subheading: PropTypes.string,
  body: PropTypes.any,
  cta: PropTypes.any,
  image: PropTypes.any,
  show_app_icons: PropTypes.bool,
};

export default FiftyFiftyImageTextNoBottomPadding;
