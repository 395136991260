import PropTypes from 'prop-types';
import React from 'react';
import ImageHero from '../../components/ImageHero/ImageHero.component';
import { Blok } from '../../interfaces/Blok.interface';
import { StoryblokLink } from '../../interfaces/StoryblokLink.interface';
import { StoryblokAsset } from '../../interfaces/Asset.interface';
import { withBlokContainer } from '../../utils/withBlokContainer';
interface CTA {
  label: string;
  link: StoryblokLink;
}
interface ImageHeroBlokProps extends Blok {
  image: StoryblokAsset;
  heading: any;
  subheading: any;
  cta: CTA;
}

const ImageHeroBlok: React.FC<ImageHeroBlokProps> = ({
  image,
  heading,
  subheading,
  cta,
  anchor,
}) => {
  const ctaData = cta && cta[0];
  const ctaContent = ctaData && {
    label: ctaData.label,
    link: ctaData.Gym ? ctaData.Gym.content[ctaData.url] : ctaData.link,
    _uid: ctaData._uid,
    anchor: ctaData.anchor,
    component: ctaData.component,
    _editable: ctaData._editable,
  };

  return withBlokContainer(
    <ImageHero
      image={image}
      heading={heading}
      subheading={subheading}
      cta={ctaContent}
    />,
    anchor
  );
};

ImageHeroBlok.defaultProps = {
  image: null,
  heading: '',
  subheading: '',
  cta: null,
};

ImageHeroBlok.propTypes = {
  image: PropTypes.any,
  heading: PropTypes.any,
  subheading: PropTypes.any,
  cta: PropTypes.any,
};

export default ImageHeroBlok;
