import { indexOf } from 'lodash';
import React from 'react';

interface TimetableHeadingProps {
  activeDay: number;
  onClick?: any;
}

export const TimetableHeading: React.FC<TimetableHeadingProps> = ({
  activeDay,
  onClick,
}) => {
  const activeClass = (day: number) => (activeDay == day ? 'text-primary' : '');
  const days = ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN'];

  return (
    <div className="grid grid-cols-7 text-2xl md:grid-cols-8 md:text-3xl font-oskari-g2--bold antialiased text-center">
      <div className="hidden md:block"></div>
      {days.map((day) => (
        <h4
          key={day}
          className={activeClass(indexOf(days, day) + 1)}
          onClick={() => onClick && onClick(indexOf(days, day) + 1)}
        >
          <span className="hidden md:inline">{day}</span>
          <span className="md:hidden">{day[0]}</span>
        </h4>
      ))}
    </div>
  );
};
