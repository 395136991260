import PropTypes from 'prop-types';
import React from 'react';

interface SocialsProps {
  social: string;
}

const Socials: React.FC<SocialsProps> = ({ social }) => {
  return (
    <div>
      {social.includes('instagram') ? (
        <svg
          width="56"
          height="57"
          className="transition-all text-black fill-black hover:text-primary"
        >
          <path d="M28 34.573c3.007 0 5.638-2.443 5.638-5.637 0-1.128-.376-2.443-1.128-3.195-.94-1.503-2.63-2.255-4.51-2.255s-3.57.94-4.51 2.255a5.103 5.103 0 0 0-1.128 3.195c0 3.007 2.631 5.637 5.638 5.637ZM40.215 21.983v-5.45h-5.45v5.45h5.45Z" />
          <path d="M28 .748c-15.41 0-28 12.59-28 28s12.59 28 28 28 28-12.59 28-28-12.59-28-28-28Zm15.973 24.805v13.155c0 3.382-2.819 6.201-6.201 6.201H18.228c-3.382 0-6.201-2.819-6.201-6.201V19.164c0-3.382 2.819-6.201 6.201-6.201h19.544c3.382 0 6.201 2.819 6.201 6.201v6.39Z" />
          <path d="M36.644 28.936c0 4.886-3.946 8.644-8.644 8.644-4.886 0-8.644-3.946-8.644-8.644 0-1.128.188-2.255.563-3.195h-4.698v13.155a2.968 2.968 0 0 0 3.007 3.007h19.544a2.968 2.968 0 0 0 3.007-3.007V25.74H36.08c.376.94.564 1.88.564 3.195Z" />
        </svg>
      ) : social.includes('facebook') ? (
        <svg
          width="56"
          height="57"
          className="transition-all text-black fill-black hover:text-primary"
        >
          <path d="M28 .748c-15.41 0-28 12.59-28 28s12.59 28 28 28 28-12.59 28-28-12.59-28-28-28Zm6.953 29.127h-4.51v16.35h-6.765v-16.35h-3.195V24.05h3.195v-3.758c0-2.631 1.315-6.765 6.765-6.765h5.074v5.637h-3.57c-.564 0-1.504.376-1.504 1.503v3.383h5.074l-.564 5.825Z" />
        </svg>
      ) : (
        social.includes('youtube') && (
          <svg
            width="56"
            height="57"
            className="transition-all text-black fill-black hover:text-primary"
          >
            <path d="M14.658 30.815h2.255v12.027h2.067V30.815h2.255v-1.88h-6.577v1.88ZM32.51 32.319c-.752 0-1.315.375-1.88 1.127v-4.51h-1.878V43.03h1.879v-.94c.564.752 1.315 1.128 1.879 1.128.752 0 1.315-.376 1.503-1.128.188-.376.188-1.127.188-2.067V35.89c0-.94 0-1.691-.188-2.067-.375-1.128-.751-1.504-1.503-1.504Zm-.188 7.704c0 .94-.188 1.316-.752 1.316-.375 0-.563-.188-.94-.376v-6.39c.377-.375.565-.375.94-.375.564 0 .752.563.752 1.503v4.322ZM25.181 40.4c-.376.563-.752.939-1.127.939a.404.404 0 0 1-.376-.376v-8.456h-1.88v8.268c0 .752 0 1.315.189 1.503.187.564.563.752 1.127.752.752 0 1.503-.376 2.067-1.315v1.127h1.88V32.32h-1.88v8.08ZM26.685 22.923c.563 0 .94-.564.94-1.504v-4.51c0-.94-.377-1.503-.94-1.503-.564 0-.94.564-.94 1.503v4.51c0 .94.376 1.504.94 1.504Z" />
            <path d="M28 .748c-15.41 0-28 12.59-28 28s12.59 28 28 28 28-12.59 28-28-12.59-28-28-28Zm3.195 13.154h1.879v8.457c0 .376.188.376.376.376.375 0 .751-.376 1.127-.94v-8.08h1.88v10.523h-1.88V23.11c-.752.94-1.503 1.316-2.255 1.316-.564 0-1.127-.188-1.315-.752-.188-.376-.188-.751-.188-1.503v-8.269h.376Zm-7.33 3.383c0-1.128.189-1.88.565-2.443.563-.752 1.315-1.128 2.255-1.128s1.69.376 2.255 1.128c.375.564.563 1.315.563 2.443v3.758c0 1.128-.188 1.88-.563 2.443-.564.752-1.316 1.128-2.255 1.128-.94 0-1.692-.376-2.255-1.128-.376-.564-.564-1.315-.564-2.443v-3.758Zm-5.073-6.953 1.503 5.637 1.504-5.637h2.067l-2.631 8.456v5.826h-2.067v-5.826c-.188-.94-.564-2.443-1.316-4.51-.375-1.315-.94-2.63-1.315-3.946h2.255Zm24.241 33.45c-.375 1.69-1.69 2.818-3.382 3.006-3.946.376-7.893.376-11.651.376-3.946 0-7.893 0-11.651-.376-1.691-.188-3.007-1.503-3.383-3.006-.563-2.443-.563-4.886-.563-7.33 0-2.442 0-5.073.563-7.328.376-1.691 1.692-2.819 3.383-3.007 3.946-.376 7.893-.376 11.651-.376 3.946 0 7.893 0 11.651.376 1.691.188 3.007 1.503 3.382 3.007.564 2.443.564 4.886.564 7.329 0 2.443 0 5.074-.564 7.329Z" />
            <path d="M38.523 32.319c-.94 0-1.69.375-2.255 1.127-.375.564-.563 1.316-.563 2.443v3.57c0 1.128.188 1.88.563 2.444.564.751 1.316 1.127 2.255 1.127.94 0 1.692-.376 2.255-1.127.188-.376.376-.752.376-1.128v-1.316h-1.879v1.316c-.188.564-.376.752-.94.752-.563 0-.94-.564-.94-1.504v-1.879h3.76v-2.067c0-1.127-.189-1.88-.565-2.443-.375-.94-1.127-1.315-2.067-1.315Zm.94 4.134h-1.88v-.94c0-.94.377-1.503.94-1.503.564 0 .94.564.94 1.503v.94Z" />
          </svg>
        )
      )}
    </div>
  );
};

Socials.defaultProps = {
  social: '',
};

Socials.propTypes = {
  social: PropTypes.string,
};

export default Socials;
