import classNames from 'classnames';
import React, { useContext, useState } from 'react';
import ReactModal from 'react-modal';
import { v4 as uuidv4 } from 'uuid';
import MembershipOption from '../memberships.interface';
import { PageContext } from '../../../contexts/PageContext';
import { MembershipContext } from '../../../contexts/MembershipContext';
import { generateJoinUrl } from '../../../utils/joinUrl';
import MinusSVG from '../../../svg/minus.svg';
import StarSVG from '../../../svg/star-green.svg';
import TickSVG from '../../../svg/tick.svg';
import Button from '../../Button/Button.component';
import MembershipEventWrapper from '../MembershipEventWrapper.component';
import MoreInfoModal from '../MoreInfoModal.component';
import UpSellModal from '../UpSellModal.component';

const MembershipComparisonCard: React.FC<MembershipOption> = ({
  membership,
  featuredMembership,
  isConcession,
  sharedFeatures,
}) => {
  const membershipContext = useContext(MembershipContext);
  const { gym } = useContext(PageContext);
  const [moreInfoModal, setMoreInfoModal] = useState<boolean>(false);
  const [upSellModal, setUpSellModal] = useState<boolean>(false);
  const toggleModal = (state: boolean): void => {
    setMoreInfoModal(state);
    setUpSellModal(state);
  };
  const {
    featured_card_bg_image,
    primary_cta_label,
    join_url,
    join_gym_url,
    secondary_cta_label,
    joining_fee_label,
  } = membershipContext.membershipDetails;

  const {
    membership_name,
    membership_term,
    price_per_month,
    discounted_price_per_month,
    fsi_product_code,
    features,
    promotion,
    joiningFeePromotion,
    joining_fee_price,
    membership_up_sell,
    show_cta,
  } = membership;

  const joinUrl = generateJoinUrl({
    site_id: gym.site_id,
    pg_club_id: gym.pg_club_id,
    migrated: gym.migrated_to_perfect_gym,
    fsi_product_code,
    join_url,
    join_gym_url,
  });

  const upsellUrl = generateJoinUrl({
    site_id: gym.site_id,
    pg_club_id: gym.pg_club_id,
    migrated: gym.migrated_to_perfect_gym,
    fsi_product_code: membership_up_sell?.fsi_product_code,
    join_url,
    join_gym_url,
  });

  return (
    <>
      <div
        className={classNames(
          `px-2 py-4 sm:p-4 lg:px-8 lg:py-10 space-y-4 lg:space-y-6`,
          {
            'bg-body/body': !featuredMembership,
            'bg-primary': featuredMembership,
          }
        )}
      >
        <div
          className={classNames({
            'text-white': !featuredMembership,
            'text-body/body': featuredMembership,
          })}
        >
          {promotion?.promotion_copy && (
            <>
              <div className="flex pb-2 sm:pb-4 lg:pb-6 justify-center w-full">
                <div
                  className={classNames(
                    'flex items-center justify-center w-full p-1 sm:py-2 sm:px-2 sm:space-x-1 lg:py-3 lg:px-4 lg:space-x-2 rounded-full',
                    {
                      'text-body/body bg-white': featuredMembership,
                      'text-white sm:bg-body/body border-white border':
                        !featuredMembership,
                    }
                  )}
                >
                  <div className="hidden sm:block scale-75 lg:scale-1">
                    <StarSVG />
                  </div>
                  <span className="text-[0.65rem] sm:text-xs lg:text-base font-oskari-g2--bold uppercase sm:leading-[0px] mt-[0.35em]">
                    {promotion.promotion_copy}
                  </span>
                  <div className="hidden sm:block scale-75 lg:scale-1">
                    <StarSVG />
                  </div>
                </div>
              </div>
            </>
          )}
          {membership_name && (
            <p className="font-bold text-lg sm:text-2xl lg:text-4xl font-oskari-g2--bold uppercase leading-[30px] sm:leading-[40px] lg:pb-1">
              {membership_name}
            </p>
          )}
          {membership_term && (
            <p className="text-sm sm:text-base font-oskari-g2--regular sm:leading-[28px]">
              {membership_term}
            </p>
          )}
        </div>
        <div>
          <div>
            {membership_up_sell ? (
              <button
                aria-label="Open modal"
                className={classNames(
                  'w-full sm:w-auto transition-all inline-block mt-0 px-2 pb-2 pt-3 sm:px-8 text-xs sm:text-sm lg:text-text-base cursor-pointer uppercase font-oskari-g2--semibold antialiased text-center',
                  {
                    'bg-body/body hover:bg-white !text-white hover:!text-black':
                      featuredMembership,
                    'bg-white text-body/body hover:bg-primary':
                      !featuredMembership,
                  }
                )}
                onClick={() => setUpSellModal(!upSellModal)}
              >
                {primary_cta_label}
              </button>
            ) : (
              <MembershipEventWrapper
                membership={membership}
                event={'add_to_cart'}
              >
                <Button
                  cta_label={primary_cta_label}
                  cta_link={{
                    anchor: '',
                    cached_url: joinUrl,
                    fieldtype: 'link',
                    _uid: '',
                    id: '',
                    linktype: '',
                    url: joinUrl,
                  }}
                  className={classNames(
                    'inline-block mt-0 !px-2 pb-2 pt-3 sm:!px-8 text-xs sm:text-sm lg:text-text-base',
                    {
                      'bg-body/body hover:bg-white !text-white hover:!text-black':
                        featuredMembership,
                      'bg-white text-body/body hover:bg-primary':
                        !featuredMembership,
                    }
                  )}
                />
              </MembershipEventWrapper>
            )}
          </div>
          <div>
            {show_cta && (
              <MembershipEventWrapper
                membership={membership}
                event={'view_item'}
              >
                <button
                  aria-label="Open modal"
                  className={classNames(
                    'w-full sm:w-auto transition-all text-body/body bg-[transparent] underline inline-block mt-0 pt-4 lg:pt-6 pb-0 text-xs sm:text-sm lg:text-text-base cursor-pointer px-1 sm:px-8  uppercase font-oskari-g2--semibold antialiased text-center',
                    {
                      'text-body/body': featuredMembership,
                      '!text-white hover:bg-body/body': !featuredMembership,
                    }
                  )}
                  onClick={() => {
                    setMoreInfoModal(!moreInfoModal);
                  }}
                >
                  {secondary_cta_label}
                </button>
              </MembershipEventWrapper>
            )}
          </div>
        </div>
      </div>
      <div
        className={classNames('p-2 sm:p-8 flex flex-col justify-center', {
          'bg-primary/100 pb-12 sm:pb-20': featuredMembership,
          'bg-steel/100': !featuredMembership,
        })}
      >
        <div className="space-y-2">
          {discounted_price_per_month && (
            <p className="line-through text-body/body text-sm sm:text-base lg:text-lg leading-[28px]">
              £{price_per_month} p/m
            </p>
          )}
          {price_per_month && (
            <p className="text-body/body text-2xl sm:text-3xl font-oskari-g2--black leading-[36px]">
              £
              {discounted_price_per_month
                ? discounted_price_per_month
                : price_per_month}
            </p>
          )}
        </div>
        {joiningFeePromotion ? (
          <p
            className={`text-body/body text-xs sm:text-sm lg:text-text-base leading-[24px] `}
          >
            No {joining_fee_label || 'Joining Fee'}
          </p>
        ) : (
          <p
            className={`text-body/body text-xs sm:text-sm lg:text-text-base leading-[24px] `}
          >
            {joining_fee_label || 'Joining Fee'}
            {': '}
            <span className="font-semibold">{`£${joining_fee_price}`}</span>
          </p>
        )}
      </div>
      <ul className={classNames(`text-center hidden xl:flex flex-col`)}>
        {sharedFeatures.map((sharedFeature, idx) => {
          const matchingFeature = features.find(
            (feature, idx) => feature.feature_name === sharedFeature
          );

          return (
            <li
              className={classNames(
                `flex flex-col sm:flex-row items-center justify-center sm:justify-between xl:justify-center px-2 sm:pl-12 sm:pr-[50px] xl:px-4 py-[10px] sm:h-[65px] xl:space-x-2`,
                {
                  'bg-white': idx % 2 == 0 && !featuredMembership,
                  'bg-steel/100': idx % 2 != 0 && !featuredMembership,
                  'bg-primary/50 xl:font-oskari-g2--semibold':
                    featuredMembership,
                }
              )}
              key={uuidv4()}
            >
              {featuredMembership && (
                <div className="space-x-2 xl:hidden block w-full">
                  <p
                    className={classNames(
                      `text-center sm:text-left text-sm lg:text-text-base`
                    )}
                  >
                    {sharedFeature}
                  </p>
                </div>
              )}
              {matchingFeature ? (
                matchingFeature.comparison_value || (
                  <div className="h-8 w-8">
                    <TickSVG />
                  </div>
                )
              ) : (
                <div className="h-8 w-8">
                  <MinusSVG />
                </div>
              )}
            </li>
          );
        })}
      </ul>
      <ReactModal
        isOpen={upSellModal || moreInfoModal}
        className="relative h-full w-full flex justify-center items-center container mx-auto"
      >
        {upSellModal && (
          <UpSellModal
            membershipUpSell={membership_up_sell}
            membership={membership}
            joinUrl={joinUrl}
            upsellUrl={upsellUrl}
            toggleModal={toggleModal}
          />
        )}
        {moreInfoModal && (
          <MoreInfoModal
            membership={membership}
            joinUrl={joinUrl}
            toggleModal={toggleModal}
          />
        )}
      </ReactModal>
    </>
  );
};

export default MembershipComparisonCard;
