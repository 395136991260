import PropTypes from 'prop-types';
import React from 'react';
import { StoryblokAsset } from '../../interfaces/Asset.interface';
import { Blok } from '../../interfaces/Blok.interface';
import Accordion from '../../components/Accordion/Accordion.component';
import { withBlokContainer } from '../../utils/withBlokContainer';

interface DocumentBlokProps extends Blok {
  label: string;
  document: StoryblokAsset;
}
interface AccordionBlokProps extends Blok {
  documents: DocumentBlokProps[];
}

const AccordionBlok: React.FC<AccordionBlokProps> = ({
  documents,
  anchor,
  theme,
}) => {
  return withBlokContainer(
    <Accordion documents={documents} theme={theme} />,
    anchor
  );
};

AccordionBlok.defaultProps = {
  documents: [],
};

AccordionBlok.propTypes = {
  documents: PropTypes.any,
};

export default AccordionBlok;
