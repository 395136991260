import React from 'react';
import FindAGym from '../../components/FindAGym/FindAGym.component';
import { Blok } from '../../interfaces/Blok.interface';
import { GymDataBlok, transformGymData } from '../../utils/gymData';
import { withBlokContainer } from '../../utils/withBlokContainer';

interface FindAGymBlokProps extends Blok {
  list_title: string;
  gyms: GymDataBlok[];
}

const FindAGymBlok: React.FC<FindAGymBlokProps> = ({
  anchor,
  list_title,
  gyms,
}) => {
  return withBlokContainer(
    <FindAGym listTitle={list_title} gyms={transformGymData(gyms)} />,
    anchor
  );
};

FindAGymBlok.defaultProps = {};

FindAGymBlok.propTypes = {};

export default FindAGymBlok;
