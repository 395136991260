import classNames from 'classnames';
import React, { useContext, useEffect } from 'react';
import { MembershipContext } from '../../contexts/MembershipContext';
import { PageContext } from '../../contexts/PageContext';
import { MembershipLayout } from '../../interfaces/MembershipLayout.enum';
import { isEditMode } from '../../utils/isEditMode';
import MembershipComparisonLayout from './MembershipComparisonLayout/MembershipComparisonLayout.component';
import MembershipHorizontalLayout from './MembershipHorizontalLayout/MembershipHorizontalLayout.component';
import MembershipVerticalLayout from './MembershipVerticalLayout/MembershipVerticalLayout.component';
import MembershipsPlaceholder from './MembershipsPlaceholder.component';

interface MembershipsProps {
  id: string;
}

const Memberships: React.FC<MembershipsProps> = ({ id }) => {
  const { membershipDetails, memberships, concessionMemberships } =
    useContext(MembershipContext);
  const { gym } = useContext(PageContext);

  useEffect(() => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'view_item_list',
      ecommerce: {
        items: [...memberships].map((membership, idx) => {
          const TagManagerFeatureKeys = membership.features.map(
            (feature, idx) => `item_category${idx !== 0 ? idx : ''}`
          );
          const membership_data = {
            item_name: membership.membership_name,
            item_id: membership.fsi_product_code,
            site_id: gym.name,
            price:
              Number(membership.price_per_month) +
              Number(membership.joining_fee_price),
            discount: membership.discounted_price_per_month,
            joining_fee: membership.joining_fee_price,
            currency: 'GBP',
            coupon: membership?.promotion?.promotion_copy,
            item_brand: 'Everlast',
            item_list_name:
              membershipDetails?.component === MembershipLayout.HORIZONTAL
                ? 'variant 1'
                : membershipDetails?.component === MembershipLayout.VERICAL
                ? 'variant 2'
                : 'variant 3',
            item_list_id:
              membershipDetails?.component === MembershipLayout.HORIZONTAL
                ? 'variant_1'
                : membershipDetails?.component === MembershipLayout.VERICAL
                ? 'variant_2'
                : 'variant_3',
            // index: idx + 1,
            quantity: 1,
          };
          membership.features.map(
            (feature, idx) =>
              (membership_data[TagManagerFeatureKeys[idx]] =
                feature.feature_name)
          );
          return membership_data;
        }),
      },
    });
  }, []);

  const hasIncorrectNumberOfCards = memberships?.length !== 3;
  const hasNoFeatureCard =
    memberships?.filter((membership) => membership.featured_membership)
      ?.length === 0;
  const hasMultipleFeatureCards =
    memberships?.filter((membership) => membership.featured_membership)
      ?.length > 1 ||
    concessionMemberships?.filter(
      (membership) => membership.featured_membership
    )?.length > 1;

  if (
    hasNoFeatureCard ||
    hasMultipleFeatureCards ||
    hasIncorrectNumberOfCards
  ) {
    if (isEditMode()) {
      return (
        <MembershipsPlaceholder
          hasNoFeatureCard={hasNoFeatureCard}
          hasMultipleFeatureCards={hasMultipleFeatureCards}
          numberOfCards={memberships?.length}
        />
      );
    } else return <></>;
  }
  const { title, sub_title } = membershipDetails;

  return (
    <section className="bg-texture-primary bg-texture/primary py-20">
      <div
        className={classNames('text-center', {
          'container mx-auto':
            membershipDetails?.component === MembershipLayout.HORIZONTAL ||
            membershipDetails?.component === MembershipLayout.COMPARISON,
        })}
      >
        <div className="pb-20 space-y-4">
          {title && gym?.name && (
            <h1 className="text-5xl font-oskari-g2--black leading-[56px]">
              {title} {gym.name}
            </h1>
          )}
          {sub_title && (
            <h2 className="text-3xl font-oskari-g2--semibold leading-[36px] text-primary/800">
              {sub_title}
            </h2>
          )}
        </div>
        {membershipDetails?.component === MembershipLayout.HORIZONTAL ? (
          <MembershipHorizontalLayout />
        ) : membershipDetails?.component === MembershipLayout.VERICAL ? (
          <MembershipVerticalLayout />
        ) : (
          membershipDetails?.component === MembershipLayout.COMPARISON && (
            <MembershipComparisonLayout />
          )
        )}
      </div>
    </section>
  );
};

export default Memberships;
