import PropTypes from 'prop-types';
import React from 'react';
import { BlockTheme, Blok } from '../../interfaces/Blok.interface';
import TileImage from '../TileImage/TileImage.component';
import Socials from '../Socials/Socials.component';
import { StoryblokAsset } from '../../interfaces/Asset.interface';
import classnames from 'classnames';

interface ImageBlok extends Blok {
  image: StoryblokAsset;
}
interface SocialBannerProps {
  heading: string;
  sub_heading: string;
  images: ImageBlok[];
  social_links: string[];
  theme: BlockTheme;
}

const SocialBanner: React.FC<SocialBannerProps> = ({
  heading,
  sub_heading,
  images,
  social_links,
  theme,
}) => {
  return (
    <section>
      <div
        className={classnames('bg-repeat bg-200 ', {
          'bg-texture-primary bg-texture/primary': theme === 'LIGHT',
          'bg-texture-secondary bg-texture/secondary': theme === 'DARK',
        })}
      >
        <div className="pt-20 pb-6">
          <div className="container mx-auto">
            {heading && (
              <h3
                className={classnames(
                  'font-oskari-g2--bold antialiased leading-none text-4xl sm:text-5xl text-center pt-1',
                  {
                    'text-body/body': theme === 'LIGHT',
                    'text-white ': theme === 'DARK',
                  }
                )}
              >
                {heading}
              </h3>
            )}
            {sub_heading && (
              <h4
                className={classnames(
                  'font-oskari-g2--semibold antialiased leading-none text-2xl sm:text-3xl text-center pb-6 pt-4 sm:pt-0 uppercase',
                  {
                    'text-body/600': theme === 'LIGHT',
                    'text-white ': theme === 'DARK',
                  }
                )}
              >
                {sub_heading}
              </h4>
            )}
            {social_links && (
              <div className="flex justify-center items-center">
                {social_links.map((social_link) => (
                  <a
                    className={classnames('mx-4', {
                      invert: theme === 'DARK',
                    })}
                    aria-label="Social"
                    href={social_link}
                    key={social_link}
                  >
                    <Socials social={social_link}></Socials>
                  </a>
                ))}
              </div>
            )}
            {images && (
              <div className="grid grid-cols-1 gap-y-8 md:grid-cols-3 sm:gap-x-8 lg:gap-x-10 py-10">
                {images.map((image) => (
                  <TileImage image={image.image} key={image._uid}></TileImage>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

SocialBanner.defaultProps = {
  heading: '',
  sub_heading: '',
  social_links: null,
  images: null,
};

SocialBanner.propTypes = {
  heading: PropTypes.string,
  sub_heading: PropTypes.string,
  social_links: PropTypes.any,
  images: PropTypes.any,
};

export default SocialBanner;
