import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import ReactPlayer from 'react-player';
import { StoryblokAsset } from '../../interfaces/Asset.interface';
import { Slot } from '../../interfaces/Slot.interface';
import { StoryblokLink } from '../../interfaces/StoryblokLink.interface';
import { KeyItem } from '../../services/timetable/timetable.context';
import '../../styles/Timetable.css';
import { getClassesByDay, getClassesBySection } from '../../utils/getClasses';
import RichText from '../RichText/RichText.component';
import { TimetableHeading } from './TimetableHeading.component';
import { TimetableItem } from './TimetableItem.component';
import { TimetableKey } from './TimetableKey.component';
import { TimetableSection } from './TimetableSection.component';

interface TimetableProps {
  title: string;
  timetable: Slot[];
  details: {
    [key: string]: ModalData;
  };
  timetableKey?: KeyItem[];
}

export interface ModalData {
  title: string;
  image: StoryblokAsset;
  body: string;
  background: StoryblokAsset;
  video: StoryblokLink;
}

const Timetable: React.FC<TimetableProps> = ({
  timetable,
  title,
  details,
  timetableKey,
}) => {
  const [activeModal, setActiveModal] = useState<ModalData>(null);
  const [activeDay, setActiveDay] = useState<number>(null);

  useEffect(() => {
    const day = new Date().getDay();
    setActiveDay(day);
  }, [setActiveDay]);

  if (!timetable || !timetable.length) {
    return (
      <div className="container text-white m-auto py-36">
        <div className=" flex justify-center items-center">
          <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-gray-900"></div>
        </div>
      </div>
    );
  }

  const classesBySection = getClassesBySection(timetable);
  const classesByDay = getClassesByDay(timetable);

  const getTimetableItems = (items: Slot[], key: string) => {
    return items
      .sort((a, b) => (a.start > b.start ? 1 : -1))
      .map((slot, idx) => {
        const modalData = details[slot.subtype];

        const isModalData = !!modalData;

        return (
          <TimetableItem
            key={`${slot.name}${idx}${key}`}
            name={slot.name}
            description={slot.description}
            time={`${slot.start}-${slot.end}`}
            colour={slot.color}
            isClickable={isModalData}
            onClick={() =>
              modalData &&
              setActiveModal({
                title: modalData?.title,
                image: modalData?.image,
                body: modalData?.body,
                background: modalData?.background,
                video: modalData?.video,
              })
            }
            type={slot.type}
          />
        );
      });
  };

  const getTimetableItemsBySection = (section) => {
    return Object.keys(classesBySection[section]).map((day) => {
      const slots = getTimetableItems(classesBySection[section][day], 'd');
      return <div key={day}>{slots}</div>;
    });
  };

  const getTimetableItemsByDay = (day: number) => {
    return getTimetableItems(classesByDay[day], 'm');
  };

  const isModalEnabled = !!Object.keys(details).length;

  return (
    <section className="relative">
      {isModalEnabled && (
        <Modal
          className="relative h-full w-full flex justify-center items-center"
          isOpen={!!activeModal}
          ariaHideApp={false}
        >
          <div className="absolute inset-0 m-auto z-50">
            <div className="flex justify-center items-center h-full px-4">
              <div className="max-w-3xl relative">
                <div className="modal-header bg-texture-primary bg-texture/primary py-3.5 px-6 bg-repeat bg-200">
                  <h4 className="uppercase text-2xl sm:text-4xl font-oskari-g2--bold">
                    {activeModal?.title}
                  </h4>
                </div>
                <div
                  className={`bg-texture/secondary sm:grid sm:grid-cols-3 pt-6 pb-4 sm:py-4 bg-cover bg-center`}
                  style={
                    {
                      // backgroundImage: `url(${activeModal?.background?.filename})`,
                    }
                  }
                >
                  <div className="modal-img sm:flex sm:justify-center sm:items-center col-span-1 px-6 sm:px-8 sm:py-4">
                    {activeModal?.image && (
                      <img src={activeModal.image.filename} />
                    )}
                  </div>

                  <div className="text-white col-span-2 flex items-center sm:py-4">
                    <div>
                      <h5 className="text-xl sm:text-2xl font-oskari-g2--bold pt-6 sm:pt-4 px-6 uppercase">
                        Class information
                      </h5>
                      <div className="py-2 px-6 text-sm sm:text-base">
                        <RichText document={activeModal?.body} />
                      </div>
                    </div>
                  </div>

                  {activeModal?.video?.url && (
                    <div className="text-white col-span-3 m-6">
                      <div
                        style={{
                          position: 'relative',
                          paddingTop: '56.25%',
                        }}
                      >
                        <ReactPlayer
                          url={activeModal.video.url}
                          muted
                          playing
                          controls
                          height={'100%'}
                          width={'100%'}
                          className="absolute top-0 left-0"
                        />
                      </div>
                    </div>
                  )}
                </div>
                <button
                  className="absolute top-5 right-4 sm:top-7 sm:right-7 block"
                  onClick={() => setActiveModal(null)}
                >
                  <svg width="20" height="20" viewBox="0 0 24 24" fill="none">
                    <path
                      d="M23.6666 2.6835L21.3166 0.333496L11.9999 9.65016L2.68325 0.333496L0.333252 2.6835L9.64992 12.0002L0.333252 21.3168L2.68325 23.6668L11.9999 14.3502L21.3166 23.6668L23.6666 21.3168L14.3499 12.0002L23.6666 2.6835Z"
                      fill="#141414"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </Modal>
      )}
      <div className="bg-texture/tertiary bg-texture-tertiary bg-repeat bg-200">
        <div className="container mx-auto">
          <div className="py-8 sm:py-16 text-white">
            <h3 className="text-3xl sm:text-5xl font-oskari-g2--bold antialiased text-center py-2 md:pb-8 uppercase">
              {title}
            </h3>
            <TimetableKey items={timetableKey} />

            <TimetableHeading
              activeDay={activeDay}
              onClick={(d) => setActiveDay(d)}
            />

            <div className="hidden md:block">
              {Object.keys(classesBySection).map((section) => (
                <TimetableSection title={section} key={section}>
                  {getTimetableItemsBySection(section)}
                </TimetableSection>
              ))}
            </div>

            <div className="md:hidden block">
              {Object.keys(classesByDay).map((d) => (
                <div
                  key={d}
                  className={`${
                    activeDay == parseInt(d) ? 'grid' : 'hidden'
                  } grid-cols-1 gap-y-4 text-3xl font-oskari-g2--bold antialiased text-black text-center`}
                >
                  <div></div>
                  {getTimetableItemsByDay(parseInt(d))}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

Timetable.defaultProps = {
  title: '',
  timetable: [],
  details: null,
  timetableKey: [],
};

Timetable.propTypes = {
  title: PropTypes.string,
  details: PropTypes.any,
  timetableKey: PropTypes.any,
  timetable: PropTypes.arrayOf(
    PropTypes.exact({
      name: PropTypes.string,
      description: PropTypes.string,
      color: PropTypes.string,
      type: PropTypes.string,
      subtype: PropTypes.string,
      start: PropTypes.string,
      end: PropTypes.string,
      day: PropTypes.number,
      week: PropTypes.number,
      location: PropTypes.string,
    })
  ),
};

export default Timetable;
