import PropTypes from 'prop-types';
import React from 'react';
import GymSelectContact, {
  ContactCTAActionProps,
} from '../../components/GymSelectContact/GymSelectContact.component';
import { Blok } from '../../interfaces/Blok.interface';
import { GymDataBlok, transformGymData } from '../../utils/gymData';
import { withBlokContainer } from '../../utils/withBlokContainer';

interface GymSelectContactBlokProps extends Blok {
  title: any;
  select_gym_label: string;
  gyms: GymDataBlok[];
  contact_cta_label: string;
  contact_cta_action: ContactCTAActionProps;
}

const GymSelectContactBlok: React.FC<GymSelectContactBlokProps> = ({
  title,
  select_gym_label,
  gyms,
  contact_cta_label,
  contact_cta_action,
  anchor,
  ...props
}) => {
  return withBlokContainer(
    <GymSelectContact
      title={title}
      select_gym_label={select_gym_label}
      gyms={transformGymData(gyms)}
      contact_cta_label={contact_cta_label}
      contact_cta_action={contact_cta_action}
      {...props}
    />,
    anchor
  );
};

GymSelectContactBlok.defaultProps = {
  title: null,
  select_gym_label: '',
  gyms: [],
  contact_cta_label: '',
  contact_cta_action: {
    _uid: '',
    type: 'link',
    email: {
      subject: 'Email subject goes here',
      body: 'Email body goes here',
    },
    plugin: '',
  },
};

GymSelectContactBlok.propTypes = {
  title: PropTypes.object,
  select_gym_label: PropTypes.string,
  gyms: PropTypes.array,
  contact_cta_label: PropTypes.string,
  contact_cta_action: PropTypes.exact({
    _uid: PropTypes.string,
    type: PropTypes.string,
    email: PropTypes.exact({
      subject: PropTypes.string,
      body: PropTypes.string,
    }),
    plugin: PropTypes.string,
  }),
};

export default GymSelectContactBlok;
