import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet';
import MetaData from '../../interfaces/MetaData.interface';

interface SEOComponentProps {
  metaData: MetaData;
}

const SEO: React.FC<SEOComponentProps> = ({ metaData }) => {
  const url = '';

  return (
    <Helmet>
      <html lang="en" />
      <meta charSet="utf-8" />
      <title>{metaData.title}</title>

      <meta name="viewport" content="width=device-width" />
      <meta name="description" content={metaData.description} />

      <meta
        property="og:title"
        content={`${metaData.og_title} ${
          metaData.og_title && `|`
        } Everlast Gyms`}
      />
      <meta property="og:site_name" content="Everlast Gyms" />
      <meta property="og:description" content={metaData.og_description} />
      <meta property="og:image" content={metaData.og_image} />
      <meta property="og:url" content={url} />
      <meta property="og:type" content="website" />
      <meta property="og:locale" content="en" />
      <meta
        name="twitter:title"
        content={`${metaData.twitter_title || 'Everlast Gyms'}`}
      />
      <meta
        name="twitter:description"
        content={`${metaData.twitter_description || metaData.description}`}
      />
      <meta name="twitter:image" content={metaData.twitter_image} />
      <link rel="canonical" href={url} />
      {/* <!-- OneTrust Cookies Consent Notice start for www.everlastgyms.com --> */}
      <script
        type="text/javascript"
        src="https://cdn-ukwest.onetrust.com/consent/62212093-5b76-4c43-a48d-43f9197ce4bc/OtAutoBlock.js"
      ></script>
      <script
        src="https://cdn-ukwest.onetrust.com/scripttemplates/otSDKStub.js"
        type="text/javascript"
        charSet="UTF-8"
        data-domain-script="62212093-5b76-4c43-a48d-43f9197ce4bc"
      ></script>
      <script type="text/javascript">{`function OptanonWrapper() {}`}</script>
      <div id="ot-sdk-cookie-policy"></div>
      {/* <!-- OneTrust Cookies Consent Notice end for www.everlastgyms.com --> */}
    </Helmet>
  );
};

SEO.defaultProps = {};

SEO.propTypes = {};

export default SEO;
