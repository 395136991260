import classNames from 'classnames';
import React from 'react';
import MembershipOption from '../memberships.interface';
import MembershipVerticalCard from './MembershipVerticalCard';

const MembershipPrimaryVerticalCard: React.FC<MembershipOption> = ({
  membership,
  className,
  isConcession,
}) => {
  return (
    <div
      className={classNames(
        `container mx-auto lg:px-0 lg:mx-0 lg:max-w-none lg:flex w-full`,
        className
      )}
    >
      <MembershipVerticalCard
        membership={membership}
        key={membership._uid}
        featuredMembership
        isConcession={isConcession}
      />
    </div>
  );
};

export default MembershipPrimaryVerticalCard;
