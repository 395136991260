import React from 'react';
import { Blok } from '../../interfaces/Blok.interface';
import Placeholder from '../Placeholder/Placeholder.component';
import PropTypes from 'prop-types';
import ComponentBlokMap from '../../bloks/ComponentBlokMap';
import SbEditable from 'storyblok-react';

interface DynamicComponentProps {
  blok: Blok;
}

const DynamicComponent: React.FC<DynamicComponentProps> = ({ blok }) => {
  if (typeof ComponentBlokMap[blok.component] !== 'undefined') {
    const Component = ComponentBlokMap[blok.component];

    return (
      <SbEditable key={blok._uid} content={blok}>
        <Component key={blok._uid} {...blok} />
      </SbEditable>
    );
  }

  return blok.component ? <Placeholder componentName={blok.component} /> : null;
};

DynamicComponent.propTypes = {
  blok: PropTypes.any,
};

export default DynamicComponent;
