import React from 'react';
import { StoryblokAsset } from '../../interfaces/Asset.interface';
import PlayCircleOutline from '../../svg/play_circle_outline.svg';
import TileImage from '../TileImage/TileImage.component';
import ReactModal from 'react-modal';
import ReactPlayer from 'react-player';
import classnames from 'classnames';

interface TileImageVideoModalProps {
  image: StoryblokAsset;
  videoURL: string;
}

const TileImageVideoModal: React.FC<TileImageVideoModalProps> = ({
  image,
  videoURL,
}) => {
  const [modalIsOpen, setModalIsOpen] = React.useState(false);
  const [hover, setHover] = React.useState(false);

  return (
    <>
      <div
        className="relative cursor-pointer"
        onMouseOver={() => setHover(true)}
        onMouseOut={() => setHover(false)}
        onClick={() => setModalIsOpen(true)}
      >
        <div className="absolute z-10 grid h-full w-full place-items-center ">
          <PlayCircleOutline
            className={classnames('max-h-20 lg:max-h-48', {
              'opacity-100': hover,
              'opacity-70': !hover,
            })}
          />
        </div>
        <TileImage image={image} />
      </div>

      <ReactModal
        isOpen={modalIsOpen}
        className="relative h-full w-full flex justify-center items-center"
      >
        <div className="absolute inset-0 m-auto z-50">
          <div className="flex justify-center items-center h-full px-4">
            <div className="  relative">
              <div className="bg-texture/secondary bg-cover bg-center border-8 border-body/50 my-7">
                <ReactPlayer
                  url={videoURL}
                  muted
                  playing
                  controls
                  height={'80vh'}
                  width={'80vw'}
                />
              </div>
              <button
                className="absolute top-0 right-0 block"
                onClick={() => setModalIsOpen(false)}
              >
                <svg width="20" height="20" viewBox="0 0 24 24" fill="none">
                  <path
                    d="M23.6666 2.6835L21.3166 0.333496L11.9999 9.65016L2.68325 0.333496L0.333252 2.6835L9.64992 12.0002L0.333252 21.3168L2.68325 23.6668L11.9999 14.3502L21.3166 23.6668L23.6666 21.3168L14.3499 12.0002L23.6666 2.6835Z"
                    fill="white"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </ReactModal>
    </>
  );
};

export default TileImageVideoModal;
