import {
  TimetableDataSource,
  TimetableDataSourceResponse,
} from '../timetable.context';

export default class LocalDataSource implements TimetableDataSource {
  constructor() {}

  /**
   * Get Data
   * Dynamically retrieve the timetable data from a static json file located in
   * Gatsby's /static folder by site id. e.g. 0001.json
   * @param siteId
   * @returns
   */
  public async getData(siteId: string): Promise<TimetableDataSourceResponse> {
    try {
      const data = await import(`/static/timetables/${siteId}.json`);

      return {
        details: data.details,
        slots: data.slots,
      };
    } catch (err) {
      throw new Error(`Cannot find Timetable data for site id: ${siteId}`);
    }
  }
}
