import React, { useEffect, useState } from 'react';
import Timetable from '../../components/Timetable/Timetable.component';
import { Blok } from '../../interfaces/Blok.interface';
import {
  Timetable as TimetableService,
  TimetableDataSource,
} from '../../services/timetable/timetable.service';
import { GymDataBlok, transformGymData } from '../../utils/gymData';
import { withBlokContainer } from '../../utils/withBlokContainer';

interface TimetableBlokProps extends Blok {
  title: string;
  gym: GymDataBlok;
  source: TimetableDataSource;
  limited: boolean;
}

const TimetableBlok: React.FC<TimetableBlokProps> = ({
  anchor,
  title,
  source,
  gym,
  limited,
}) => {
  let siteId = '';

  if (gym) {
    const { site_id, pg_club_id } = transformGymData([gym])[0];
    siteId = source === 'PERFECT_GYM' ? pg_club_id : site_id;
  }

  const [timetable, setTimetable] = useState(null);

  useEffect(() => {
    async function getTimetable() {
      if (siteId && source) {
        const timetable = await TimetableService.get(siteId, source, limited);
        setTimetable(timetable);
      }
    }

    getTimetable();
  }, [siteId, source, limited]);

  return withBlokContainer(
    <Timetable
      timetable={timetable?.slots}
      title={title}
      details={timetable?.details}
      timetableKey={timetable?.key}
    />,
    anchor
  );
};

TimetableBlok.defaultProps = {};

TimetableBlok.propTypes = {};

export default TimetableBlok;
