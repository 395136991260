import Axios, { AxiosResponse } from 'axios';
import { endOfWeek, format, getISODay, parseISO, startOfWeek } from 'date-fns';
import { Slot } from '../../../interfaces/Slot.interface';
import {
  TimetableDataSource,
  TimetableDataSourceResponse,
} from '../timetable.context';
import { formatClassDetails, getClasses } from '../timeline.helpers';

export interface ClassType {
  id: number;
  name: string;
  description: string;
  photoUrl: string;
  categoryId: number;
  isDeleted: boolean;
  version: number;
}

export interface ClubZone {
  id: number;
  name: string;
  zoneCapacity: number;
  clubId: number;
  clubZoneTypeId: number;
  parentClubZoneId: number;
  isDeleted: boolean;
  version: number;
}

export interface ClassItem {
  id: number;
  allowChooseSeatNumber: boolean;
  attendeesCount: number;
  attendeesLimit: number;
  clubId: number;
  classType: ClassType;
  clubZone: ClubZone;
  startDate: string;
  endDate: string;
  groupId: number;
  instructorId: number;
  hasStreaming: boolean;
  isCourse: boolean;
  isDeleted: boolean;
  isReservationRequired: boolean;
  standbyListLimit: number;
  version: number;
}

export interface PerfectGymResponseItem {
  value: ClassItem[];
}

export default class FirstSportDataSource implements TimetableDataSource {
  // Live API
  private readonly API_URL = 'https://api.everlastgyms.com/timetable-v2';

  // Local API
  // private readonly API_URL = 'http://localhost:3000/timetable-v2';

  private readonly DATE_FORMAT = 'yyyy-MM-dd';

  constructor() {}

  /**
   * Get Data
   * Dynamically retrieve the timetable data from Perfect Gym API by site id
   * @param siteId
   * @returns
   */
  public async getData(
    siteId: string,
    limited: boolean
  ): Promise<TimetableDataSourceResponse> {
    const classes = await getClasses();
    const details = formatClassDetails(classes);

    // Get Slots From API
    const { firstDayOfWeek, lastDayOfWeek } = this.getStartEndOfWeek();

    const formattedStartDate = format(firstDayOfWeek, this.DATE_FORMAT);
    const formattedEndDate = format(lastDayOfWeek, this.DATE_FORMAT);

    const response = await this.fetch(
      siteId,
      formattedStartDate,
      formattedEndDate
    );

    const slots = this.mapDataToTimetableSlots(response.data, limited);

    return {
      details,
      slots,
    };
  }

  /**
   * Fetch
   * Fetch data from the API
   * @param siteId
   */
  private async fetch(
    siteId: string,
    startDate: string,
    endDate: string
  ): Promise<AxiosResponse<PerfectGymResponseItem>> {
    try {
      const response = await Axios.get(this.API_URL, {
        params: {
          siteId,
          startDate,
          endDate,
        },
      });

      return response;
    } catch {
      throw new Error('Unable to fetch timetable data from remote service');
    }
  }

  /**
   * mapDataToTimetableSlots
   * Map data to our internal `Slot` model
   * @param data
   * @returns
   */
  private mapDataToTimetableSlots(
    data: PerfectGymResponseItem,
    limited: boolean
  ): Slot[] {
    // Get day/time from initial data
    const formattedValues = data.value.map((item) => {
      return {
        ...item,
        startTime: this.getTimeFromDate(item.startDate),
        endTime: this.getTimeFromDate(item.endDate),
        day: this.getDayOfWeek(item.startDate),
      };
    });

    if (limited) {
      return formattedValues.map((item) => ({
        name: item.classType.name,
        description: item.clubZone.name,
        type: item.classType.name,
        // The "subtype" is used to match the modal content to the slot - But doesn't matter for limited as no modal appears
        subtype: item.classType.description,
        start: item.startTime,
        end: item.endTime,
        day: item.day,
        week: 1,
        // The "location" is used as the "key" at the top, and defines the color of the slot
        location: item.clubZone.name,
      }));
    } else {
      // Return the "full" version of the timetable for gyms that have been refurbished
      return formattedValues.map((item) => {
        const modalKey = item.classType.name.toLowerCase().replace(' ', '-');

        return {
          name: item.classType.name,
          description: null,
          type: item.classType.name,
          // The "subtype" is used to match the modal content to the slot - Storyblok "Class" slug
          subtype: modalKey,
          start: item.startTime,
          end: item.endTime,
          day: item.day,
          week: 1,
          // The "location" is used as the "key" at the top, and defines the color of the slot
          location: item.clubZone.name,
        };
      });
    }
  }

  private getDayOfWeek(date: string) {
    const day = new Date(date);
    return getISODay(day);
  }

  private getStartEndOfWeek(date: Date = new Date()) {
    const firstDayOfWeek = startOfWeek(date, { weekStartsOn: 1 }); // 1 for Monday
    const lastDayOfWeek = endOfWeek(date, { weekStartsOn: 1 }); // 1 for Monday

    return { firstDayOfWeek, lastDayOfWeek };
  }

  private getTimeFromDate(date: string): string {
    return format(parseISO(date), 'HH:mm');
  }
}
