import Accordion from './Accordion/Accordion.blok';
import CTABlok from './CTABlok/CTABlok.blok';
import ClubHero from './ClubHero/ClubHero.blok';
import ContentList from './ContentList/ContentList.blok';
import Facilities from './Facilities/Facilities.blok';
import FiftyFiftyImageText from './FiftyFiftyImageText/FiftyFiftyImageText.blok';
import FiftyFiftyImageTextNoBottomPadding from './FiftyFiftyImageTextNoBottomPadding/FiftyFiftyImageTextNoBottomPadding.blok';
import FiftyFiftyRegistration from './FiftyFiftyRegistration/FiftyFiftyRegistration.blok';
import FindAGym from './FindAGym/FindAGym.blok';
import FullWidthCTA from './FullWidthCTA/FullWidthCTA.blok';
import GymSelect from './GymSelect/GymSelect.blok';
import GymSelectContact from './GymSelectContact/GymSelectContact.blok';
import ImageHero from './ImageHero/ImageHero.blok';
import ImageHeroWithGymSelect from './ImageHeroWithGymSelect/ImageHeroWithGymSelect.blok';
import ImageCarousel from './ImageCarousel/ImageCarousel.blok';
import Memberships from './Memberships/Memberships.blok';
import SecondaryNav from './SecondaryNav/SecondaryNav.blok';
import SmallImageHero from './SmallImageHero/SmallImageHero.blok';
import SocialBanner from './SocialBanner/SocialBanner.blok';
import Text from './Text/Text.blok';
import Timetable from './Timetable/Timetable.blok';
import Title from './Title/Title.blok';
import TwoColCTA from './TwoColCTA/TwoColCTA.blok';
import Video from './Video/Video.blok';
import VideoHero from './VideoHero/VideoHero.blok';

const ComponentBlokMap = {
  Accordion,
  ClubHero,
  ContentList,
  CTABlok,
  Facilities,
  FiftyFiftyImageText,
  FiftyFiftyImageTextNoBottomPadding,
  FiftyFiftyRegistration,
  FindAGym,
  FullWidthCTA,
  GymSelect,
  GymSelectContact,
  ImageCarousel,
  ImageHero,
  ImageHeroWithGymSelect,
  Memberships,
  SecondaryNav,
  SmallImageHero,
  SocialBanner,
  Timetable,
  Text,
  Title,
  TwoColCTA,
  Video,
  VideoHero,
};

export default ComponentBlokMap;
